import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState, useEffect } from "react";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import {
  AiOutlineCopy,
  AiOutlineFullscreen,
  AiOutlineControl,
} from "react-icons/ai";
import {
  Box,
  MenuList,
  Image,
  Flex,
  Menu,
  Tooltip,
  Modal,
  MenuButton,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";

import LikeImage from "../LikeImage/LikeImage";
import NoImage from "../../../../../image/no-image.jpg";
import DownloadImage from "../DownloadImage/DownloadImage";
import { defaultThemeColor, greyBgColor } from "../../../../../constants";
import ImageXConfig from "../ProjectConfiguration/CurrentImageConf/ImageXConfig";
import {
  setClipBoardValue,
  setTabFlag,
} from "../../../../../store/actions/workFlowAction";
import { resetWorkflowData } from "../../../../../utils/resetWorkflowData";
import CopyImageToClipboard from "../../../../Custom/CopyImageToClipboard";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import ImageGenerationConfig from "../../ImageGenerationConfig";
import SharedSpaceWorkflow from "./SharedSpaceWorkflow";
import AddNewWorkflow from "../AddNewWorkflow/AddNewWorkflow";

function CardFlip(props) {
  const divRef = useRef(null);
  const toast = useToast();
  const dispatch = useDispatch();
  const likeRating = props?.likeRating;
  const indexValue = props?.indexValue;
  const cardFlipStatus = props?.cardFlipStatus;
  const workflowCounter = props?.workflowCounter;
  const imageData = props?.imageJsonData;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: configIsOpen,
    onOpen: onConfigOpen,
    onClose: configOnClose,
  } = useDisclosure();

  const [modalWidth, setModalWidth] = useState(0);
  const [imageDetailsArray, setImageDetailsArray] = useState([]);
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const workflowDetailsData = workflowDetails?.workflowObject;
  const [imageArr, setImageArr] = useState([]);

  const [loadedCount, setLoadedCount] = useState(0);
  const { copyImageToClipboard } = CopyImageToClipboard(); //Custom Clipboard Function
  const [currentIndex, setCurrentIndex] = useState(indexValue);

  useEffect(() => {
    if (imageData?.length) {
      if (cardFlipStatus) {
        const imageUrlArr = imageData.flatMap((data) =>
          data.images?.flatMap((subValue) =>
            subValue?.map((innerValue) => innerValue.url).filter(Boolean)
          )
        );
        setImageArr(imageUrlArr);
      } else {
        const imageUrlArr = imageData.flatMap((data) =>
          data.images?.flatMap((subValue) => subValue.url).filter(Boolean)
        );
        setImageArr(imageUrlArr);
      }
    }
  }, [imageData]);

  const copyToClipboard = (data) => {
    if (data?.object_name?.length) {
      dispatch(setClipBoardValue(data?.object_name));
      copyImageToData(data?.object_name);
    }
  };

  const copyImageToData = async (imageName) => {
    try {
      await copyImageToClipboard(imageName);
      toast(toastFunctionToaster("Image copied to clipboard", "success"));
    } catch (error) {
      toast(toastFunctionToaster("Failed to copy image to clipboard", "error"));
    }
  };

  const passImageDetails = (imageUrl, imageObjName) => {
    let objBody = {
      imageUrl: imageUrl,
      imageObjName: imageObjName,
      indexValue: indexValue,
      workflowCounter: workflowCounter,
    };
    props?.passImageIndexValue(objBody);
  };

  const handleImageLoad = (e) => {
    // Prevent slider functionality until all images are loaded
    setLoadedCount((prevCount) => prevCount + 1);
    setModalWidth(e.target.naturalWidth);
  };

  const imageConfigComponent = (index) => {
    return (
      <Menu as={Box} position="relative" zIndex={10}>
        <MenuButton
          color="#fff"
          position={"absolute"}
          right="1"
          top="1"
          bg={greyBgColor}
          fontSize={"20px"}
          borderRadius={"10px"}
          zIndex={1}
        >
          <BiDotsVerticalRounded />
        </MenuButton>
        <MenuList
          bg={greyBgColor}
          border="none"
          minW="100px !important"
          position="absolute"
          left="-90px"
        >
          <Flex justifyContent={"space-between"} alignItems={"center"} px="2">
            <Tooltip label="Download Image" placement="top">
              <Box>
                <DownloadImage
                  position={"relative"}
                  right={"0"}
                  imageUrl={
                    props?.frontImage[0]?.url?.length
                      ? props?.frontImage[0]?.url
                      : NoImage
                  }
                ></DownloadImage>
              </Box>
            </Tooltip>
            {cardFlipStatus ? (
              <LikeImage
                projectId={workflowDetailsData?.project_uuid}
                imageWorkflowId={workflowDetailsData?.workflow_uuid}
                imageIndexValue={indexValue}
                workflowCounter={workflowCounter}
                fontSize="20px"
                sendDataToCardFlip={passDataToPrevComp}
                likeRating={likeRating?.length > 0 ? likeRating : {}}
              />
            ) : (
              <Tooltip label="Open Config" placement="top">
                <Box>
                  <AiOutlineControl
                    size="20px"
                    cursor="pointer"
                    onClick={() => {
                      setCurrentIndex(imageArr.indexOf(props?.frontImage[0]?.url));
                      onConfigOpen();
                    }}
                    color={defaultThemeColor}
                  />
                </Box>
              </Tooltip>
            )}
            <Tooltip label="Copy To Clipboard" placement="top">
              <Box>
                <AiOutlineCopy
                  size="20px"
                  cursor="pointer"
                  color={defaultThemeColor}
                  onClick={() => {
                    copyToClipboard(props?.frontImage[0]);
                  }}
                />
              </Box>
            </Tooltip>
            {cardFlipStatus ? (
              <AddNewWorkflow
                projectId={workflowDetailsData?.project_uuid}
                resourceId={workflowDetailsData?.resource_uuid}
                imageWorkflowId={workflowDetailsData?.workflow_uuid}
                imageWorkflowName={workflowDetailsData?.workflow_name}
                imageIndexValue={indexValue}
                workflowCounter={workflowCounter}
                objData={props?.frontImage[0]}
                resetData={() => {
                  resetWorkflowData(dispatch);
                  dispatch(setTabFlag(0));
                }}
                fontSize="20px"
                menuLeft="90px"
              />
            ) : (
              <Tooltip label="Open Image In Full Screen" placement="top">
                <Box>
                  <AiOutlineFullscreen
                    size="20px"
                    cursor="pointer"
                    onClick={() => {
                      if (imageArr.length > 0) {
                        setCurrentIndex(imageArr.indexOf(props?.frontImage[0]?.url));
                        onOpen();
                      }
                    }}
                    color={defaultThemeColor}
                  />
                </Box>
              </Tooltip>
            )}
            <SharedSpaceWorkflow
              projectId={workflowDetailsData?.project_uuid}
              objectKey={props?.frontImage?.[0]?.object_name}
              fontSize="20px"
            />
          </Flex>
          <ImageXConfig
            imgUrl={props?.frontImage[0]}
            height={imageDetailsArray[index]?.height}
            width={imageDetailsArray[index]?.width}
          />
        </MenuList>
      </Menu>
    );
  };

  const passDataToPrevComp = (value) => {
    props?.sendDataToPrevImageComp(value);
  };

  const handleNext = () => {
    // Guard clause to avoid errors
    if (imageArr.length === 0) return;

    // Calculate the next index and wrap around if necessary
    setCurrentIndex((idx) => (idx + 1) % imageArr.length);
  };

  const handlePrev = () => {
    // Guard clause to avoid errors
    if (imageArr.length === 0) return;

    // Calculate the previous index and wrap around if necessary
    setCurrentIndex((idx) => (idx - 1 + imageArr.length) % imageArr.length);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        handlePrev();
      } else if (e.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setCurrentIndex, imageArr]);

  return (
    <>
      <Box
        h={props?.height ? props?.height : "290px"}
        w={props?.width ? props?.width : ""}
        mr="3"
        position="relative"
        ref={divRef}
      >
        {imageDetailsArray[indexValue]?.width &&
          imageConfigComponent(indexValue)}
        <Image
          w="100%"
          h="100%"
          cursor="pointer"
          objectFit="cover"
          src={
            props?.frontImage[0]?.url?.length
              ? props?.frontImage[0]?.url
              : NoImage
          }
          onClick={() => {
            if (cardFlipStatus === false) {
              passImageDetails(
                props?.frontImage[0]?.url,
                props?.frontImage[0]?.object_name
              );
            } else if (imageArr.length > 0) {
              setCurrentIndex(imageArr.indexOf(props?.frontImage[0]?.url));
              onOpen();
            }
          }}
          onLoad={(e) => {
            const height = e.target.naturalHeight;
            const width = e.target.naturalWidth;
            setImageDetailsArray((prevArray) => {
              const newArray = [...prevArray];
              newArray[indexValue] = { height, width };
              return newArray;
            });
          }}
        />
        {imageDetailsArray[indexValue]?.width ? (
          <Box
            color="#fff"
            position="absolute"
            top="0"
            bg={greyBgColor}
            fontWeight="600"
            p="2"
          >
            {imageDetailsArray[indexValue]?.width} x{" "}
            {imageDetailsArray[indexValue]?.height}
          </Box>
        ) : null}
      </Box>
      <Modal
        isOpen={configIsOpen}
        onClose={configOnClose}
        scrollBehavior="outside"
        style={{ width: "100% !important" }}
        size={"5xl"}
      >
        <ModalOverlay />
        <ModalContent mt="5" bg="#0b090c">
          <ModalCloseButton
            color="red.500"
            fontWeight={"600"}
            bg="#fff"
            top="0"
            right="0"
            zIndex="999"
          />
          <ImageGenerationConfig
            rowJsonData={props?.frontImage?.[1]?.json}
            workFlowImage={workflowDetailsData?.source_image}
            useBackground={(
              workflowDetailsData?.modelCode === 1 ||
              workflowDetailsData?.modelCode === 3 ||
              workflowDetailsData?.modelCode === 4
            )}
          />
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setLoadedCount(0);
        }}
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent maxW="80%" width={modalWidth} mt="5">
          <ModalCloseButton
            color="red.500"
            fontWeight="600"
            bg="#fff"
            top="0"
            right="0"
            zIndex="999"
          />
          <Box position={"relative"}>
            <LazyLoad height={"100%"} width={"100%"}>
              <Image
                src={imageArr[currentIndex]}
                alt="Image"
                objectFit="contain"
                height="95vh"
                bg="#0b090c"
                width="100%"
                onLoad={handleImageLoad}
              />
            </LazyLoad>
            <Flex
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              w="100%"
              justifyContent="space-between"
              px="2"
              color="white"
            >
              <BiLeftArrowAlt
                size="2em"
                zIndex="2"
                cursor="pointer"
                onClick={handlePrev}
              />
              <BiRightArrowAlt
                size="2em"
                zIndex="2"
                cursor="pointer"
                onClick={handleNext}
              />
            </Flex>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CardFlip;
