import * as actionType from "../actionTypes";

const initialState = {
  headerName: "",
  statusColor: "",
  businessInfoStatus: false,
  sideMenuFlag: true,
  profileLogo: "",
};

const notificationHeader = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_NOTIFICATION_MESSAGE: {
      return {
        ...state,
        headerName: action.value,
      };
    }
    case actionType.SET_NOTIFICATION_STATUS_COLOR: {
      return {
        ...state,
        statusColor: action.value,
      };
    }
    case actionType.SET_BUSINESS_INFO_STATUS: {
      return {
        ...state,
        businessInfoStatus: action.value,
      };
    }
    case actionType.SET_EXPAND_MIN_SIDEMENU: {
      return {
        ...state,
        sideMenuFlag: action.value,
      };
    }
    case actionType.PROFILE_LOGO: {
      return {
        ...state,
        profileLogo: action.value,
      };
    }
    default:
      return state;
  }
};
export default notificationHeader;
