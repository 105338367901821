// Chakra Imports
import React, { useEffect, useState, useRef } from "react";
import { AiOutlineDelete, AiFillFolder } from "react-icons/ai";
import ResponsivePagination from "react-responsive-pagination";
import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  Icon,
  Spinner,
  GridItem,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
} from "@chakra-ui/react";

// Component Imports
import { defaultGreyColor, greyBgColor } from "../../../../constants";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import { Droppable } from "react-drag-and-drop";

// Api Imports
import {
  AssignProjectFolder,
  CollectProjectFolders,
  RemoveProjectFolder,
} from "../../../../services/projectTemplateService";
import AddProjectFolder from "./AddProjectFolder";

function ProjectFolder({
  currentFolderId,
  folderLoading,
  isFolderLoading,
  projectId,
  refreshProjFolderData,
  setCurrentFolderId,
  setCurrentFolderName,
  setParentFolderId,
  setRefreshProjFolderData,
  selectedFolders,
  setSelectedFolders,
}) {
  const toast = useToast();
  const onlyFetchOnCurrentPageChangeRef = useRef(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteAlert, isDeleteAlert] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [fullProjectFolders, setFullProjectFolders] = useState([]);
  const [hoveredFolderId, setHoveredFolderId] = useState(null);

  useEffect(() => {
    if (projectId.length || refreshProjFolderData) {
      loadProjectFolderData();
    }
    setRefreshProjFolderData(false);
  }, [projectId, currentFolderId, refreshProjFolderData]);

  useEffect(() => {
    if (!onlyFetchOnCurrentPageChangeRef.current) {
      onlyFetchOnCurrentPageChangeRef.current = true;
      return;
    }
    loadProjectFolderData();
  }, [currentPage]);

  const handleCheckboxChange = (folderId, isChecked) => {
    if (isChecked) {
      setSelectedFolders((prev) => [...prev, folderId]);
    } else {
      setSelectedFolders((prev) => prev.filter((id) => id !== folderId));
    }
  };

  const loadProjectFolderData = () => {
    if (projectId) {
      let objBody = {
        project_uuid: projectId,
        records_per_page: 10,
        page_id: currentPage,
      };
      if (currentFolderId) {
        objBody = {
          ...objBody,
          project_folder_uuid: currentFolderId,
        };
      }
      isFolderLoading(true);
      CollectProjectFolders(objBody)
        .then((res) => {
          isFolderLoading(false);
          if (res?.data?.length > 0) {
            setCurrentFolderName(res?.data[0]?.name);
            setParentFolderId(res?.data[0]?.parent_folder_uuid);
            setFullProjectFolders(res?.data[0]?.pagination_data?.[0]?.records);
            setTotalPages(res.data[0].pagination_data[0].total_pages);
            setSelectedFolders([]);
            if (res.data[0].pagination_data[0].total_pages < currentPage) {
              setCurrentPage(
                Math.max(1, res.data[0].pagination_data[0].total_pages)
              );
            }
          } else {
            setFullProjectFolders([]);
          }
        })
        .catch((err) => {
          isFolderLoading(false);
          setFullProjectFolders([]);
        });
    }
  };

  const deleteFolderData = () => {
    if (folderToDelete && projectId) {
      isDeleteAlert(false);
      let objBody = {
        project_uuid: projectId,
        project_folder_uuid: folderToDelete,
      };
      isFolderLoading(true);
      RemoveProjectFolder(objBody)
        .then((res) => {
          setFullProjectFolders([]);
          if (res?.data?.length > 0) {
            loadProjectFolderData();
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            isFolderLoading(false);
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            isFolderLoading(false);
            toast(toastFunctionToaster("Something Went Wrong", "error"));
          }
        });
    }
  };

  const handleDrop = (data, folderId) => {
    if (data?.fruit && folderId) {
      assignWorkflow(data?.fruit, folderId);
    }
  };

  const assignWorkflow = (workflowId, folderId) => {
    // isFolderLoading(true);

    let obj = {
      project_uuid: projectId,
      project_folder_uuid_list: [],
      workflow_uuid_list: [workflowId],
    };
    if (folderId?.length) {
      obj.parent_folder_uuid = folderId;
    }
    AssignProjectFolder(obj)
      .then((res) => {
        if (res?.result) {
          setCurrentFolderId(folderId);
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        if (err) {
          toast(toastFunctionToaster(err?.message, "error"));
        }
      });
  };

  // Handle drag events
  const handleDragEnter = (folderId) => {
    if (hoveredFolderId !== folderId) {
      setHoveredFolderId(folderId);
    }
  };

  const handleDragLeave = (folderId) => {
    if (hoveredFolderId === folderId) {
      setHoveredFolderId(null);
    }
  };

  // Todo:Render Folder Name
  return (
    <>
      {folderLoading ? (
        <Flex alignItems="center" justifyContent="center" w="100%" h="10vh">
          <Spinner size="sm" color="#fff" />
        </Flex>
      ) : (
        <>
          <Grid
            templateColumns="repeat(4, 1fr)"
            gap={4}
            p="10px"
            pt="4"
            borderBottomWidth={"0.5px"}
            borderBottomColor={greyBgColor}
          >
            {fullProjectFolders?.length ? (
              fullProjectFolders.map((data) => (
                <Droppable
                  key={data.folder_uuid}
                  types={["fruit"]} // Ensure this matches the type you're dragging
                  onDrop={(flowData) => handleDrop(flowData, data.folder_uuid)}
                  onDragEnter={() => handleDragEnter(data.folder_uuid)}
                  onDragLeave={() => handleDragLeave(data.folder_uuid)}
                >
                  <GridItem
                    borderRadius={"10px"}
                    p="10px"
                    cursor={"pointer"}
                    borderWidth={
                      hoveredFolderId === data.folder_uuid ? "2px" : "0.5px"
                    }
                    borderColor={greyBgColor}
                    onClick={() => {
                      setCurrentFolderId(data?.folder_uuid);
                    }}
                    bg={
                      hoveredFolderId === data.folder_uuid
                        ? "blue.300" // Highlight when hovered
                        : defaultGreyColor
                    }
                  >
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <AiFillFolder fontSize={"25px"} color="#fff" />
                      <Flex flexWrap={"wrap"} ml="2">
                        <Text
                          color="#fff"
                          fontWeight={"600"}
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          w="150px"
                        >
                          {data?.name}
                        </Text>
                        <Text color="#fff" w="100%">
                          <Text as="span" color="green.200" fontWeight={"bold"}>
                            {data?.workflows_count}
                          </Text>{" "}
                          Workflows
                        </Text>
                      </Flex>

                      <Flex alignItems="center">
                        <AddProjectFolder
                          projectId={projectId}
                          editFolderId={data?.folder_uuid}
                          resetUpdatedFolderDetails={refreshProjFolderData}
                        />
                        <Button
                          ml="2"
                          size={"xs"}
                          colorScheme="red"
                          onClick={(e) => {
                            e.stopPropagation(); // Stop event propagation here
                            isDeleteAlert(true);
                            setFolderToDelete(data?.folder_uuid);
                          }}
                        >
                          <Icon w={5} h={5} as={AiOutlineDelete} />
                        </Button>
                      </Flex>
                    </Flex>
                  </GridItem>
                </Droppable>
              ))
            ) : (
              <GridItem
                bg={defaultGreyColor}
                borderRadius={"5px"}
                p="10px"
                cursor={"pointer"}
                borderWidth={"0.5px"}
                borderColor={greyBgColor}
              >
                <Flex alignItems={"center"}>
                  <Text color="#fff" w="100%" fontWeight={"600"}>
                    No Project Folders Found
                  </Text>
                </Flex>
              </GridItem>
            )}
          </Grid>
          {totalPages > 1 && (
            <Box mt="4">
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </Box>
          )}
        </>
      )}

      {/* Todo:Delete Folder Popup */}
      <AlertDialog isOpen={deleteAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Folder
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? The projected folder and all associated data will be
              permanently deleted from storage!!
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => isDeleteAlert(false)} size="sm">
                Cancel
              </Button>
              <Button
                ml={3}
                size="sm"
                colorScheme="red"
                onClick={() => deleteFolderData()}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default ProjectFolder;
