import React from 'react';
import { Outlet } from 'react-router-dom';

function DefaultLayout() {
  return (
    <>
      <Outlet></Outlet>
    </>
  );
}

export default DefaultLayout;
