// Chakra - React Imports
import { React, useState, useEffect, useRef } from "react";
import {
  Box,
  Img,
  Link,
  Text,
  Flex,
  Button,
  Switch,
  Avatar,
  Heading,
  Spinner,
  Tooltip,
  useToast,
  Accordion,
  useDisclosure,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";

// Component Imports
import AddComments from "./AddComments";
import { AiOutlineReload } from "react-icons/ai";
import SharedImageModal from "./SharedImageModal";
import { Excalidraw } from "@excalidraw/excalidraw";
import UploadSharedContent from "./UploadSharedContent";
import { getEmailId } from "../../utils/localStorageIndex";
import { toastFunctionToaster } from "../../utils/toastFunction";
import {
  defaultGreyColor,
  defaultThemeColor,
  greyBgColor,
} from "../../constants";
import {
  FaFile,
  FaFileArchive,
  FaFileExcel,
  FaFilePdf,
  FaFilePowerpoint,
  FaFileWord,
} from "react-icons/fa";

// Api Imports
import { externalContentList } from "../../services/sharedServices";
import {
  fetchSharedSpaceData,
  handleSharedContentStatus,
} from "../../services/businessServices";
import EditComments from "./EditComments";
import ExcaliComp from "./ExcaliComp";

function SharedFolder({
  type,
  shared_space_id,
  email,
  code,
  sharedSpaceName,
  collapseFlag,
}) {
  const toast = useToast();
  const creatorEmail = email || getEmailId();
  const excalidrawRef = useRef(null);
  const [sceneData, setSceneData] = useState(null);
  const [sharedSpaceData, setSharedSpaceData] = useState([]);

  useEffect(() => {
    loadShareContent();
  }, [shared_space_id]);

  // External Content
  const loadShareContent = () => {
    if (type === "external") {
      const params = {
        shared_space_key: shared_space_id,
        user_email: email,
        password: code,
      };
      const obj = {
        // filter_type: "string",
        records_per_page: 10,
        page_id: 1,
      };
      externalContentList(params, obj)
        .then((res) => {
          if (res?.data?.[0]?.pagination_data?.[0]?.records?.length) {
            setSharedSpaceData(res?.data?.[0]?.pagination_data?.[0]?.records);
          } else {
            setSharedSpaceData([]);
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster(err?.message, "error"));
          }
        });
    } else {
      let obj = {
        shared_space_uuid: shared_space_id,
        filter_enabled: false,
        // filter_type: "string",
        records_per_page: 10,
        page_id: 1,
      };
      fetchSharedSpaceData(obj)
        .then((res) => {
          if (res?.data?.[0]?.pagination_data?.[0]?.records?.length) {
            setSharedSpaceData(res?.data?.[0]?.pagination_data?.[0]?.records);
          } else {
            setSharedSpaceData([]);
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster(err?.message, "error"));
          }
        });
    }
  };

  const getFileIcon = (filename) => {
    const filename_els = filename.split(".");
    const extension = filename_els[filename_els.length - 1].toLowerCase();
    if (extension === "pdf") {
      return <FaFilePdf color="#fff" />;
    } else if (
      extension === "zip" ||
      extension === "gzip" ||
      extension === "7z" ||
      extension === "rar"
    ) {
      return <FaFileArchive color="#fff" />;
    } else if (extension === "xls" || extension === "xlsx") {
      return <FaFileExcel color="#fff" />;
    } else if (extension === "ppt" || extension === "pptx") {
      return <FaFilePowerpoint color="#fff" />;
    } else if (extension === "doc" || extension === "docx") {
      return <FaFileWord color="#fff" />;
    } else {
      return <FaFile color="#fff" />;
    }
  };

  const getFileName = (filename) => {
    const filename_els = filename.split("/");
    return filename_els[filename_els.length - 1];
  };

  function DisplaySharedContent({ reloadShareContent, sharedContentData }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [indexValue, setIndexValue] = useState();

    if (sharedContentData?.type === "file") {
      const sharedImages = sharedContentData?.info?.filter((info) => {
        const filename_els = info?.object_name?.split(".");
        const extension = filename_els[filename_els.length - 1].toLowerCase();
        return (
          ["png", "jpg", "jpeg", "gif", "webp", "bmp"]?.indexOf(extension) >= 0
        );
      });
      const sharedAttachments = sharedContentData?.info?.filter((info) => {
        const filename_els = info?.object_name?.split(".");
        const extension = filename_els[filename_els.length - 1].toLowerCase();
        return (
          ["png", "jpg", "jpeg", "gif", "webp", "bmp"]?.indexOf(extension) < 0
        );
      });
      return (
        <Box
          border="1px"
          borderRadius={"5px"}
          borderStyle={"dotted"}
          borderColor={"#797b80"}
          p="10px"
          borderBottom={"0"}
        >
          {sharedImages?.length > 0 && (
            <>
              <Heading fontSize={"18px"} color={"#fff"} fontWeight={"600"}>
                Shared Images
              </Heading>
              <SharedImageModal
                open={isOpen}
                handleClose={onClose}
                imageArr={sharedImages}
                indexValue={indexValue}
              />
            </>
          )}
          <Flex mt="2" flexWrap={"wrap"}>
            {sharedImages?.map((info, index) => (
              <Box
                h="150px"
                w="150px"
                mr="2"
                key={index}
                cursor={"pointer"}
                onClick={() => {
                  setIndexValue(index);
                  onOpen();
                }}
                mb="2"
              >
                <Img
                  objectFit={"contain"}
                  h={"100%"}
                  border={"1px"}
                  borderRadius={"5px"}
                  w="100%"
                  borderColor={defaultGreyColor}
                  src={info?.url}
                />
              </Box>
            ))}
            {sharedContentData?.author === creatorEmail && (
              <UploadSharedContent
                type={type}
                sharedSpaceId={shared_space_id}
                sharedContentId={sharedContentData?.shared_content_uuid}
                email={email}
                code={code}
                uploadImageDetailsFlag={reloadShareContent}
              />
            )}
          </Flex>
          {sharedAttachments?.length > 0 && (
            <Heading fontSize={"18px"} color={"#fff"} fontWeight={"600"}>
              Attachments
            </Heading>
          )}
          <Flex mt="2" flexWrap={"wrap"}>
            {sharedAttachments?.map((info, index) => (
              <Link href={info?.url} key={index} target="_blank">
                <Button
                  mr="2"
                  size="sm"
                  colorScheme="blue"
                  rightIcon={getFileIcon(info?.object_name)}
                >
                  {getFileName(info?.object_name)}
                </Button>
              </Link>
            ))}
          </Flex>
        </Box>
      );
    } else if (sharedContentData?.type === "comment") {
      return sharedContentData?.author === creatorEmail ? (
        <EditComments
          type={type}
          sharedSpaceId={shared_space_id}
          email={email}
          code={code}
          handleSpaceData={reloadShareContent}
          sharedContentData={sharedContentData}
        />
      ) : (
        <Box
          mt="2"
          border="1px"
          borderRadius={"5px"}
          borderStyle={"dotted"}
          borderColor={"#797b80"}
          p="10px"
          borderBottom={"0"}
        >
          <Text rows="3" resize="none" color={"#fff"} borderColor={"#797b80"}>
            {sharedContentData?.info}
          </Text>
          {sharedContentData?.updated !== sharedContentData?.created && (
            <Text textAlign={"right"} color={"#d7d7d7"} my="2">
              Edited by {sharedContentData?.author} on{" "}
              {sharedContentData?.updated}
            </Text>
          )}
        </Box>
      );
    } else if (sharedContentData?.type === "drawing") {
      return (
        <ExcaliComp
          type={type}
          sharedSpaceId={shared_space_id}
          email={email}
          code={code}
          sharedContentData={sharedContentData}
          handleSpaceData={reloadShareContent}
        />
      )
    }
  }

  // Todo:Shared Content HOC (High-Order) Component
  function SharedSpaceItem({ sharedSpaceData, reloadShareContent }) {
    const [childSharedSpaceData, setChildSharedSpaceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [childLoading, setChildLoading] = useState(false);

    const updateSharedContentStatus = (sharedContentId, newStatus) => {
      const obj = {
        shared_content_uuid: sharedContentId,
        shared_content_status: newStatus,
      };
      setLoading(true);
      handleSharedContentStatus(obj)
        .then((res) => {
          if (res?.data?.length) {
            toast(toastFunctionToaster(res?.message, "success"));
            reloadShareContent();
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster(err?.message, "error"));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const fetchChildDataDetails = () => {
      if (type === "external") {
        const params = {
          shared_space_key: shared_space_id,
          user_email: email,
          password: code,
        };
        const obj = {
          parent_shared_content_uuid: sharedSpaceData?.shared_content_uuid,
          // filter_type: "string",
          records_per_page: 10,
          page_id: 1,
        };
        setChildLoading(true);
        externalContentList(params, obj)
          .then((res) => {
            if (res?.data?.[0]?.pagination_data?.[0]?.records?.length) {
              setChildSharedSpaceData(
                res?.data?.[0]?.pagination_data?.[0]?.records
              );
            } else {
              setChildSharedSpaceData([]);
            }
          })
          .catch((err) => {
            if (err) {
              toast(toastFunctionToaster(err?.message, "error"));
            }
          })
          .finally(() => {
            setChildLoading(false);
          });
      } else {
        let obj = {
          shared_space_uuid: shared_space_id,
          parent_shared_content_uuid: sharedSpaceData?.shared_content_uuid,
          filter_enabled: false,
          // filter_type: "string",
          records_per_page: 10,
          page_id: 1,
        };
        setChildLoading(true);
        fetchSharedSpaceData(obj)
          .then((res) => {
            if (res?.data?.[0]?.pagination_data?.[0]?.records?.length) {
              setChildSharedSpaceData(
                res?.data?.[0]?.pagination_data?.[0]?.records
              );
            } else {
              setChildSharedSpaceData([]);
            }
          })
          .catch((err) => {
            if (err) {
              toast(toastFunctionToaster(err?.message, "error"));
            }
          })
          .finally(() => {
            setChildLoading(false);
          });
      }
    };

    useEffect(() => {
      if (!childSharedSpaceData?.length) {
        fetchChildDataDetails();
      }
    }, []);

    return (
      <Accordion
        defaultIndex={[0]}
        allowMultiple
        onChange={(isExpanded) => {
          if (isExpanded?.length && !childSharedSpaceData?.length) {
            fetchChildDataDetails();
          }
        }}
      >
        <AccordionItem
          borderWidth={"1px"}
          borderRadius={"5px"}
          borderColor={greyBgColor}
          mb="3"
        >
          <AccordionButton p="0" bg={greyBgColor} _hover={{ bg: greyBgColor }}>
            <Flex
              bg={greyBgColor}
              w="100%"
              p="5px"
              alignItems={"center"}
              borderTopLeftRadius={"5px"}
              borderBottomLeftRadius={"5px"}
            >
              <Tooltip placement="left-end" label={sharedSpaceData?.author}>
                <Avatar name={sharedSpaceData?.author} color={"#000"} mr="2" />
              </Tooltip>
              <Flex justifyContent={"space-between"} w="100%">
                <Text fontWeight={"500"} color={"#fff"}>
                  {sharedSpaceData?.author}
                </Text>
                <Text fontSize={"14px"} color={"#fff"}>
                  {sharedSpaceData?.created}
                </Text>
              </Flex>
              {type !== "external" &&
                sharedSpaceData?.author === creatorEmail && (
                  <Switch
                    ml="2"
                    colorScheme="green"
                    isChecked={sharedSpaceData?.enabled}
                    onChange={(e) => {
                      e.stopPropagation();
                      updateSharedContentStatus(
                        sharedSpaceData?.shared_content_uuid,
                        !sharedSpaceData?.enabled
                      );
                    }}
                  />
                )}
            </Flex>
            <AccordionIcon fontSize="2rem" color={"#fff"} ml="2" p="5px" />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box ml="2rem">
              {loading ? (
                <Spinner my="2" color="#fff" />
              ) : (
                <DisplaySharedContent
                  reloadShareContent={reloadShareContent}
                  sharedContentData={sharedSpaceData}
                />
              )}
              <Flex
                border="1px"
                borderRadius={"5px"}
                borderStyle={"dotted"}
                borderColor={"#797b80"}
                p="10px"
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <UploadSharedContent
                  type={type}
                  sharedSpaceId={shared_space_id}
                  parentSharedContentId={sharedSpaceData?.shared_content_uuid}
                  email={email}
                  code={code}
                  uploadImageDetailsFlag={fetchChildDataDetails}
                  name={"Reply With Attachment"}
                  width={"170px"}
                />
                <ExcaliComp
                  type={type}
                  sharedSpaceId={shared_space_id}
                  parentSharedContentId={sharedSpaceData?.shared_content_uuid}
                  email={email}
                  code={code}
                  handleSpaceData={fetchChildDataDetails}
                  name={"Reply With Drawing"}
                />
                <AddComments
                  type={type}
                  sharedSpaceId={shared_space_id}
                  parentSharedContentId={sharedSpaceData?.shared_content_uuid}
                  email={email}
                  code={code}
                  handleSpaceData={fetchChildDataDetails}
                  name={"Reply"}
                />
              </Flex>
              {childLoading ? (
                <Box mt="2" ml="4">
                  <Spinner color="#fff" />
                </Box>
              ) : (
                childSharedSpaceData?.length > 0 && (
                  <Box mt="2" ml="4">
                    {childSharedSpaceData.map((childData, index) => (
                      <SharedSpaceItem
                        key={index}
                        sharedSpaceData={childData}
                        reloadShareContent={fetchChildDataDetails}
                      />
                    ))}
                  </Box>
                )
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }

  // Todo:Render History Component With Nested Child Component
  const renderSharedSpaceData = () => {
    if (sharedSpaceData?.length) {
      return sharedSpaceData.map((data, index) => (
        <SharedSpaceItem
          key={index}
          sharedSpaceData={data}
          reloadShareContent={loadShareContent}
        />
      ));
    }
    return (
      <Text
        p="2"
        bg={greyBgColor}
        mb="3"
        borderRadius={"5"}
        color={"white"}
        fontWeight={"600"}
      >
        No Shared Content Found
      </Text>
    );
  };

  return (
    <>
      <Flex
        border="1px"
        borderRadius={"5px"}
        borderStyle={"dotted"}
        borderColor={"#797b80"}
        p="10px"
        justifyContent={"space-between"}
        alignItems={"center"}
        mb="2"
        position={type === "external" ? "fixed" : "relative"}
        bgColor={"#151117"}
        zIndex={"2"}
        w={type === "external" ? "65%" : "100%"}
      >
        <Flex w="100%" justifyContent={"space-between"} alignItems={"center"}>
          <Text
            color={defaultThemeColor}
            fontSize={"16px"}
            fontWeight={"500"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            mr="10px"
          >
            {sharedSpaceName}
          </Text>
          <Flex alignItems={"center"} flexWrap={"wrap"}>
            <Button
              mr="1"
              size="xs"
              colorScheme="yellow"
              onClick={() => {
                loadShareContent();
              }}
              rightIcon={<AiOutlineReload />}
            >
              Reload
            </Button>
            <UploadSharedContent
              type={type}
              sharedSpaceId={shared_space_id}
              email={email}
              code={code}
              uploadImageDetailsFlag={loadShareContent}
              name={"New Attachment"}
            />
            <ExcaliComp
              type={type}
              sharedSpaceId={shared_space_id}
              email={email}
              code={code}
              handleSpaceData={loadShareContent}
              name={"New Drawing Canvas"}
            />
            <AddComments
              type={type}
              sharedSpaceId={shared_space_id}
              email={email}
              code={code}
              handleSpaceData={loadShareContent}
              name={"New Comment"}
            />
          </Flex>
        </Flex>
      </Flex>
      <Box
        mb="3"
        h="calc(100vh - 15vh)"
        overflow="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "transparent",
            borderRadius: "24px",
          },
        }}
      >
        <Box mt={type === "external" ? "4rem" : null}>
          {renderSharedSpaceData()}
        </Box>
      </Box>
    </>
  );
}

export default SharedFolder;
