import React from "react";
import { useLocation } from "react-router-dom";
// Chakra Import
import { Flex } from "@chakra-ui/react";
import ProjectConfiguration from "./ProjectConfiguration/ProjectConfiguration";

function DesignProject() {
  const { state } = useLocation();
  const projectDetails = state;
  return (
    <Flex h="100%">
      <ProjectConfiguration projectDetails={projectDetails} />
    </Flex>
  );
}

export default DesignProject;
