// Chakra Imports
import React, { useEffect, useState } from "react";
import { AiFillFolder, AiOutlineArrowLeft } from "react-icons/ai";
import {
  Text,
  Button,
  Flex,
  Modal,
  Icon,
  GridItem,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast,
  useDisclosure,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  defaultGreyColor,
  defaultThemeColor,
  greyBgColor,
} from "../../../../constants";
import {
  AssignProjectFolder,
  CollectProjectFolders,
} from "../../../../services/projectTemplateService";
import { toastFunctionToaster } from "../../../../utils/toastFunction";

function ProjectToolManagement({
  projectId,
  workflowIdList,
  startFolderId,
  prevFolderId,
  loadWorkflowData,
}) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [folderLoading, isFolderLoading] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(startFolderId);
  const [destFolderId, setDestFolderId] = useState(startFolderId);
  const [fullProjectFolders, setFullProjectFolders] = useState([]);
  const [parentFolderId, setParentFolderId] = useState(prevFolderId);

  useEffect(() => {
    if (isOpen) {
      setDestFolderId(currentFolderId);
      loadProjectFolderData();
    }
  }, [isOpen, currentFolderId]);

  const loadProjectFolderData = () => {
    if (projectId) {
      isFolderLoading(true);
      let objBody = {
        project_uuid: projectId,
        records_per_page: 10,
        page_id: 1,
      };
      if (destFolderId?.length) {
        objBody.project_folder_uuid = currentFolderId;
      }
      CollectProjectFolders(objBody)
        .then((res) => {
          if (res?.data?.length > 0) {
            setParentFolderId(res?.data[0]?.parent_folder_uuid);
            setFullProjectFolders(
              res?.data?.[0]?.pagination_data?.[0]?.records
            );
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            setFullProjectFolders([]);
            setParentFolderId("");
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          setFullProjectFolders([]);
          setParentFolderId("");
        })
        .finally(() => {
          isFolderLoading(false);
        });
    }
  };

  const assignWorkflow = () => {
    isFolderLoading(true);

    let obj = {
      project_uuid: projectId,
      project_folder_uuid_list: [],
      workflow_uuid_list: workflowIdList,
    };
    if (destFolderId?.length) {
      obj.parent_folder_uuid = destFolderId;
    }
    AssignProjectFolder(obj)
      .then((res) => {
        isFolderLoading(false);
        if (res?.result) {
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
        onClose();
        loadWorkflowData();
      })
      .catch((err) => {
        isFolderLoading(false);
        if (err) {
          toast(toastFunctionToaster(err?.message, "error"));
        }
      });
  };

  return (
    <>
      <Button
        ml="2"
        size={"sm"}
        colorScheme={"blue"}
        onClick={onOpen}
      >
        Move to Folder
      </Button>
      <Modal
        borderWidth="1px"
        borderColor={defaultThemeColor}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent bg="#1c181e" color="#fff">
          <ModalHeader>Move to Project Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody borderTopWidth={"1px"} borderColor={defaultGreyColor}>
            <GridItem
              bg="green.500"
              borderRadius={"10px"}
              p="10px"
              cursor={"pointer"}
              borderWidth={"0.5px"}
              borderColor={greyBgColor}
              key={"Back"}
              onClick={() => {
                setCurrentFolderId(parentFolderId);
              }}
            >
              <Flex alignItems={"center"}>
                <AiOutlineArrowLeft fontSize={"22px"} color="#fff" />
                <Flex flexWrap={"wrap"} ml="3">
                  <Text color="#fff" w="100%" fontWeight={"600"}>
                    Back
                  </Text>
                </Flex>
              </Flex>
            </GridItem>
            {fullProjectFolders?.length ? (
              fullProjectFolders.map((data) => (
                <GridItem
                  bg={
                    data?.folder_uuid === destFolderId
                      ? "green.500"
                      : defaultGreyColor
                  }
                  borderRadius={"10px"}
                  p="10px"
                  cursor={"pointer"}
                  borderWidth={"0.5px"}
                  borderColor={greyBgColor}
                  key={data?.folder_uuid}
                  onClick={() => {
                    if (data?.folder_uuid === destFolderId) {
                      setCurrentFolderId(data?.folder_uuid);
                    } else {
                      setDestFolderId(data?.folder_uuid);
                    }
                  }}
                >
                  <Flex alignItems={"center"}>
                    <AiFillFolder fontSize={"22px"} color="#fff" />
                    <Flex flexWrap={"wrap"} ml="3">
                      <Text color="#fff" w="100%" fontWeight={"600"}>
                        {data?.name}
                      </Text>
                    </Flex>
                  </Flex>
                </GridItem>
              ))
            ) : (
              <GridItem
                bg={defaultGreyColor}
                borderRadius={"5px"}
                p="10px"
                cursor={"pointer"}
                borderWidth={"0.5px"}
                borderColor={greyBgColor}
              >
                <Flex alignItems={"center"}>
                  <Text color="#fff" w="100%" fontWeight={"600"}>
                    No Project Folders Found
                  </Text>
                </Flex>
              </GridItem>
            )}
          </ModalBody>

          <ModalFooter borderTopWidth={"1px"} borderColor={defaultGreyColor}>
            <Button size="sm" colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              size="sm"
              colorScheme="green"
              onClick={() => {
                assignWorkflow();
                setCurrentFolderId(startFolderId);
                setParentFolderId(prevFolderId);
              }}
              isDisabled={folderLoading || destFolderId === startFolderId}
              isLoading={folderLoading}
            >
              Move
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ProjectToolManagement;
