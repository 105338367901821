// Menu Actions
export const SET_NOTIFICATION_MESSAGE = "SET_NOTIFICATION_MESSAGE";
export const SET_NOTIFICATION_STATUS_COLOR = "SET_NOTIFICATION_STATUS_COLOR";
export const SET_BUSINESS_INFO_STATUS = "SET_BUSINESS_INFO_STATUS";
export const SET_EXPAND_MIN_SIDEMENU = "SET_EXPAND_MIN_SIDEMENU";
export const SET_WORKFLOW_OBJECT = "SET_WORKFLOW_OBJECT";
export const WORK_FLOW_IMAGE_DATA = "WORK_FLOW_IMAGE_DATA";
export const WORKFLOW_ENGINE_LOADER = "WORKFLOW_ENGINE_LOADER";
export const WORKFLOW_ENGINE_QUEUE = "WORKFLOW_ENGINE_QUEUE";
export const REFERENCE_IMAGE_IMPOSE_FLAG = "REFERENCE_IMAGE_IMPOSE_FLAG";
export const WORKFLOW_IMG_COUNTER = "WORKFLOW_IMG_COUNTER";
export const SELECTED_IMG_TYPE = "SELECTED_IMG_TYPE";
export const PROFILE_LOGO = "PROFILE_LOGO";
export const LINEDRAW_FLAG = "LINEDRAW_FLAG";
export const SELECTED_TAB_FLAG = "SELECTED_TAB_FLAG";
export const SELECTED_CURRENT_IMAGE = "SELECTED_CURRENT_IMAGE";
export const TEXT_IMAGE_STABILITY = "TEXT_IMAGE_STABILITY";
export const COPY_TO_CLIP = "COPY_TO_CLIP";
export const STORE_DESIGN_PROJECT_OBJ = "STORE_DESIGN_PROJECT_OBJ";
