import React from 'react';
import {
  Box,
  Flex,
  Icon,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { BsGrid1X2Fill } from 'react-icons/bs';
import {
  AiOutlineCodeSandbox,
  AiFillInfoCircle,
  AiFillCaretDown,
} from 'react-icons/ai';
import { defaultThemeColor } from '../../../constants/index';

function DefaultTable() {
  return (
    <Box>
      <Flex
        p="10px"
        color="#b0b0b0"
        borderBottomWidth="0.5px"
        borderColor="#494552"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <Icon
            w={5}
            h={5}
            color={'#ee7733'}
            as={AiOutlineCodeSandbox}
            mr="10px"
          ></Icon>
          <Text>Prodmap Workspace Group 1</Text>
          <Text
            ml="10px"
            fontSize={'10px'}
            p="3px"
            bg="#241a52"
            borderRadius={'5px'}
            color="#947ff5"
          >
            AWS/US WEST 2 (OREGON)
          </Text>
          <Text
            ml="10px"
            fontSize={'10px'}
            p="3px"
            bg="#183828"
            color="#36ad73"
            borderRadius={'5px'}
          >
            FREE TRIAL
          </Text>
        </Flex>
        <Flex alignItems="center">
          {/* <Text mr="10px" color="#d7d7d7">
            Last Updated: 12:29:47PM
          </Text> */}
          <Button
            color="#d7d7d7"
            variant="outline"
            borderRadius="0px"
            borderColor="#6b6b6b"
            fontSize="10px"
            size="xs"
            p="10px"
            mr="10px"
          >
            Contact US
          </Button>
          <IconButton
            color="#d7d7d7"
            variant="outline"
            borderRadius="0px"
            borderColor="#6b6b6b"
            fontSize="10px"
            size="xs"
            p="10px"
            icon={<BsGrid1X2Fill />}
          />
        </Flex>
      </Flex>
      <Tabs>
        <TabList
          color="#d7d7d7"
          borderBottomWidth="0.5px"
          borderBottomColor="#494552"
        >
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomWidth: '0.5px',
              borderBottomColor: '#c552ff',
              marginBottom: '-1px',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Overwier
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomWidth: '0.5px',
              borderBottomColor: '#c552ff',
              marginBottom: '-1px',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Workspace
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomWidth: '0.5px',
              borderBottomColor: { defaultThemeColor },
              marginBottom: '-1px',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Database
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomWidth: '0.5px',
              borderBottomColor: { defaultThemeColor },
              marginBottom: '-1px',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Access
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomWidth: '0.5px',
              borderBottomColor: { defaultThemeColor },
              marginBottom: '-1px',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Firewall
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomWidth: '0.5px',
              borderBottomColor: { defaultThemeColor },
              marginBottom: '-1px',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Security
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomWidth: '0.5px',
              borderBottomColor: { defaultThemeColor },
              marginBottom: '-1px',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Updates
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p="0">
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th
                      w="20%"
                      color="#b0b0b0"
                      fontWeight="normal"
                      fontSize="11px"
                      borderRightWidth="1px"
                      borderRightColor="#494552"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      p="10px"
                    >
                      Name
                    </Th>
                    <Th
                      color="#b0b0b0"
                      fontWeight="normal"
                      fontSize="11px"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      borderRightWidth="1px"
                      borderRightColor="#494552"
                    >
                      Status
                    </Th>
                    <Th
                      color="#b0b0b0"
                      fontWeight="normal"
                      fontSize="11px"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      borderRightWidth="1px"
                      borderRightColor="#494552"
                    >
                      Table Count
                    </Th>
                    <Th
                      color="#b0b0b0"
                      fontWeight="normal"
                      fontSize="11px"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      borderRightWidth="1px"
                      borderRightColor="#494552"
                    >
                      Memory Usage
                    </Th>
                    <Th
                      color="#b0b0b0"
                      fontWeight="normal"
                      fontSize="11px"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      borderRightWidth="1px"
                      borderRightColor="#494552"
                    >
                      <Flex alignItems="center">
                        <Text mr="3px">Index Disk Usage </Text>
                        <AiFillInfoCircle></AiFillInfoCircle>
                      </Flex>
                    </Th>
                    <Th
                      color="#b0b0b0"
                      fontWeight="normal"
                      fontSize="11px"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      borderRightWidth="1px"
                      borderRightColor="#494552"
                    >
                      <Flex alignItems="center">
                        <Text mr="3px">Disk Usage </Text>
                        <AiFillInfoCircle></AiFillInfoCircle>
                      </Flex>
                    </Th>
                    <Th
                      color="#b0b0b0"
                      fontWeight="normal"
                      fontSize="11px"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      borderRightWidth="1px"
                      borderRightColor="#494552"
                    >
                      <Flex alignItems="center">
                        <Text mr="3px"> Partition Count </Text>
                        <AiFillInfoCircle></AiFillInfoCircle>
                      </Flex>
                    </Th>
                    <Th
                      color="#b0b0b0"
                      fontWeight="normal"
                      fontSize="11px"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      borderRightWidth="1px"
                      borderRightColor="#494552"
                    >
                      Actions
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      s2_database_martech_41286a64
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      Detached
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      -
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      -
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      -
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      -
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      -
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      <Menu size="sm">
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<AiFillCaretDown fontSize="14px" />}
                          variant="normal"
                          height="15px"
                        />
                        <MenuList>
                          <MenuItem>Connect Directly</MenuItem>
                          <MenuItem>SQL Editor</MenuItem>
                          <MenuItem>Detach/Attach Database</MenuItem>
                          <MenuItem>View Workspace Details</MenuItem>
                          <MenuItem>Resize Workspace Details</MenuItem>
                          <MenuItem>Suspend Workspace Details</MenuItem>
                          <MenuItem>
                            <Text color="red">Suspend Workspace Details</Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                  <Tr bg="#1c181e">
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      information_schema
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      Attached
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      165
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      -
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      -
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      -
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      -
                    </Td>
                    <Td
                      color="#b0b0b0"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      fontWeight="normal"
                      fontSize="11px"
                    >
                      <Menu size="sm">
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<AiFillCaretDown fontSize="14px" />}
                          variant="normal"
                          height="15px"
                        />
                        <MenuList>
                          <MenuItem>Connect Directly</MenuItem>
                          <MenuItem>SQL Editor</MenuItem>
                          <MenuItem>Detach/Attach Database</MenuItem>
                          <MenuItem>View Workspace Details</MenuItem>
                          <MenuItem>Resize Workspace Details</MenuItem>
                          <MenuItem>Suspend Workspace Details</MenuItem>
                          <MenuItem>
                            <Text color="red">Suspend Workspace Details</Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default DefaultTable;
