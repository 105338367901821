// Chakra Imports
import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Input,
  Button,
  FormControl,
  Box,
  Flex,
  Image,
  Heading,
  Text,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

// Component Imports
import Logo from "../../image/LandingPageImg/logo.png";
import { errorColor, defaultThemeColor } from "../../constants/index";
import LandingBgImage from "../../image/LandingPageImg/landingbg.png";

// Api Services
import { updateDefaultPassword } from "../../services/sharedServices";

function ChangePassword() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useToast();
  const [error, setError] = useState({});
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [changePasswordForm, setChangePassword] = useState({
    email: state.email,
    current_password: "",
    new_password: "",
  });
  const [retype_password, setRetypePassword] = useState("");
  const [retypePasswordErrMsg, setRetypePasswordErrMsg] = useState("");

  const onUpdateField = (e) => {
    const nextFormState = {
      ...changePasswordForm,
      [e.target.name]: e.target.value,
    };
    setChangePassword(nextFormState);
  };

  // password validation
  const validateFieldForm = () => {
    let fields = changePasswordForm;
    let errors = {};
    let formIsValid = true;
    const checkWhiteSpace = /^\S*$/;
    const checkLoerCase = /^(?=.*[a-z]).*$/;
    const checkUpperCase = /^(?=.*[A-Z]).*$/;
    const checkNumber = /.*[0-9].*/;
    const isValidLength = /^.{8,}$/;

    if (!fields["current_password"]) {
      formIsValid = false;
      errors["current_password"] = "*Current Password Is Reqired.";
    }
    if (!fields["new_password"]) {
      formIsValid = false;
      errors["new_password"] = "*New Password Cannot Be Empty.";
    } else if (!checkWhiteSpace.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] = "*Password must not contain Whitespaces";
    } else if (!checkLoerCase.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] =
        "*Password must have at least one Lowercase Character";
    } else if (!checkUpperCase.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] =
        "*Password must have at least one Uppercase Character";
    } else if (!checkNumber.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] = "*Password must have at least one Number";
    } else if (!isValidLength.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] = "*Password must be minimum 8 characters long";
    }

    if (!retype_password) {
      formIsValid = false;
      setRetypePasswordErrMsg("*Confirm Password Cannot Be Empty.");
    } else if (fields["new_password"] !== retype_password) {
      formIsValid = false;
      setRetypePasswordErrMsg("*New Password didn't match.");
    }
    setError(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      // TODO - Make sure changePasswordForm['email'] has the value;
      updateDefaultPassword(changePasswordForm)
        .then((res) => {
          if (res.result) {
            navigate("/login");
            toast({
              title: res?.message,
              status: "success",
              isClosable: true,
              duration: 3000,
            });
          } else {
            toast({
              title: res?.message,
              status: "error",
              isClosable: true,
              duration: 3000,
            });
          }
        })
        .catch((err) => {
          if (err)
            toast({
              title: err?.message,
              status: "error",
              isClosable: true,
              duration: 3000,
            });
        });
    }

    //
  };

  const handleClick1 = () => setShow1(!show1);
  const handleClick2 = () => setShow2(!show2);
  const handleClick3 = () => setShow3(!show3);

  return (
    <Box bg="black" color="#fff">
      <Box
        bgImage={LandingBgImage}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        h="100vh"
        p="10"
      >
        <Box className="top-content">
          <Flex justifyContent={"space-between"}>
            <Flex
              alignItems={"center"}
              cursor={"pointer"}
              onClick={() => {
                navigate("/");
              }}
            >
              <Image src={Logo}></Image>
              <Text ml="3" fontWeight={"600"} fontSize={"26px"}>
                Corbu
              </Text>
            </Flex>
            <Button
              color="#000"
              bg={defaultThemeColor}
              _hover={{ boxShadow: "none" }}
              _active={{ bg: "none" }}
              onClick={() => {
                navigate("/business/RegisterUser");
              }}
            >
              Register
            </Button>
          </Flex>
        </Box>

        <Flex justifyContent="end" mt="2%">
          <Box bg="#141718" borderRadius={"20px"} p="20px" w="40%">
            <Heading fontFamily="Poppins" mb="4" fontSize={"24px"}>
              Change Default Password
            </Heading>

            <form onSubmit={handleSubmit}>
              <FormControl>
                <Text fontSize={"14px"} color="grey" mt="2">
                  Currently you have logged into using the default system
                  generated password. Please change your password to your own
                  here, before you can successfuly use the Corbu AI Platform.
                </Text>
              </FormControl>
              <FormControl mt="20px">
                <InputGroup>
                  <Input
                    type={show1 ? "text" : "password"}
                    value={changePasswordForm.current_password}
                    name="current_password"
                    onChange={onUpdateField}
                    placeholder="Enter Current Password"
                    size="sm"
                    borderRadius="10px"
                    height={"45px"}
                    border="none"
                    bg="#232627"
                    fontSize="16px"
                    _placeholder={{ color: "#fff" }}
                    _focus={{ boxShadow: "none" }}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick1}
                      bg="none"
                      _hover={{ bg: "none" }}
                    >
                      {show1 ? (
                        <BiHide size="sm" color="grey" />
                      ) : (
                        <BiShow size="sm" color="grey" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text color={errorColor} mt="2">
                  {error?.current_password}
                </Text>
              </FormControl>

              <FormControl>
                <InputGroup>
                  <Input
                    type={show2 ? "text" : "password"}
                    value={changePasswordForm.new_password}
                    name="new_password"
                    onChange={onUpdateField}
                    placeholder="Enter New Password"
                    size="sm"
                    borderRadius="10px"
                    height={"45px"}
                    border="none"
                    bg="#232627"
                    fontSize="16px"
                    _placeholder={{ color: "#fff" }}
                    _focus={{ boxShadow: "none" }}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick2}
                      bg="none"
                      _hover={{ bg: "none" }}
                    >
                      {show2 ? (
                        <BiHide size="sm" color="grey" />
                      ) : (
                        <BiShow size="sm" color="grey" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <Text color={errorColor} mt="2">
                  {error?.new_password}
                </Text>
              </FormControl>

              <FormControl>
                <InputGroup>
                  <Input
                    type={show3 ? "text" : "password"}
                    value={retype_password}
                    name="retype_password"
                    onChange={(e) => {
                      setRetypePassword(e.target.value);
                    }}
                    placeholder="Confirm New Password"
                    size="sm"
                    borderRadius="10px"
                    height={"45px"}
                    border="none"
                    bg="#232627"
                    fontSize="16px"
                    _placeholder={{ color: "#fff" }}
                    _focus={{ boxShadow: "none" }}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick3}
                      bg="none"
                      _hover={{ bg: "none" }}
                    >
                      {show3 ? (
                        <BiHide size="sm" color="grey" />
                      ) : (
                        <BiShow size="sm" color="grey" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text fontSize="sm" color={errorColor} mt="1">
                  {retypePasswordErrMsg}
                </Text>
              </FormControl>
              <Button
                mt={5}
                fontWeight="500"
                fontSize="16px"
                w="100%"
                bg={defaultThemeColor}
                borderRadius={"10px"}
                _hover={{ boxShadow: "none" }}
                _active={{ bg: "none" }}
                color={"#000"}
                type="submit"
              >
                Update Password
              </Button>
            </form>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default ChangePassword;
