// React Imports
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  RadioGroup,
  Flex,
  Radio,
  Text,
  Grid,
} from "@chakra-ui/react";

// Component Imports
import { defaultGreyColor, defaultThemeColor, greyBgColor } from "../../../../../../constants";
import { stabilityAspectRatios } from "../../../../../../constants/Workflow";

function AspectRatio({ useStabilityEngine }) {
  const dispatch = useDispatch();
  const [aspectType, setAspectType] = useState(
    useStabilityEngine === "true" ? "1:1" : "Square"
  );

  const setAspectRatioDetails = (aspectType, width, height) => {
    setAspectType(aspectType);
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageWidth", value: width },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageHeight", value: height },
    });
  };
  return (
    <Box mb="4" bg={greyBgColor} borderRadius={"10px"}>
      <Box justifyContent={"space-between"}>
        <Text
          fontSize="14px"
          color="#fff"
          p="2"
          borderBottom={"1px"}
          borderBottomColor={"#d7d7d7"}
          fontWeight={"600"}
        >
          Aspect Ratio
        </Text>
      </Box>
      {useStabilityEngine === "true" ? (
        <RadioGroup
          flexDirection={"row-reverse"}
          justifyContent={"start"}
          value={aspectType}
          defaultValue="1:1"
          onChange={(value) =>
            setAspectRatioDetails(
              value,
              stabilityAspectRatios[value].width,
              stabilityAspectRatios[value].height
            )
          }
          isrequired="true"
          p="2"
        >
          <Grid templateColumns="repeat(2, 1fr)" gap={1}>
            {Object.entries(stabilityAspectRatios).map((data) => (
              <Radio
                value={data[0]}
                color="#fff"
                flexDirection={"row-reverse"}
                justifyContent={"start"}
              >
                <Text
                  color="#fff"
                  fontSize={"14px"}
                  fontWeight={"600"}
                  w="100px"
                >
                  {" "}
                  {data[1].title}
                </Text>
                <Text color="#fff" fontSize={"12px"} fontWeight={"500"} mt="1">
                  ({data[1].width} x {data[1].height}){" "}
                </Text>
              </Radio>
            ))}
          </Grid>
        </RadioGroup>
      ) : (
        <Flex mt="4" p="3" justifyContent={"space-between"}>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderWidth="1px"
            borderStyle={"dashed"}
            h={"110px"}
            w={"80px"}
            mr="10px"
            cursor={"pointer"}
            onClick={() => {
              setAspectRatioDetails("Portrait", 1024, 1408);
            }}
            borderColor={
              aspectType === "Portrait" ? defaultThemeColor : defaultGreyColor
            }
            color={aspectType === "Portrait" ? defaultThemeColor : "#fff"}
          >
            <Box textAlign={"center"}>
              <Text fontWeight={"500"}>Portrait</Text>
              <Text fontWeight={"500"}>1024 x 1408</Text>
            </Box>
          </Flex>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderWidth="1px"
            borderStyle={"dashed"}
            h="80px"
            w="40%"
            mr="10px"
            cursor={"pointer"}
            onClick={() => {
              setAspectRatioDetails("Landscape", 1408, 1024);
            }}
            borderColor={
              aspectType === "Landscape" ? defaultThemeColor : defaultGreyColor
            }
            color={aspectType === "Landscape" ? defaultThemeColor : "#fff"}
          >
            <Box textAlign={"center"}>
              <Text fontWeight={"500"}>Landscape</Text>
              <Text fontWeight={"500"}>1408 x 1024</Text>
            </Box>
          </Flex>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderWidth="1px"
            borderStyle={"dashed"}
            h="80px"
            w="80px"
            cursor={"pointer"}
            onClick={() => {
              setAspectRatioDetails("Square", 1024, 1024);
            }}
            borderColor={
              aspectType === "Square" ? defaultThemeColor : defaultGreyColor
            }
            color={aspectType === "Square" ? defaultThemeColor : "#fff"}
          >
            <Box textAlign={"center"}>
              <Text fontWeight={"500"}>Square</Text>
              <Text fontWeight={"500"}>1024 x 1024</Text>
            </Box>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}

export default AspectRatio;
