const speedQualityLabel = "Speed x Quality";
const imageSharpnessLabel = "Image Sharpness";
const controlStrengthLabel = "Guidance Resemblance";
const promptStrengthLabel = "Prompt Strength";
const inpaintStrengthLabel = "Inpaint Creativity";
const maskCropPaddingLabel = "Inpaint Harmonization";
const refAdainScaleLabel = "Reference Color Strength";
const refAttnScaleLabel = "Reference Structure Strength";
const imageSeedLabel = "Image Seed";
const guidanceTypeLabel = "Guidance Type";
const modelAdapterLabel = "Model Adapter";
const modelAdapterWeightLabel = "Adapter Strength";

const titleConstants = {
  speedQualityLabel,
  imageSharpnessLabel,
  controlStrengthLabel,
  promptStrengthLabel,
  inpaintStrengthLabel,
  maskCropPaddingLabel,
  refAdainScaleLabel,
  refAttnScaleLabel,
  imageSeedLabel,
  guidanceTypeLabel,
  modelAdapterLabel,
  modelAdapterWeightLabel,
};

export default titleConstants;
