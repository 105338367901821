import axios from './axios';
import { serviceUrls } from './serviceUrls';

export const fetchDepartmentServices = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchDepartmentList, data, config);
};

export const addDepartmentDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addDepartmentDetails, data, config);
};

export const updateDepartmentData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.put(serviceUrls().updateDepartmentDetails, data, config);
};
