import axios from './axios';
import { serviceUrls } from './serviceUrls';

export const fetchTeamMemberData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(serviceUrls().fetchTeamMember, config);
};
