import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Flex,
  Image,
  Text,
  Select,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Center,
  LinkBox,
  LinkOverlay,
  Icon,
  Spacer,
} from "@chakra-ui/react";
import { AiOutlineArrowRight, AiOutlineInstagram } from "react-icons/ai";
import logoWithSm from "../../media/images/corbu-logo-sm.png";
import topImage from "../../media/images/modular-man-colors.png";
import CorbuBrandmark from "../../media/images/corbu-brandmark-design.png";
import LandingPage from "./LandingPage";

function LoginHome() {
  const joinTheWaitlist = useRef(null);

  const scrollToDiv = (divType) => {
    if (divType === "signup") {
      if (joinTheWaitlist.current) {
        ReactDOM.findDOMNode(joinTheWaitlist.current).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };
  return (
    <LandingPage />
    // <VStack
    //   w={"100%"}
    //   overflow={"hidden"}
    //   gap={{
    //     base: 0,
    //     md: 2,
    //     xl: 2,
    //     sm: 0,
    //   }}
    // >
    //   <Flex
    //     w={"100%"}
    //     p={5}
    //     boxShadow="md"
    //     position="fixed"
    //     zIndex={999}
    //     opacity={0.8}
    //     bg={"gray.100"}
    //     borderBottomWidth={"0.5px"}
    //     borderBottomColor={"gray.300"}
    //   >
    //     <Image
    //       ml={{
    //         md: 20,
    //         xl: 20,
    //         sm: 0,
    //       }}
    //       src={logoWithSm}
    //       w={{
    //         base: "100px",
    //         md: "200px",
    //         xl: "200px",
    //         sm: 50,
    //       }}
    //       objectFit={"contain"}
    //     />
    //     <Spacer />
    //     <Box
    //       w={"200px"}
    //       _hover={{
    //         cursor: "pointer",
    //         boxShadow: "xl",
    //         borderWidth: "0.5px",
    //         borderColor: "gray.300",
    //       }}
    //       onClick={() => {
    //         scrollToDiv("signup");
    //       }}
    //       h={"60px"}
    //       rounded={"md"}
    //       boxShadow="lg"
    //       shadow={"2xl"}
    //       size={"lg"}
    //       fontSize={"xl"}
    //       pl={10}
    //       pr={10}
    //     >
    //       <Center w={"100%"} h={"100%"}>
    //         Sign up
    //       </Center>
    //     </Box>
    //   </Flex>
    //   <Flex
    //     w={"100%"}
    //     h={"500px"}
    //     direction={"row"}
    //     pt={"150px"}
    //     flexWrap="wrap"
    //   >
    //     <Flex
    //       w={{
    //         base: "100%",
    //         md: "50%",
    //         xl: "50%",
    //         sm: "100%",
    //       }}
    //       direction={"column"}
    //       pl={{
    //         base: 2,
    //         md: 10,
    //         xl: 10,
    //         sm: 0,
    //       }}
    //     >
    //       <Text
    //         fontSize={{
    //           base: "3xl",
    //           md: "7xl",
    //           xl: "7xl",
    //           sm: "3xl",
    //         }}
    //         ml={{
    //           base: 0,
    //           md: 20,
    //           xl: 20,
    //           sm: 0,
    //         }}
    //         w={{
    //           base: "100%",
    //           md: "80%",
    //           xl: "80%",
    //           sm: "100%",
    //         }}
    //         textAlign={{
    //           base: "center",
    //           md: "start",
    //           xl: "start",
    //           sm: "center",
    //         }}
    //         fontWeight={{
    //           base: "600",
    //           md: "initial",
    //           xl: "initial",
    //           sm: "600",
    //         }}
    //       >
    //         Unprecedented Design AI
    //       </Text>
    //       <Text
    //         fontSize={{
    //           base: "md",
    //           md: "xl",
    //           xl: "xl",
    //           sm: "md",
    //         }}
    //         fontWeight={{
    //           base: "600",
    //           md: "initial",
    //           xl: "initial",
    //           sm: "600",
    //         }}
    //         ml={{
    //           base: 0,
    //           md: 20,
    //           xl: 20,
    //           sm: 0,
    //         }}
    //         w={{
    //           base: "100%",
    //           md: "60%",
    //           xl: "60%",
    //           sm: "100%",
    //         }}
    //         textAlign={{
    //           base: "center",
    //           md: "start",
    //           xl: "start",
    //           sm: "center",
    //         }}
    //         mt={"10px"}
    //       >
    //         For Architects, Home Builders, Interior Designers, and anyone
    //         designing in the Built World.
    //       </Text>
    //     </Flex>
    //     <Box
    //       mt={{
    //         base: "8",
    //         md: "initial",
    //         xl: "initial",
    //         sm: "8",
    //       }}
    //       w={{
    //         base: "100%",
    //         md: "50%",
    //         xl: "50%",
    //         sm: "100%",
    //       }}
    //     >
    //       <Image src={topImage} w={"600px"} />
    //     </Box>
    //   </Flex>
    //   <Flex alignItems={"center"} direction={"row"} h={"100px"}>
    //     <Flex direction={"column"} w={"200px"} ml={2}>
    //       <Text></Text>
    //       <Button
    //         onClick={() => scrollToDiv("signup")}
    //         mt={"6"}
    //         bg={"blue.200"}
    //         _hover={{ bg: "blue.300" }}
    //       >
    //         Get Early Access <Icon ml={2} as={AiOutlineArrowRight} />
    //       </Button>
    //     </Flex>
    //   </Flex>
    //   <Flex
    //     alignContent={"center"}
    //     alignItems={"center"}
    //     bg={"#E9F1FF"}
    //     direction={"column"}
    //     w={"100%"}
    //     height={{
    //       base: "inherit",
    //       md: "500px",
    //       xl: "500px",
    //       sm: "inherit",
    //     }}
    //     pt={{
    //       base: "0",
    //       md: "120",
    //       xl: "120",
    //       sm: "0",
    //     }}
    //   >
    //     <Text
    //       fontSize={{
    //         base: "3xl",
    //         md: "6xl",
    //         xl: "6xl",
    //         sm: "3xl",
    //       }}
    //       ml={{
    //         base: "10",
    //         md: "40",
    //         xl: "40",
    //         sm: "10",
    //       }}
    //       mr={{
    //         base: "10",
    //         md: "40",
    //         xl: "40",
    //         sm: "10",
    //       }}
    //       fontWeight={{
    //         base: "600",
    //         md: "initial",
    //         xl: "initial",
    //         sm: "600",
    //       }}
    //       color={"gray.600"}
    //       textAlign={{
    //         base: "center",
    //         md: "start",
    //         xl: "start",
    //         sm: "center",
    //       }}
    //       pt={{
    //         base: "10",
    //         md: "initial",
    //         xl: "initial",
    //         sm: "10",
    //       }}
    //     >
    //       Make Design Decisions Faster with the
    //     </Text>
    //     <Text
    //       fontSize={{
    //         base: "3xl",
    //         md: "6xl",
    //         xl: "6xl",
    //         sm: "3xl",
    //       }}
    //       ml={{
    //         base: "10",
    //         md: "40",
    //         xl: "40",
    //         sm: "10",
    //       }}
    //       mr={{
    //         base: "10",
    //         md: "40",
    //         xl: "40",
    //         sm: "10",
    //       }}
    //       fontWeight={{
    //         base: "600",
    //         md: "initial",
    //         xl: "initial",
    //         sm: "600",
    //       }}
    //       pt={{
    //         base: "10",
    //         md: "initial",
    //         xl: "initial",
    //         sm: "10",
    //       }}
    //       color={"gray.600"}
    //     >
    //       Power of Generative AI
    //     </Text>
    //   </Flex>
    //   <Flex
    //     w={"100%"}
    //     tag={"JoinTheWaitlist"}
    //     alignItems={"center"}
    //     direction={"column"}
    //     h={{
    //       base: "initial",
    //       md: "800px",
    //       xl: "800px",
    //       sm: "initial",
    //     }}
    //     bg={"#E9F1FF"}
    //     ref={joinTheWaitlist}
    //     p={5}
    //     boxShadow="md"
    //     borderBottomWidth={"0.5px"}
    //     borderBottomColor={"gray.300"}
    //   >
    //     <Box
    //       px={{
    //         base: "3",
    //         md: "20",
    //         xl: "20",
    //         sm: "3",
    //       }}
    //       w={{
    //         base: "95%",
    //         md: "50%",
    //         xl: "50%",
    //         sm: "95%",
    //       }}
    //       bg={"#E9F1FF"}
    //       rounded="md"
    //       border="1px"
    //       borderColor="gray.200"
    //     >
    //       <Text
    //         fontSize={{
    //           base: "3xl",
    //           md: "5xl",
    //           xl: "5xl",
    //           sm: "3xl",
    //         }}
    //         mt={{
    //           base: "5",
    //           md: "20",
    //           xl: "20",
    //           sm: "5",
    //         }}
    //         fontWeight={{
    //           base: "600",
    //           md: "bold",
    //           xl: "bold",
    //           sm: "600",
    //         }}
    //       >
    //         Get Early Access
    //       </Text>
    //       <Text fontSize={"xl"} mt={2} mb={10}>
    //         Join our email list and get early access to Corbu
    //       </Text>
    //       <Box
    //         p={4}
    //         w={{
    //           base: "100%",
    //           md: "600",
    //           xl: "600",
    //           sm: "100%",
    //         }}
    //         rounded={"lg"}
    //         borderWidth={"0.5px"}
    //         borderColor={"blue.200"}
    //       >
    //         <VStack
    //           as="form"
    //           action="https://corbu.us21.list-manage.com/subscribe/post?u=3c5a408528d8db9da5f287a51&amp;id=ac62f24cee&amp;f_id=0068d8e6f0"
    //           // action="https://page.us21.list-manage.com/subscribe/post?u=3c5a408528d8db9da5f287a51&amp;id=ac62f24cee&amp;f_id=0068d8e6f0"
    //           method="post"
    //           id="mc-embedded-subscribe-form"
    //           name="mc-embedded-subscribe-form"
    //           spacing={4}
    //           align="left"
    //           target="_blank"
    //         >
    //           <FormControl>
    //             <FormLabel>Email Address</FormLabel>
    //             <Input
    //               type="email"
    //               name="EMAIL"
    //               required
    //               placeholder="Your email address"
    //             />
    //             <Text id="mce-EMAIL-HELPERTEXT" className="helper_text"></Text>
    //           </FormControl>
    //           <Flex w={"100%"} direction={"row"}>
    //             <FormControl>
    //               <FormLabel>First Name</FormLabel>
    //               <Input
    //                 type="text"
    //                 name="FNAME"
    //                 required
    //                 placeholder="Your first name"
    //               />
    //             </FormControl>
    //             <FormControl>
    //               <FormLabel>Last Name</FormLabel>
    //               <Input
    //                 type="text"
    //                 name="LNAME"
    //                 required
    //                 placeholder="Your last name"
    //               />
    //             </FormControl>
    //           </Flex>
    //           <Flex w={"100%"} direction={"column"}>
    //             <FormLabel>Design Firm Website</FormLabel>
    //             <Input
    //               type="text"
    //               name="MMERGE7"
    //               required
    //               placeholder="Design Firm Website"
    //             />
    //           </Flex>
    //           <FormControl>
    //             <FormLabel>I am a (an)</FormLabel>
    //             <Box w={"100%"}>
    //               <Select class="" id="mce-undefined">
    //                 <option value=""></option>
    //                 <option value="Investor">Investor</option>
    //                 <option value="Designer">Designer</option>
    //                 <option value="Student">Student</option>
    //                 <option value="Consumer wanting a design">
    //                   Consumer wanting a design
    //                 </option>
    //               </Select>
    //             </Box>
    //           </FormControl>
    //           <Box id="mce-responses" className="clear foot">
    //             <Box
    //               className="response"
    //               id="mce-error-response"
    //               display="none"
    //             ></Box>
    //             <Box
    //               className="response"
    //               id="mce-success-response"
    //               display="none"
    //             ></Box>
    //           </Box>
    //           <Box
    //             aria-hidden="true"
    //             style={{ position: "absolute", left: "-5000px" }}
    //           >
    //             <Input type="text" />
    //           </Box>
    //           <Flex w={"100%"} alignItems={"center"} color={"white"}>
    //             <Button
    //               type="submit"
    //               name="subscribe"
    //               _hover={{ bg: "pink.300" }}
    //               bg={"pink.500"}
    //               w={"200px"}
    //             >
    //               Join!! <Icon ml={2} as={AiOutlineArrowRight} />
    //             </Button>
    //           </Flex>
    //         </VStack>
    //       </Box>
    //     </Box>
    //   </Flex>
    //   <Flex
    //     direction={"column"}
    //     w={"100%"}
    //     h={{
    //       base: "initial",
    //       md: "500px",
    //       xl: "500px",
    //       sm: "initial",
    //     }}
    //     alignItems={"center"}
    //     mb={{
    //       base: "0",
    //       md: "100",
    //       xl: "100",
    //       sm: "0",
    //     }}
    //     p={5}
    //     boxShadow="md"
    //     borderBottomWidth={"0.5px"}
    //     borderBottomColor={"gray.300"}
    //   >
    //     <Flex
    //       direction={"row"}
    //       alignItems={"center"}
    //       justifyContent={"space-between"}
    //       w={{
    //         base: "100%",
    //         md: "60%",
    //         xl: "60%",
    //         sm: "100%",
    //       }}
    //       mt={{
    //         base: "0",
    //         md: "20",
    //         xl: "20",
    //         sm: "0",
    //       }}
    //     >
    //       <Box>
    //         <Image src={CorbuBrandmark} h={"80px"} />
    //       </Box>

    //       <Box>
    //         <Text fontSize={"2xl"} mb={5}>
    //           Follow Us
    //         </Text>
    //         <LinkBox as="article" isExternal mr={3}>
    //           <LinkOverlay
    //             href="https://instagram.com/corbu.ai"
    //             target="_blank"
    //           >
    //             <Icon
    //               as={AiOutlineInstagram}
    //               color={"pink.300"}
    //               h={"40px"}
    //               w={"40px"}
    //             />
    //           </LinkOverlay>
    //         </LinkBox>
    //       </Box>
    //     </Flex>
    //     <Divider
    //       w={{
    //         base: "100%",
    //         md: "80%",
    //         xl: "80%",
    //         sm: "100%",
    //       }}
    //       mt={{
    //         base: "5",
    //         md: "50px",
    //         xl: "50px",
    //         sm: "5",
    //       }}
    //     />
    //     <Flex
    //       alignItems={"center"}
    //       justifyContent={"space-between"}
    //       color={"gray.400"}
    //       mt={{
    //         base: "5",
    //         md: "20",
    //         xl: "20",
    //         sm: "5",
    //       }}
    //       w={{
    //         base: "100%",
    //         md: "35%",
    //         xl: "35%",
    //         sm: "100%",
    //       }}
    //     >
    //       <Text>info@corbu.ai</Text>
    //       <Text>Privacy Policy</Text>
    //       <Text>Terms of Service</Text>
    //     </Flex>
    //   </Flex>
    // </VStack>
  );
}

export default LoginHome;
