// React Chakra Imports
import { FaPlus } from "react-icons/fa";
import { useDisclosure } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import {
  Flex,
  Input,
  Modal,
  Text,
  Button,
  useToast,
  ModalBody,
  FormLabel,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";

// Component Imports
import { getEmailId } from "../../../utils/localStorageIndex";
import {
  errorColor,
  defaultThemeColor,
  themeFontSizeMediumHeading,
} from "../../../constants/index";

// Api Services Import
import { addDepartmentDetails } from "../../../services/departmentServices";

function AddDepartmentModal(props) {
  const toast = useToast();
  const email = getEmailId();
  const [loading, isLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [departmentName, setDepartmentName] = useState("");
  const [updateDepartment, setUpdateDepartment] = useState({});
  const [updateDepartmentFlag, setUpdateDepartmentFlag] = useState(false);
  const [departmentNameErrMsg, setDepartmentNameErrMsg] = useState(false);

  useEffect(() => {
    if (props.sendEditableData.department_name) {
      setUpdateDepartment(props.sendEditableData);
      setDepartmentName(props.sendEditableData.department_name);
      setUpdateDepartmentFlag(true);
      onOpen();
    }
  }, [props.sendEditableData]);

  const storeDepartmentData = () => {
    if (departmentName.length > 0) {
      isLoading(true);
      let objBody = {
        email: email,
        department_name: departmentName,
      };
      saveDepartmentDetails(objBody);
    } else {
      setDepartmentNameErrMsg(!departmentNameErrMsg);
    }
  };

  //   save new department data
  const saveDepartmentDetails = (objBody) => {
    addDepartmentDetails(objBody)
      .then((res) => {
        isLoading(false);
        if (res.result) {
          props.onSaveSetFlag(true);
          setDepartmentName("");
          setDepartmentNameErrMsg(false);
          onClose();
          toast({
            title: res.message,
            status: "success",
            isClosable: true,
            duration: 3000,
          });
        } else {
          onClose();
          setDepartmentName("");
          setDepartmentNameErrMsg(false);
          toast({
            title: res.message,
            status: "error",
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        onClose();
        isLoading(false);
        setDepartmentName("");
        setDepartmentNameErrMsg(false);
      });
  };

  //   Todo:Send update data to parent component
  const updateDepartmentData = () => {
    if (departmentName.length > 0) {
      const departmentData = { ...updateDepartment };
      departmentData.department_name = departmentName;
      props.fetchEditedData(departmentData);
      onClose();
    } else {
      setDepartmentNameErrMsg(true);
    }
  };

  // Todo:On Model Close Reset Data
  const onCloseModal = () => {
    onClose();
    setUpdateDepartmentFlag(false);
    setUpdateDepartment({});
    props?.resetDepartmentDetails();
  };

  return (
    <Flex alignItems="center" justifyContent="space-between" color="#fff">
      <Button
        bg="white"
        size="sm"
        rightIcon={<FaPlus />}
        onClick={() => {
          onOpen();
          setDepartmentName("");
        }}
      >
        Add New Department
      </Button>
      <>
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onCloseModal();
          }}
          borderWidth="1px"
          borderColor={defaultThemeColor}
        >
          <ModalOverlay />
          <ModalContent bg="#1c181e" color="#fff">
            <Text p="10px" pl="25px">
              {updateDepartmentFlag ? "Update" : " Add New"} Department For Your
              Business
            </Text>
            <ModalCloseButton />
            <ModalBody>
              {updateDepartmentFlag ? null : (
                <Text fontSize={themeFontSizeMediumHeading}>
                  Additional users may be invited to your department, who can
                  signup for a Corbu account through accepting an invitation
                  sent to their email.
                </Text>
              )}
              <FormControl isRequired mt={updateDepartmentFlag ? "2" : "7"}>
                <FormLabel fontSize={themeFontSizeMediumHeading}>
                  Department Name
                </FormLabel>
                <Input
                  type="text"
                  borderRadius="10px"
                  height={"50px"}
                  border="none"
                  bg={"#151117"}
                  name="departmentName"
                  value={departmentName}
                  onChange={(e) => {
                    setDepartmentName(e.target.value);
                  }}
                  fontSize={themeFontSizeMediumHeading}
                  placeholder="Enter Department Name"
                  size="sm"
                />
                {departmentNameErrMsg ? (
                  <Text
                    color={errorColor}
                    mt="2"
                    fontSize={themeFontSizeMediumHeading}
                  >
                    Department Name is required
                  </Text>
                ) : null}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              {updateDepartmentFlag ? (
                <Button
                  size="xs"
                  bg={defaultThemeColor}
                  color="#000"
                  borderRadius="2px"
                  mr={3}
                  isDisabled={loading ? true : false}
                  isLoading={loading ? true : false}
                  onClick={() => {
                    updateDepartmentData();
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  size="xs"
                  bg={defaultThemeColor}
                  color="#000"
                  borderRadius="2px"
                  mr={3}
                  isDisabled={loading ? true : false}
                  isLoading={loading ? true : false}
                  onClick={() => {
                    storeDepartmentData();
                  }}
                >
                  Save
                </Button>
              )}
              <Button
                size="xs"
                bg="white"
                color="#000"
                borderRadius="2px"
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Flex>
  );
}

export default AddDepartmentModal;
