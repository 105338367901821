import React from 'react';
import { Box, Flex, Text, Img, Button } from '@chakra-ui/react';
import DataPipelineImg from '../../../image/data-pipelines.PNG';
import { defaultThemeColor } from '../../../constants/index';

function EmptyPage() {
  return (
    <Flex justifyContent="center" alignItems="center" height="80vh">
      <Box
        borderWidth="1px"
        borderColor="#494552"
        borderRadius="5px"
        p="5"
        w="50%"
        textAlign="center"
      >
        <Flex justifyContent="center" mb="5">
          <Img src={DataPipelineImg}></Img>
        </Flex>
        <Text fontSize="16px" color="#d7d7d7">
          Data Pipelines
        </Text>
        <Text color="#d7d7d7" my="3">
          Ingest live and historical data from <br />
          different sources into your cluster
        </Text>
        <Button
          size="sm"
          mt="3"
          bg={defaultThemeColor}
          color="#000"
          borderColor={defaultThemeColor}
        >
          Learn How to load data
        </Button>
      </Box>
    </Flex>
  );
}

export default EmptyPage;
