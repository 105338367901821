// Chakra Imports
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Button,
  FormControl,
  Box,
  Flex,
  Image,
  HStack,
  Text,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import Logo from "../../image/LandingPageImg/logo.png";
import { errorColor, defaultThemeColor } from "../../constants";
import { createBusinessUsers } from "../../services/businessServices";
import LandingBgImage from "../../image/LandingPageImg/landingbg.png";

function RegisterBusinessUser() {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const toast = useToast();
  const [loading, isLoading] = useState(false);

  const [businessUserObj, setBusinessUserObj] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [verificationEmail, setVerificationEmail] = useState("");
  const [verificationErrMsg, setVerificationErrMsg] = useState("");

  const onUpdateField = (e) => {
    const nextFormState = {
      ...businessUserObj,
      [e.target.name]: e.target.value,
    };
    setBusinessUserObj(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = businessUserObj;
    let errors = {};
    let formIsValid = true;
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "*First Name is required.";
    }
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "*Last Name is required.";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Email is required.";
    } else if (!emailPattern.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "*Please enter valid email address.";
    }
    if (!verificationEmail) {
      formIsValid = false;
      setVerificationErrMsg("*Email is required.");
    } else if (fields["email"] !== verificationEmail) {
      formIsValid = false;
      setVerificationErrMsg("*Email does not match.");
    }
    setError(errors);
    return formIsValid;
  };

  const submitBusinessUserData = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      setVerificationErrMsg("");
      createBusinessUsers(businessUserObj)
        .then((res) => {
          isLoading(false);
          if (res.result) {
            toast({
              title: res?.message,
              status: "success",
              isClosable: true,
              duration: 3000,
            });
          } else {
            isLoading(false);
            toast({
              title: res?.message,
              status: "error",
              isClosable: true,
              duration: 3000,
            });
          }
        })
        .catch((err) => {
          isLoading(false);
          if (err)
            toast({
              title: err?.response?.data?.detail,
              status: "error",
              isClosable: true,
              duration: 3000,
            });
        });
    }
  };

  return (
    <Box bg="black" color="#fff">
      <Box
        bgImage={LandingBgImage}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        h="100vh"
        p="10"
      >
        <Box className="top-content">
          <Flex justifyContent={"space-between"}>
            <Flex
              alignItems={"center"}
              cursor={"pointer"}
              onClick={() => {
                navigate("/");
              }}
            >
              <Image src={Logo}></Image>
              <Text ml="3" fontWeight={"600"} fontSize={"26px"}>
                Corbu
              </Text>
            </Flex>
            <Button
              color="#000"
              bg={defaultThemeColor}
              _hover={{ boxShadow: "none" }}
              _active={{ bg: "none" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </Flex>
        </Box>

        <Flex justifyContent="end" mt="2%">
          <Box bg="#141718" borderRadius={"20px"} p="20px" w="40%">
            <Text fontSize={"24px"}>Create your Corbu Business Account</Text>

            <form
              onSubmit={submitBusinessUserData}
              style={{ marginTop: "20px" }}
            >
              <FormControl mb="4">
                <Input
                  type="text"
                  value={businessUserObj.first_name}
                  name="first_name"
                  onChange={onUpdateField}
                  placeholder="Enter Your First Name"
                  size="sm"
                  borderRadius="10px"
                  height={"45px"}
                  border="none"
                  bg="#232627"
                  fontSize="16px"
                  _placeholder={{ color: "#fff" }}
                  _focus={{ boxShadow: "none" }}
                />
                <Text fontSize="sm" color={errorColor} mt="1">
                  {error?.first_name}
                </Text>
              </FormControl>

              <FormControl mb="4">
                <Input
                  type="text"
                  value={businessUserObj.last_name}
                  name="last_name"
                  onChange={onUpdateField}
                  placeholder="Enter Your Last Name"
                  size="sm"
                  borderRadius="10px"
                  height={"45px"}
                  border="none"
                  bg="#232627"
                  fontSize="16px"
                  _placeholder={{ color: "#fff" }}
                  _focus={{ boxShadow: "none" }}
                />
                <Text fontSize="sm" color={errorColor} mt="1">
                  {error?.last_name}
                </Text>
              </FormControl>

              <FormControl mb="4">
                <Input
                  type="text"
                  value={businessUserObj.email}
                  name="email"
                  onChange={onUpdateField}
                  placeholder="Enter Your Email Address"
                  size="sm"
                  borderRadius="10px"
                  height={"45px"}
                  border="none"
                  bg="#232627"
                  fontSize="16px"
                  _placeholder={{ color: "#fff" }}
                  _focus={{ boxShadow: "none" }}
                />
                <Text fontSize="sm" color={errorColor} mt="1">
                  {error?.email}
                </Text>
              </FormControl>

              <FormControl>
                <Input
                  type="text"
                  value={verificationEmail}
                  name="verificationEmail"
                  onChange={(e) => {
                    setVerificationEmail(e.target.value);
                  }}
                  placeholder="Re-Enter Your Email Address"
                  size="sm"
                  borderRadius="10px"
                  height={"45px"}
                  border="none"
                  bg="#232627"
                  fontSize="16px"
                  _placeholder={{ color: "#fff" }}
                  _focus={{ boxShadow: "none" }}
                />
                <Text fontSize="sm" color={errorColor} mt="1">
                  {verificationErrMsg}
                </Text>
              </FormControl>
              <HStack w={"100%"} mt={5}>
                <Button
                  fontWeight="500"
                  fontSize="16px"
                  w="100%"
                  bg={defaultThemeColor}
                  borderRadius={"10px"}
                  _hover={{ boxShadow: "none" }}
                  _active={{ bg: "none" }}
                  color={"#000"}
                  type="submit"
                  isDisabled={loading ? true : false}
                  isLoading={loading ? true : false}
                >
                  Register
                </Button>
              </HStack>
            </form>
            <Flex mt="1rem">
              To Login your Corbu account click here...{" "}
              <Text
                ml="3"
                color={defaultThemeColor}
                cursor="pointer"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default RegisterBusinessUser;
