import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { getSessionId } from '../utils/localStorageIndex';

export default function ProtectedLayout() {
  const session_id = getSessionId();
  const isAuthenticated = session_id ? true : false;

  return <>{isAuthenticated ? <Outlet /> : <Navigate to="/" />}</>;
}
