import React from 'react';
import { Box } from '@chakra-ui/react';
import NestedRoutes from '../../SubRoute/NestedRoutes';

function BUManagement() {
  const notificationContent = [
    { number: 3, label: 'Business Users' },
    { number: 5, label: 'Active Job Workflows' },
  ];

  const businessHeaderArr = [
    // { label: 'View Information', value: 'BizTeamDetails' },
    { label: 'Business Users List', value: 'BizUsersListMgmt' },
    { label: 'Invite Business Users', value: 'InviteBusinessUser' },
  ];

  return (
    <Box>
      <NestedRoutes
        notificationContentData={notificationContent}
        headerArrData={businessHeaderArr}
        breadCrumTitle="Business Team Management"
        /* This number is the total number of items in the url */
        /* URL - business/bizmanagement/BizUsersListMgmt has 3 splits */
        split={3} 
      ></NestedRoutes>
    </Box>
  );
}

export default BUManagement;
