// React Imports
import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Button, Tooltip, useToast } from "@chakra-ui/react";
import { AiOutlineStar, AiOutlineHeart, AiFillDislike } from "react-icons/ai";

// Component Import
import { defaultThemeColor, greyBgColor } from "../../../../../constants";

// Api Services Import
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import {
  getLikedImageEntity,
  unLikeSelectedImage,
  LikeUpdateGeneratedImage,
} from "../../../../../services/projectTemplateService";

function LikeImage(props) {
  const [imageStarId, setImageStarId] = useState("");
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const [styleCount, setStyleCount] = useState(0);
  const [qualityCount, setQualityCount] = useState(0);
  const [lightingCount, setLightingCount] = useState(0);
  const [showCommentDiv, setShowCommentDiv] = useState(false);
  const [likeRatingData, setLikeRatingData] = useState(props?.likeRating || []);

  useEffect(() => {
    if (likeRatingData?.length) {
      likeRatingData?.forEach((data) => {
        const parsedIndexValue = parseInt(data?.image_id);
        if (props?.imageIndexValue === parsedIndexValue) {
          setLightingCount(data?.lightening_count);
          setQualityCount(data?.quality_count);
          setStyleCount(data?.style_count);
          setImageStarId(data?.image_star_uuid);
        }
      });
    }
  }, [props?.imageIndexValue, likeRatingData]);

  useEffect(() => {
    setShowCommentDiv(false);
  }, [props]);

  // Todo:Render Liking Component
  const StarComponent = ({ count, setCount, defaultThemeColor }) => (
    <>
      {[...Array(5)].map((_, index) => (
        <AiOutlineStar
          key={index}
          onClick={() => {
            if (index + 1 === count) {
              setCount(0);
            } else {
              setCount(index + 1);
            }
          }}
          color={count >= index + 1 ? defaultThemeColor : null}
          cursor="pointer"
          fontSize="20px"
        />
      ))}
    </>
  );

  // Todo:Save Like Api
  const saveLike = () => {
    let obj = {
      project_uuid: props?.projectId,
      workflow_uuid: props?.imageWorkflowId,
      workflow_counter: props?.workflowCounter,
      image_id: props?.imageIndexValue,
      lightening_count: lightingCount,
      style_count: styleCount,
      quality_count: qualityCount,
    };
    isLoading(true);
    LikeUpdateGeneratedImage(obj)
      .then((res) => {
        isLoading(false);
        if (res?.result) {
          toast(toastFunctionToaster(res?.message, "success"));
          props?.sendDataToCardFlip("true");
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
          props?.sendDataToCardFlip("false");
        }
      })
      .catch((err) => {
        if (err) {
          isLoading(false);
          toast(toastFunctionToaster("Something Went Wrong", "error"));
        }
      });
  };

  // Todo:Reset After save or close
  const resetLikeFeature = () => {
    setStyleCount(0);
    setQualityCount(0);
    setLightingCount(0);
    setShowCommentDiv(false);
  };

  // Todo:Remove Liked Images
  const removeLikedImage = (e) => {
    e.preventDefault();
    if (imageStarId?.length) {
      isLoading(true);
      let obj = { image_star_uuid: imageStarId };
      unLikeSelectedImage(obj)
        .then((res) => {
          isLoading(false);
          if (res?.result) {
            resetLikeFeature();
            toast(toastFunctionToaster(res?.message, "success"));
            props?.sendDataToCardFlip("true");
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
            props?.sendDataToCardFlip("false");
          }
        })
        .catch((err) => {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
          isLoading(false);
          resetLikeFeature();
        });
    }
  };

  // Todo:Check Available Ratings
  useEffect(() => {
    if (showCommentDiv) {
      handleLikeRatings();
    } else {
      resetLikeFeature();
    }
  }, [showCommentDiv]);

  const handleLikeRatings = () => {
    //   Todo:To fetched liked images
    isLoading(true);
    let obj = {
      project_uuid: props?.projectId,
      workflow_uuid: props?.imageWorkflowId,
      workflow_counter: props?.workflowCounter,
    };

    getLikedImageEntity(obj)
      .then((res) => {
        if (res?.result) {
          if (res?.data[0]?.details) {
            setLikeRatingData(res?.data[0]?.details);
          }
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster(err.message, "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  return (
    <Box position={"relative"}>
      <Tooltip label="Like" placement="top">
        <Box>
          <AiOutlineHeart
            size={props?.fontSize ? props?.fontSize : "30px"}
            cursor="pointer"
            color={defaultThemeColor}
            onClick={() => setShowCommentDiv(!showCommentDiv)}
          />
        </Box>
      </Tooltip>
      {showCommentDiv ? (
        <Box
          position={"absolute"}
          right={"0px"}
          top="33px"
          bg={greyBgColor}
          borderRadius={"10px"}
          color="#fff"
          p="2"
          zIndex={"9"}
        >
          <Box position={"relative"}>
            <Flex alignItems={"center"}>
              <Text fontSize={"16px"} w="65px">
                Lighting
              </Text>
              <Flex ml="2">
                <StarComponent
                  count={lightingCount}
                  setCount={setLightingCount}
                  defaultThemeColor={defaultThemeColor}
                />
              </Flex>
            </Flex>
            <Flex alignItems={"center"}>
              <Text fontSize={"16px"} w="60%">
                Style
              </Text>
              <Flex ml="2">
                <StarComponent
                  count={styleCount}
                  setCount={setStyleCount}
                  defaultThemeColor={defaultThemeColor}
                />
              </Flex>
            </Flex>
            <Flex alignItems={"center"}>
              <Text fontSize={"16px"} w="40%">
                Quality
              </Text>
              <Flex ml="2">
                <StarComponent
                  count={qualityCount}
                  setCount={setQualityCount}
                  defaultThemeColor={defaultThemeColor}
                />
              </Flex>
            </Flex>
            <Flex justifyContent="end" mt="2">
              <Button
                size="xs"
                mr="2"
                colorScheme="green"
                onClick={(e) => {
                  if (
                    styleCount === 0 &&
                    qualityCount === 0 &&
                    lightingCount === 0
                  ) {
                    removeLikedImage(e);
                  } else {
                    saveLike();
                  }
                }}
                isDisabled={loading ? true : false}
                isLoading={loading ? true : false}
              >
                Save{" "}
              </Button>
              <Button
                size="xs"
                colorScheme="red"
                onClick={(e) => {
                  removeLikedImage(e);
                }}
                rightIcon={<AiFillDislike />}
                isDisabled={loading ? true : false}
                isLoading={loading ? true : false}
              >
                Unlike
              </Button>
            </Flex>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

export default LikeImage;
