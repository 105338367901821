// Chakra Imports
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AiOutlineDelete,
  AiOutlineFileImage,
  AiOutlineHistory,
} from "react-icons/ai";
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Icon,
  Text,
  HStack,
  Image,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Flex,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import EditWorkflow from "../../EditWorkflow/EditWorkflow";
import { renderWorkflowTitle } from "../../../../constants/Workflow";
import { defaultThemeColor, greyBgColor } from "../../../../constants";
import { resetWorkflowData } from "../../../../utils/resetWorkflowData";
import { toastFunctionToaster } from "../../../../utils/toastFunction";

// Api Services
import { fetchHistoryWorkflowData } from "../../../../services/businessServices";
import {
  DeleteProjectWorkflow,
  DeleteProjectWorkflowTree,
} from "../../../../services/projectTemplateService";

function HistoryWorkflow({
  projectId,
  resourceId,
  workflowId,
  loadWorkflowData,
  setProjectPhoto,
}) {
  const toast = useToast();
  const btnRef = React.useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rootWorkflowData, setRootWorkflowData] = useState(null);
  const [rootNumWorkflows, setRootNumWorkflows] = useState(0);
  const [deleteAlert, isDeleteAlert] = useState(false);
  const [deleteAllAlert, isDeleteAllAlert] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);

  const fetchRootServices = () => {
    setLoading(true);
    const obj = {
      project_uuid: projectId,
      workflow_uuid: workflowId,
      max_depth: 10,
    };
    fetchHistoryWorkflowData(obj)
      .then((res) => {
        if (res?.data[0]?.pagination_data[0]?.records) {
          setRootWorkflowData(res?.data[0]?.pagination_data[0]?.records);
          setRootNumWorkflows(res?.data[0]?.total_records);
        } else {
          setRootWorkflowData([]);
          setRootNumWorkflows(0);
        }
      })
      .catch(() => {
        toast(toastFunctionToaster("Something Went Wrong...", "error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Todo:Re-direct to Workflow Component
  const loadWorkflow = useCallback(
    (e, data) => {
      e.preventDefault();
      e.stopPropagation();

      resetWorkflowData(dispatch);
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "project_uuid",
          value: projectId,
        },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "resource_uuid",
          value: resourceId,
        },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "workflow_uuid",
          value: data.workflow_uuid,
        },
      });
      navigate("/business/designProject", {
        state: {
          launcher: "relaunch",
          resource_uuid: resourceId,
          project_uuid: projectId,
          workflow_uuid: data.workflow_uuid,
          workflow_model_code: data.model_code,
          object_name: data.info[0].object_name,
          object_url: data.info[0].url,
          workflow_state: "existing",
          workflow_name: data?.name,
        },
      });
    },
    [dispatch, navigate, projectId, resourceId]
  );

  const deleteAllWorkflowData = () => {
    isDeleteAllAlert(false);
    let objBody = {
      project_uuid: projectId,
      workflow_uuid: workflowId,
    };
    setLoading(true);
    DeleteProjectWorkflowTree(objBody)
      .then((res) => {
        if (res?.data?.length > 0) {
          handleClose();
          loadWorkflowData();
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        if (err) {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteWorkflowData = () => {
    if (workflowToDelete && projectId) {
      isDeleteAlert(false);
      let objBody = {
        project_uuid: projectId,
        workflow_uuid: workflowToDelete,
      };
      setLoading(true);
      DeleteProjectWorkflow(objBody)
        .then((res) => {
          if (res?.data?.length > 0) {
            if (workflowToDelete === workflowId) {
              handleClose();
              loadWorkflowData();
            } else {
              fetchRootServices();
            }
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster("Something Went Wrong", "error"));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Todo:Render History Component With Nested Child Component
  const renderHistoryData = () => {
    if (rootWorkflowData?.length) {
      return rootWorkflowData.map((workflowData, index) => (
        <WorkflowItem
          key={index}
          workflowData={workflowData}
          greyBgColor={greyBgColor}
          defaultThemeColor={defaultThemeColor}
          loadWorkflow={loadWorkflow}
          renderWorkflowTitle={renderWorkflowTitle}
        />
      ));
    }
    return null;
  };

  // Todo:Workflow HOC (High-Order) Component
  const WorkflowItem = ({
    workflowData,
    greyBgColor,
    defaultThemeColor,
    loadWorkflow,
    renderWorkflowTitle,
  }) => (
    <>
      <HStack
        cursor="pointer"
        mb="10px"
        bg={greyBgColor}
        p="10px"
        borderRadius="5px"
      >
        <Image
          src={workflowData.info?.[0]?.url || "/path/to/default/image.png"}
          alt={workflowData?.name || "Workflow Image"}
          style={{
            height: "64px",
            marginRight: "8px",
            objectFit: "contain",
          }}
        />
        <Box w="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="#fff" fontWeight="600" ml="2">
              {workflowData?.name ? workflowData?.name : "Untitled Workflow"}
            </Text>
            <Box
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {setProjectPhoto && (
                <Button mr="2" size={"xs"} colorScheme="teal">
                  <Icon
                    w={5}
                    h={5}
                    as={AiOutlineFileImage}
                    onClick={() => {
                      setProjectPhoto(workflowData?.workflow_uuid);
                    }}
                  />
                </Button>
              )}
              <EditWorkflow
                workflowData={workflowData}
                workflowName={workflowData?.name}
                project_uuid={projectId}
                size="sm"
                renderList={handlerenderList}
              />
              <Button ml="2" size={"xs"} colorScheme="red">
                <Icon
                  w={5}
                  h={5}
                  as={AiOutlineDelete}
                  onClick={() => {
                    isDeleteAlert(true);
                    setWorkflowToDelete(workflowData?.workflow_uuid);
                  }}
                />
              </Button>
            </Box>
          </Flex>

          <Text color={defaultThemeColor} fontWeight="600" ml="2">
            {renderWorkflowTitle(workflowData.model_code)}
          </Text>
          <Text color="#fff" ml="2">
            Created:{" "}
            <Text as="span" color={defaultThemeColor}>
              {workflowData?.started}
            </Text>
          </Text>
          <Text color="#fff" ml="2">
            Updated:{" "}
            <Text as="span" color="green.200">
              {workflowData?.updated}
            </Text>
          </Text>
          <Button
            size="xs"
            ml="2"
            mt="2"
            colorScheme="green"
            onClick={(e) => loadWorkflow(e, workflowData)}
          >
            Load Workflow
          </Button>
        </Box>
      </HStack>
      {workflowData.child_workflows?.length > 0 && (
        <Box mt="4" ml="4">
          {workflowData.child_workflows.map((childWorkflow, index) => (
            <WorkflowItem
              key={index}
              workflowData={childWorkflow}
              greyBgColor={greyBgColor}
              defaultThemeColor={defaultThemeColor}
              loadWorkflow={loadWorkflow}
              renderWorkflowTitle={renderWorkflowTitle}
            />
          ))}
        </Box>
      )}
    </>
  );

  // Todo:Reset Data After Workflow Name Is Updated
  const handlerenderList = (data) => {
    if (data) {
      fetchRootServices();
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Button
        size={"xs"}
        colorScheme="orange"
        onClick={(e) => {
          onOpen();
          fetchRootServices();
          e.stopPropagation();
        }}
        mr="2"
      >
        <Icon w={5} h={5} as={AiOutlineHistory} />
      </Button>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          handleClose();
        }}
        finalFocusRef={btnRef}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent bg={"#131415"}>
          <DrawerHeader>
            <HStack justifyContent="space-between">
              <Text color="#fff" fontWeight={"600"}>
                Workflow History
              </Text>
              <Button
                size={"sm"}
                colorScheme="red"
                onClick={() => {
                  isDeleteAllAlert(true);
                }}
              >
                Delete All Workflows
              </Button>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            {loading ? (
              <Flex
                h="60vh"
                w="100%"
                alignItems="center"
                justifyContent="center"
                mt="10"
              >
                <Spinner size="xl" color="#fff" />
              </Flex>
            ) : rootWorkflowData?.length ? (
              renderHistoryData()
            ) : (
              <Text
                fontSize={"16px"}
                fontWeight={"600"}
                color={defaultThemeColor}
                ml="2"
              >
                No Data Found
              </Text>
            )}
          </DrawerBody>
          <DrawerFooter justifyContent="space-between">
            <Text color="#fff" textAlign="left" fontWeight={"600"}>
              Total Workflows: {!loading && rootNumWorkflows}
            </Text>
            <Button
              size="sm"
              onClick={() => {
                handleClose();
              }}
            >
              Return
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Todo:Delete Workflow Popup */}
      <AlertDialog isOpen={deleteAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Workflow
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? The workflow and all associated data will be
              permanently deleted from storage!!
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => isDeleteAlert(false)} size="sm">
                Cancel
              </Button>
              <Button
                ml={3}
                size="sm"
                colorScheme="red"
                onClick={() => deleteWorkflowData()}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Todo:Delete All Workflow Popup */}
      <AlertDialog isOpen={deleteAllAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Workflow
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? All {rootNumWorkflows} workflows and all associated
              data will be permanently deleted from storage!!
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => isDeleteAllAlert(false)} size="sm">
                Cancel
              </Button>
              <Button
                ml={3}
                size="sm"
                colorScheme="red"
                onClick={() => deleteAllWorkflowData()}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default HistoryWorkflow;
