// Chakra - React Imports
import LazyLoad from "react-lazyload";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Image,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";

function SharedImageModal({ open, handleClose, imageArr, indexValue }) {
    const popupSliderRef = useRef(null);
    const [modalWidth, setModalWidth] = useState(0);

    const handleKeyDown = (e) => {
      if (popupSliderRef.current) {
        if (e.key === "ArrowLeft") {
          popupSliderRef.current.slickPrev();
        } else if (e.key === "ArrowRight") {
          popupSliderRef.current.slickNext();
        }
      };
    };

    const sliderSettings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: imageArr?.length, // Disable arrows until all images are loaded
      dots: imageArr?.length, // Disable dots until all images are loaded
      draggable: imageArr?.length, // Disable dragging until all images are loaded
      swipe: imageArr?.length, // Disable swipping until all images are loaded
      initialSlide: indexValue,
    };

    useEffect(() => {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    const handleImageLoad = (e) => {
      setModalWidth(e.target.naturalWidth);
    };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      scrollBehavior="outside"
      style={{ width: "100% !important" }}
    >
      <ModalOverlay />
      <ModalContent maxW={"80%"} width={modalWidth} mt="5" bg="#0b090c">
        <ModalCloseButton
          color="red.500"
          fontWeight={"600"}
          bg="#fff"
          top="0"
          right="0"
          zIndex="999"
        />
          <SlickSlider ref={popupSliderRef} {...sliderSettings}>
            {imageArr?.map((data, index) => (
              <LazyLoad key={index}>
                <Image
                  src={data?.url}
                  alt="Image"
                  objectFit="contain"
                  height="95vh"
                  bg="#0b090c"
                  width="100%"
                  onLoad={handleImageLoad}
                />
              </LazyLoad>
            ))}
          </SlickSlider>
      </ModalContent>
    </Modal>
  );
}

export default SharedImageModal;
