import React, { useEffect, useState } from 'react';
import { defaultThemeColor } from '../../../../constants';
import {
  Th,
  Td,
  Tr,
  Flex,
  Text,
  Thead,
  Tbody,
  Table,
  Spinner,
  TableContainer,
  Box,
  HStack,
  Icon,
} from '@chakra-ui/react';
import {
  fetchBusinessesListMinimum,
  fetchBusinessesUsersPaginated,
} from '../../../../services/businessServices';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';

function BusinessUsersList() {
  const [loading, isLoading] = useState(true);
  const [hrListLoader, setHrListLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [noRecordFlag, setNoRecordFlag] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState();
  const [hrUserList, setHrUserList] = useState([]);

  useEffect(() => {
    let requestBody = {
      email: getEmailId(),
      records_per_page: 10,
      page_id: 0,
      records_length: 0,
    };
    fetchBusinessesListMinimum(requestBody)
      .then(res => {
        isLoading(false);
        if (res.data.length > 0) {
          setTableData(res.data);
        } else {
          setTableData([]);
          setNoRecordFlag(true);
        }
      })
      .catch(err => {
        isLoading(false);
      });
  }, []);

  function getBusinessUsersListForBusinesss(selectedBusinessId) {
    if (selectedBusinessId != undefined) {
      let requestBody = {
        email: getEmailId(),
        business_uuid: selectedBusinessId,
        records_per_page: 10,
        page_id: 0,
        records_length: 0,
      };
      setHrListLoader(true);
      setSelectedBusinessId(selectedBusinessId);
      fetchBusinessesUsersPaginated(requestBody)
        .then(res => {
          setHrListLoader(false);
          isLoading(false);
          if (res.data.length > 0) {
            setHrUserList(res.data);
          } else {
            setHrUserList([]);
            setNoRecordFlag(true);
            setHrListLoader(false);
          }
        })
        .catch(err => {
          isLoading(false);
          setHrListLoader(false);
        });
    }
  }

  const headerTitleData = [
    { label: 'Business Name', value: 'Business_name' },
    { label: 'city', value: 'city' },
    { label: 'country', value: 'country' },
  ];

  const renderTableHeader = headerTitleData?.map((data, index) => (
    <Th
      color="#b0b0b0"
      fontWeight="normal"
      fontSize="11px"
      borderBottomWidth="0.5px"
      borderBottomColor="#494552"
      borderWidth="1px"
      borderColor="#494552"
      key={index}
    >
      {data.label}
    </Th>
  ));

  const renderTableData = tableData.map((data, index) => (
    <Tr
      bg="#1c181e"
      onClick={() => getBusinessUsersListForBusinesss(data.business_uuid)}
    >
      <Td
        color="#b0b0b0"
        borderBottomWidth="0.5px"
        borderBottomColor="#494552"
        fontWeight="normal"
        fontSize="11px"
      >
        {index + 1}
      </Td>
      {headerTitleData?.map((headerData, idx) => (
        <Td
          color="#b0b0b0"
          borderBottomWidth="0.5px"
          borderBottomColor="#494552"
          fontWeight="normal"
          fontSize="11px"
          key={idx * 2}
        >
          {data[headerData.value] ? data[headerData.value] : '-'}
        </Td>
      ))}
    </Tr>
  ));

  const renderHrUserList =
    hrUserList !== undefined &&
    hrUserList !== null &&
    hrUserList[0]?.records?.length ? (
      hrUserList[0].records.map((data, index) => (
        <Box
          m="1"
          p="1px"
          alignItems="center"
          justifyContent="space-between"
          bg="#1c181e"
          borderLeftWidth="5px"
          borderColor={defaultThemeColor}
          key={index + 1}
        >
          <Box>
            <Text mb="5px" fontSize={16}>
              {' '}
              <Icon fontSize={14} as={AiOutlineUser} mr={2}></Icon>
              {data.first_name ? data.first_name : '-'}
              <Text as="span" pl="10px">
                {data.last_name ? data.last_name : '-'}
              </Text>
            </Text>
            <Text mb="5px">
              <Icon fontSize={14} as={AiOutlinePhone} mr={2}></Icon>
               {data.email ? data.email : '-'}</Text>
          </Box>
        </Box>
      ))
    ) : (
      <Box
        m="1"
        p="10px"
        alignItems="center"
        justifyContent="space-between"
        bg="#1c181e"
        borderLeftWidth="5px"
        borderColor={'gray.400'}
      >
        <HStack>
          <Icon fontSize={20}></Icon>
          <Text mb="5px" fontSize={16}>
            {' '} There are no business users associated with this business.
          </Text>
        </HStack>
      </Box>
    );

  return (
    <>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10">
          <Spinner size="xl" />
        </Flex>
      ) : noRecordFlag ? (
        <Box
            m="1"
            p="5px"
            bg="#1c181e"
            borderLeftWidth="5px"
            borderColor={'red'}
          >
          <Text padding="10px" color="white" fontSize={16}>
            There are no business registered so far.
          </Text>
        </Box>
      ) : (
        <Flex w={'100%'} p={1} h={'calc(100vh - 150px)'}>
          <Box w="30%" mr="5" h={'calc(100vh - 150px)'} bg={'gray.200'} overflowY='scroll'>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th
                      color="#b0b0b0"
                      fontWeight="normal"
                      fontSize="11px"
                      borderWidth="1px"
                      borderColor="#494552"
                      borderBottomWidth="0.5px"
                      borderBottomColor="#494552"
                      p="10px"
                    >
                      Sr No
                    </Th>
                    {renderTableHeader}
                  </Tr>
                </Thead>
                <Tbody>{renderTableData}</Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            w="70%"
            minWidth="200px"
            borderWidth={'0.5px'}
            borderColor={'#494552'}
          >
            {/* {selectedBusinessId} */}
            {/* <hr />   */}
            {/* {JSON.stringify(hrUserList)} */}
            {hrListLoader ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                mt="10"
                h={'40vh'}
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              renderHrUserList
            )}
          </Box>
        </Flex>
      )}
    </>
  );
}

export default BusinessUsersList;
