// React Chakra Imports
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  Text,
  Flex,
  Image,
  Input,
  Button,
  Select,
  Drawer,
  useToast,
  FormLabel,
  DrawerBody,
  FormControl,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Switch,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

// Component Import
import {
  borderBottomColor,
  errorColor,
  greyBgColor,
  themeFontSizeMediumHeading,
} from "../../../constants";

// Services Import
import { setupReferenceObject } from "../../../services/projectTemplateService";
import {
  createResourceTemplate,
  updateResourceEntityStatus,
  updateResourceTemplateDetails,
} from "../../../services/resourceTemplateService";
import ObjectUploader from "../Playground/ObjectUploader";
import ImageFromResource from "../ProjectManagement/DesignProjects/ProjectWrokspace/BaseConfig/ImageFromResource";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function AddResourceTemplate(props) {
  const toast = useToast();
  const btnRef = React.useRef();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [resourceTypeChoice, setResourceTypeChoice] = useState("Commercial");
  const [resourceStatus, setResourceStatus] = useState(false);
  const [resourceCustomType, setResourceCustomType] = useState("");
  const [customTypeErr, setCustomTypeErr] = useState(false);
  const [projTemplateForm, setProjTemplateForm] = useState({
    resource_name: "",
    resource_type: "",
    resource_details: "",
    resource_requirements: "",
    resource_cover: [],
  });
  const resourceEditViewFlag = props.resourceEditViewFlag;
  const projTitle =
    resourceEditViewFlag === "editResourceTemplate"
      ? "Edit"
      : resourceEditViewFlag === "viewResourceTemplate"
      ? "View"
      : "Add";

  useEffect(() => {
    if (resourceEditViewFlag.length) {
      onOpen();
      setProjTemplateForm(props.sendEditableData);
      // setResourceTypeChoice(props?.sendEditableData?.resource_type);
      resourceType(props?.sendEditableData?.resource_type);
      setResourceStatus(props?.sendEditableData?.resource_status);
    }
  }, [props]);

  // Todo:Set Resource Type
  const resourceType = (choice) => {
    if (choice === "Commercial") {
      setResourceTypeChoice(choice);
    } else {
      setResourceTypeChoice("custom");
      setResourceCustomType(choice);
    }
  };

  //   Set form fields
  const onUpdateField = (e) => {
    const nextFormState = {
      ...projTemplateForm,
      [e.target.name]: e.target.value,
    };
    setProjTemplateForm(nextFormState);
  };

  //   Todo:Form validation for title and Type
  const validateFieldForm = () => {
    let fields = projTemplateForm;
    let errors = {};
    let formIsValid = true;

    if (!fields["resource_name"]) {
      formIsValid = false;
      errors["resource_name"] = "Resource title is required.";
    }
    if (resourceTypeChoice.trim().length === 0) {
      formIsValid = false;
      errors["resource_type"] = "*Resource Type is required.";
    }
    if (
      resourceTypeChoice === "custom" &&
      (!resourceCustomType || resourceCustomType.trim().length === 0)
    ) {
      formIsValid = false;
      setCustomTypeErr("*Enter Custom Resource Type.");
    } else {
      setCustomTypeErr("");
    }
    if (!fields["resource_details"]) {
      formIsValid = false;
      errors["resource_details"] = "*Resource description is required.";
    }
    setError(errors);
    return formIsValid;
  };

  //   Todo:Submit Resource details data
  const createResourceTemplateData = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      projTemplateForm["resource_type"] =
        resourceTypeChoice === "custom"
          ? resourceCustomType
          : resourceTypeChoice;
      createResourceTemplate(projTemplateForm)
        .then((res) => {
          isLoading(false);
          if (res.result) {
            onClose();
            resetUpdatedProjDetails("renderProjData");
            toastFunction(res.message, "success");
          } else {
            isLoading(false);
            toastFunction(res.message, "error");
          }
        })
        .catch((err) => {
          isLoading(false);
          if (err) {
            toastFunction(err.message, "error");
          }
        });
    }
  };

  //   Todo:Update Resource Details
  const updateResourceTemplateForm = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      projTemplateForm["resource_type"] =
        resourceTypeChoice === "custom"
          ? resourceCustomType
          : resourceTypeChoice;
      if (projTemplateForm["resource_cover"]?.length > 0) {
        projTemplateForm["resource_cover_image"] =
          projTemplateForm["resource_cover"][0]?.object_name;
      }
      delete projTemplateForm["resource_cover"];
      updateResourceTemplateDetails(projTemplateForm)
        .then((res) => {
          isLoading(false);
          if (res.status) {
            resetUpdatedProjDetails("renderProjData");
            toastFunction(res.message, "success");
          } else {
            toastFunction(res.message, "error");
          }
        })
        .catch((err) => {
          isLoading(false);
          toastFunction(err?.message, "error");
        });
    }
  };

  const resetUpdatedProjDetails = (data) => {
    onClose();
    props.resetEditableData(data);
  };

  //   Todo:Reset Form
  const resetFormValue = () => {
    onOpen();
    setProjTemplateForm({
      resource_name: "",
      resource_type: "",
      resource_details: "",
      resource_requirements: "",
      resource_cover: [],
    });
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  // Todo Update Resource  Status
  const updateResourceStatus = () => {
    let objData = {
      resource_uuid: props?.sendEditableData.resource_uuid,
      resource_status: String(!resourceStatus),
    };
    setResourceStatus(!resourceStatus);
    updateResourceEntityStatus(objData)
      .then((res) => {
        if (res.status) {
          toastFunction(res.message, "success");
          resetUpdatedProjDetails("renderProjData");
        } else {
          toastFunction(res.message, "error");
        }
      })
      .catch((err) => {
        toastFunction(err?.message, "error");
      });
  };

  const SupplementObjectHandler = (data) => {
    if (data !== null || data !== undefined) {
      let objBody = {
        object_info: data.key,
      };
      setupReferenceObject(objBody)
        .then((res) => {
          if (res?.data[0]?.object[0]?.url.length) {
            onUpdateField({
              target: { name: "resource_cover", value: res?.data[0]?.object },
            });
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          toast(toastFunctionToaster("Something Went Wrong...", "error"));
        });
    }
  };

  return (
    <>
      <Flex>
        <Flex
          textAlign="center"
          ref={btnRef}
          onClick={() => {
            resetFormValue();
          }}
          bg={"white"}
          p="5px"
          cursor="pointer"
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
          position={"absolute"}
          bottom="10%"
          left="55%"
          zIndex={1}
          h="50px"
          w="50px"
          color="#000"
        >
          <Icon fontSize={24} as={AiOutlinePlus}></Icon>
          {/* <Text size="sm" fontSize="14px">
              Create New Resource
            </Text> */}
        </Flex>
      </Flex>
      <Modal
        size="xl"
        isOpen={isOpen}
        placement="right"
        finalFocusRef={btnRef}
        fontFamily="Poppins"
        onClose={() => {
          resetUpdatedProjDetails(true);
          setResourceTypeChoice("");
        }}
      >
        <ModalOverlay />
        <ModalContent bg="#0b090c" display="flex" maxW={"50%"}>
          <ModalHeader
            color="#fff"
            fontWeight="normal"
            borderBottomWidth={"1px"}
            borderBottomColor={"#333334"}
          >
            {projTitle} New Resource
          </ModalHeader>
          <ModalCloseButton
            color="white"
            onClose={() => {
              resetUpdatedProjDetails();
            }}
          />
          <ModalBody color="#fff" py="5">
            <FormControl isRequired mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading}>
                Resource Title
              </FormLabel>
              <Input
                borderRadius="10px"
                height={"50px"}
                border="none"
                fontSize="14px"
                borderColor={borderBottomColor}
                bg="#1c181e"
                placeholder="Enter Resource Title"
                color="#fff"
                value={projTemplateForm.resource_name}
                onChange={onUpdateField}
                name="resource_name"
                _placeholder={{ color: "gray.500" }}
                disabled={
                  resourceEditViewFlag === "viewResourceTemplate" ? true : false
                }
              />
              <Text fontSize="sm" color={errorColor} mt="1">
                {error?.resource_name}
              </Text>
            </FormControl>
            <FormControl isRequired mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading}>
                Resource Type
              </FormLabel>

              <Box>
                <Select
                  borderRadius="10px"
                  height={"50px"}
                  border="none"
                  fontSize="14px"
                  borderColor={borderBottomColor}
                  bg="#1c181e"
                  // width="250px"
                  size="sm"
                  borderRightWidth="0px"
                  onSelect={(e) => {
                    setResourceTypeChoice(e.target.value);
                  }}
                  onChange={(e) => {
                    setResourceTypeChoice(e.target.value);
                  }}
                  value={resourceTypeChoice}
                >
                  <option disabled={true} style={{ color: "black" }}>
                    Please Select
                  </option>
                  <option value="Commercial" style={{ color: "black" }}>
                    Commercial
                  </option>
                  <option value="custom" style={{ color: "black" }}>
                    Custom Resource Type
                  </option>
                </Select>
                {resourceTypeChoice === "custom" ? (
                  <>
                    <Input
                      placeholder="Enter Custom Type"
                      borderRadius="10px"
                      height={"50px"}
                      border="none"
                      borderColor={borderBottomColor}
                      bg={greyBgColor}
                      value={resourceCustomType}
                      onChange={(e) => {
                        setResourceCustomType(e.target.value);
                      }}
                      color="#fff"
                      mt="4"
                    />
                    {customTypeErr ? (
                      <Text fontSize="sm" color={errorColor} mt="1">
                        {customTypeErr}
                      </Text>
                    ) : null}
                  </>
                ) : null}
                {error?.resource_type ? (
                  <Text fontSize="sm" color={errorColor} mt="1">
                    {error?.resource_type}
                  </Text>
                ) : null}
              </Box>
            </FormControl>
            <FormControl isRequired mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading}>
                Resource Description
              </FormLabel>
              <Input
                as="textarea"
                borderRadius="10px"
                border="none"
                fontSize="14px"
                borderColor={borderBottomColor}
                bg="#1c181e"
                rows={4}
                placeholder="Enter Resource Description"
                maxHeight={"200px"}
                size="xl"
                px="10px"
                value={projTemplateForm.resource_details}
                onChange={onUpdateField}
                name="resource_details"
                _placeholder={{ color: "gray.500" }}
                disabled={
                  resourceEditViewFlag === "viewResourceTemplate" ? true : false
                }
              />
              <Text fontSize="sm" color={errorColor} mt="1">
                {error?.resource_details}
              </Text>
            </FormControl>
            <FormControl mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading}>
                Resource Requirements
              </FormLabel>
              <Input
                as="textarea"
                borderRadius="10px"
                border="none"
                fontSize="14px"
                borderColor={borderBottomColor}
                bg="#1c181e"
                rows={4}
                px="10px"
                placeholder="Enter Resource Requirements"
                size="xl"
                maxHeight={"200px"}
                value={projTemplateForm.resource_requirements}
                onChange={onUpdateField}
                name="resource_requirements"
                _placeholder={{ color: "gray.500" }}
                disabled={
                  resourceEditViewFlag === "viewResourceTemplate" ? true : false
                }
              />
            </FormControl>
            {resourceEditViewFlag.length ? (
              <FormControl mb={3}>
                <FormLabel fontSize={themeFontSizeMediumHeading}>
                  Resource Status
                </FormLabel>
                <Switch
                  colorScheme="green"
                  isChecked={resourceStatus}
                  onChange={() => {
                    setResourceStatus(!resourceStatus);
                  }}
                />
              </FormControl>
            ) : null}
            {resourceEditViewFlag === "editResourceTemplate" && (
              <FormControl mb="4">
                <FormLabel fontSize={themeFontSizeMediumHeading}>
                  Resource Cover Image
                </FormLabel>
                {projTemplateForm.resource_cover?.length > 0 ? (
                  <VStack w={"100%"} align={"start"} position="relative" mt="2">
                    <Button
                      size={"xs"}
                      colorScheme="red"
                      onClick={() => {
                        onUpdateField({
                          target: { name: "resource_cover", value: [] },
                        });
                      }}
                      position="absolute"
                      right="0"
                      top="0"
                    >
                      <Icon w={5} h={5} as={AiOutlineDelete} />
                    </Button>
                    <Image
                      objectFit={"contain"}
                      src={projTemplateForm.resource_cover[0]?.url}
                      width="100%"
                      height={"200px"}
                      borderRadius={"10px"}
                      bg={greyBgColor}
                    ></Image>
                  </VStack>
                ) : (
                  <Box h={"100%"} w={"100%"} position="relative" mt="2">
                    <ObjectUploader
                      createObject={{
                        resource_uuid: props.sendEditableData?.resource_uuid,
                      }}
                      objectUploaded={(data) => SupplementObjectHandler(data)}
                    />
                    <ImageFromResource
                      objectRole={""}
                      passResourceDetails={(data, _) => {
                        SupplementObjectHandler({
                          key: data?.payload?.object_name,
                        });
                      }}
                      resourceId={props.sendEditableData?.resource_uuid}
                    />
                  </Box>
                )}
              </FormControl>
            )}

            {resourceEditViewFlag === "viewResourceTemplate" ? null : (
              <Flex>
                <Button
                  size="sm"
                  colorScheme="green"
                  isDisabled={loading ? true : false}
                  isLoading={loading ? true : false}
                  type="button"
                  w="100%"
                  textAlign={"center"}
                  onClick={(e) => {
                    resourceEditViewFlag === "editResourceTemplate"
                      ? updateResourceTemplateForm(e)
                      : createResourceTemplateData(e);
                  }}
                >
                  {resourceEditViewFlag === "editResourceTemplate"
                    ? "Update"
                    : "Add"}
                </Button>
                {/* <Button
                  size="sm"
                  colorScheme="red"
                  ml={3}
                  onClick={() => {
                    resetUpdatedProjDetails(true);
                  }}
                >
                  Close
                </Button> */}
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddResourceTemplate;
