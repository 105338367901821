// Chakra Imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  Spinner,
  Stack,
  Text,
  useToast,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import {
  AiOutlinePlus,
  AiOutlineCloseCircle,
  AiOutlineSearch,
} from "react-icons/ai";

// Component Imports
import BulkUpload from "./BulkUpload";
import BulkLabelerPanel from "./BulkLabeler";
import ObjectMetadataPanel from "./ObjectMetadata";
import { borderBottomColor, greyBgColor } from "../../../constants";
import { toastFunctionToaster } from "../../../utils/toastFunction";

// Api Services
import {
  countObjectsInFolders,
  listObjectsInFolders,
  searchImageResource,
} from "../../../services/resourceTemplateService";

function ResourceFolderImage(props) {
  const toast = useToast();
  const folderName = props?.folderName;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [objsLoading, setObjsLoading] = useState(false);
  const [folderObjects, setFolderObjects] = useState([]);
  const [selectedObjectUrl, setSelectedObjectUrl] = useState(null);
  const [selectedObjectName, setSelectedObjectName] = useState(null);
  const [launchMetadataPanel, setLaunchMetadataPanel] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [imageLoading, setImageLoading] = useState(true); // State for image loading
  const iScrollRef = useRef(null);

  useEffect(() => {
    setCurrentPage(1);
    setFolderObjects([]);
    setImageLoading(true); // Set to true when starting to load images
    renderFolderCounts();
    setSearchQuery("");
  }, [props]);

  useEffect(() => {
    if (searchQuery?.length) {
      searchSelectedResource();
    } else {
      renderFolderImage();
    }
  }, [currentPage, recordsPerPage, props?.folderName]);

  const renderFolderCounts = () => {
    if (props?.resource_uuid && folderName) {
      const objBody = {
        resource_uuid: props?.resource_uuid,
        folderKey: folderName,
      };
      countObjectsInFolders(objBody)
        .then((res) => {
          if (res?.data?.length > 0) {
            setTotalRecords(res.data[0].total_records);
            setTotalPages(
              Math.ceil(res.data[0].total_records / recordsPerPage)
            );
          } else {
            setTotalRecords(0);
            setTotalPages(0);
          }
        })
        .catch((err) => {
          toast(
            toastFunctionToaster("Error listing folder counts...", "error")
          );
        });
    }
  };

  const renderFolderImage = () => {
    if (props?.resource_uuid && folderName) {
      const objBody = {
        resource_uuid: props.resource_uuid,
        folderKey: folderName,
        records_per_page: (
          folderName === "output/"
            ? 2 * recordsPerPage
            : recordsPerPage
        ),
        page_id: currentPage,
      };
      setObjsLoading(true);
      listObjectsInFolders(objBody)
        .then((res) => {
          if (res?.data?.length > 0) {
            if (currentPage <= 1) {
              setFolderObjects(res.data[0].records);
            } else {
              setFolderObjects((prev) => [...prev, ...res.data[0].records]);
            }
            setHasMore(currentPage <= res?.data?.[0]?.total_pages);
            setTotalPages(res?.data?.[0]?.total_pages);
          } else {
            setHasMore(false);
            setTotalPages(0);
          }
        })
        .catch((err) => {
          toast(
            toastFunctionToaster("Error listing folder objects...", "error")
          );
          if (currentPage <= 1) {
            setFolderObjects([]);
          }
          setHasMore(false);
        })
        .finally(() => {
          setObjsLoading(false);
        });
    }
  };

  const searchSelectedResource = () => {
    setObjsLoading(true);
    const obj = {
      resource_uuid: props?.resource_uuid,
      folderKey: folderName,
      query: [searchQuery],
      rerank_results: false,
      page_id: currentPage,
      records_per_page: recordsPerPage
    };
    searchImageResource(obj)
      .then((res) => {
        if (res?.data[0]?.length) {
          const resArr = res?.data[0]?.map((data) => ([{
            object_name: data?.payload?.object_name,
            url: data?.payload?.url,
          }]));
          if (currentPage <= 1) {
            setFolderObjects(resArr);
          } else {
            setFolderObjects((prev) => [...prev, ...resArr]);
          }
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        toast(
          toastFunctionToaster("Error listing folder objects...", "error")
        );
        if (currentPage <= 1) {
          setFolderObjects([]);
        }
        setHasMore(false);
      })
      .finally(() => {
        setObjsLoading(false);
      });
  };

  const resetMetadataFormData = (data) => {
    setLaunchMetadataPanel(false);
  };

  function updateSelectedObj(objectKey) {
    setSelectedObjectUrl(objectKey.url);
    setSelectedObjectName(objectKey.object_name);
    setLaunchMetadataPanel(true);
  }

  const renderAllFolderObjects = () => {
    if (folderObjects.length > 0) {
      return folderObjects.map((data, index) => (
        <GridItem
          w="100%"
          cursor="pointer"
          rounded="md"
          onClick={() => {
            updateSelectedObj(data[0]);
            setSelectedImage(index);
          }}
          key={folderName + index}
        >
          <Image
            src={data?.[0]?.url}
            height="20vh"
            w="100%"
            borderRadius="10px"
            objectFit="cover"
            border={selectedImage === index ? "1px" : null}
            borderColor={selectedImage === index ? "green.200" : null}
            onLoad={() => setImageLoading(false)} // Set to false when an image is loaded
            onError={() => setImageLoading(false)} // Ensure loading state is false even if there's an error
          />
        </GridItem>
      ));
    }
  };

  const changeResourceObject = (value) => {
    if (value === "left" && selectedImage > 0) {
      const objectValue = folderObjects[selectedImage - 1];
      setSelectedImage(selectedImage - 1);
      setSelectedObjectUrl(objectValue[0]?.url);
      setSelectedObjectName(objectValue[0]?.object_name);
      setLaunchMetadataPanel(true);
    }

    if (value === "right" && selectedImage < folderObjects.length - 1) {
      const objectValue = folderObjects[selectedImage + 1];
      setSelectedImage(selectedImage + 1);
      setSelectedObjectUrl(objectValue[0]?.url);
      setSelectedObjectName(objectValue[0]?.object_name);
      setLaunchMetadataPanel(true);
    }
  };

  const loadMoreData = useCallback(() => {
    if (!objsLoading && hasMore && currentPage <= totalPages) {
      if (currentPage < totalPages) {
        setCurrentPage((prev) => {
          return prev + 1;
        });
      } else {
        setHasMore(false);
      }
    }
  }, [objsLoading, hasMore, currentPage, searchQuery, totalPages]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        iScrollRef.current.scrollTop + iScrollRef.current.clientHeight >=
        iScrollRef.current.scrollHeight - 20
      ) {
        loadMoreData();
      }
    };

    const currentRef = iScrollRef.current;
    currentRef.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      currentRef.removeEventListener("scroll", handleScroll);
    };
  }, [loadMoreData]);

  return (
    <Box bg="#151117" p="10px" mt="5" borderRadius="10px" height="55vh">
      <>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" w="60%">
            <Flex alignItems="center" w="100%">
              <Stack spacing={4} mr="3" w="90%">
                <InputGroup>
                  <InputLeftElement>
                    <AiOutlineSearch color="white" />
                  </InputLeftElement>
                  <Input
                    background="#000"
                    border="none"
                    w="100%"
                    type="text"
                    color="#fff"
                    placeholder="Search Images"
                    outline="none"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setCurrentPage(1);
                        setFolderObjects([]);
                        searchQuery?.length
                          ? searchSelectedResource()
                          : renderFolderImage();
                      }
                    }}
                  />
                  {searchQuery?.length ? (
                    <InputRightElement>
                      <AiOutlineCloseCircle
                        cursor="pointer"
                        color="red"
                        onClick={() => {
                          setSearchQuery("");
                          setCurrentPage(1);
                          setFolderObjects([]);
                          renderFolderImage();
                        }}
                      />
                    </InputRightElement>
                  ) : null}
                </InputGroup>
              </Stack>
              <Button
                cursor="pointer"
                colorScheme="yellow"
                size="sm"
                onClick={() => {
                  setCurrentPage(1);
                  setFolderObjects([]);
                  searchQuery?.length
                    ? searchSelectedResource()
                    : renderFolderImage();
                }}
                isDisabled={objsLoading}
                isLoading={objsLoading}
              >
                Search
              </Button>
            </Flex>
          </Flex>
          {folderObjects.length > 0 && (
            <>
              <Flex alignItems="center" justifyContent="center">
                {folderName !== "output/" && (
                  <Flex
                    bg="white"
                    p="5px"
                    cursor="pointer"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="50%"
                    position="absolute"
                    bottom="10%"
                    left="55%"
                    zIndex={1}
                    h="50px"
                    w="50px"
                    color="#000"
                  >
                    <AiOutlinePlus fontSize={24} onClick={onOpen} />
                  </Flex>
                )}
              </Flex>
              <BulkLabelerPanel
                resourceId={props.resource_uuid}
                folderKey={folderName}
                totalRecords={totalRecords}
              />
              <Modal
                scrollBehavior="outside"
                style={{ width: "100% !important" }}
                isOpen={isOpen}
                onClose={onClose}
              >
                <ModalOverlay />
                <ModalContent bg={greyBgColor} maxW="50%" mt="5">
                  <ModalHeader color="#fff">Upload To Folder</ModalHeader>
                  <ModalCloseButton color="red.500" fontWeight="600" />
                  <Flex
                    w="100%"
                    borderWidth="0.5px"
                    borderColor={borderBottomColor}
                    rounded="md"
                    color="white"
                    alignItems="start"
                    p="2"
                  >
                    <BulkUpload
                      resourceUuid={props.resource_uuid}
                      folderKey={folderName}
                    />
                  </Flex>
                </ModalContent>
              </Modal>
            </>
          )}
        </Flex>
        <Box
          ref={iScrollRef}
          h="40vh"
          overflow="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#fff",
              borderRadius: "24px",
            },
          }}
        >
          {imageLoading && (
            <Flex alignItems="center" h="20vh" justifyContent="center" mt="4">
              <Spinner size="xl" />
            </Flex>
          )}
          <Grid
            templateColumns={
              folderObjects.length > 0 ? "repeat(5, 1fr)" : "repeat(0,1fr)"
            }
            gap={2}
            mt="4"
          >
            {renderAllFolderObjects()}
            {!folderObjects.length && !objsLoading && !imageLoading ? (
              <Text
                bg={greyBgColor}
                p="5"
                fontWeight="600"
                color="#fff"
                borderRadius="10px"
              >
                No Images Found
              </Text>
            ) : null}
          </Grid>
          {objsLoading && !imageLoading && (
            <Flex alignItems="center" justifyContent="center" mt="10">
              <Spinner size="xl" />
            </Flex>
          )}
        </Box>
        <ObjectMetadataPanel
          resource_uuid={props.resource_uuid}
          selectedObjectName={selectedObjectName}
          selectedObjectUrl={selectedObjectUrl}
          resetMetadataPanelState={resetMetadataFormData}
          showMetadataPanel={launchMetadataPanel}
          handleResourceObject={changeResourceObject}
          selectedImageIndex={selectedImage}
          dataCount={folderObjects.length}
        />
      </>
    </Box>
  );
}

export default ResourceFolderImage;
