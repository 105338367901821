// React Chakra Import
import { React, useState } from "react";
import { Excalidraw } from "@excalidraw/excalidraw";
import { Flex, Box, Text, Switch } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

// Component Import
import SharedFolder from "./SharedFolder";
import SharedDetails from "./SharedDetails";
import { greyBgColor, defaultThemeColor } from "../../constants";
import { getEmailId } from "../../utils/localStorageIndex";

// Api Imports
function UserSharedSpace({ spaceData, manageSwitch, manageUploadedContent }) {
  const shared_space_id = spaceData?.shared_space_uuid;
  const [configurationCollapseFlag, setConfiguationCollapseFlag] =
    useState(true);
  const email = getEmailId();

  return (
    <Flex
      bg="#151117"
      w="100%"
      fontSize="13px"
      height="100vh"
      overflow="hidden"
    >
      <Box
        w={"100%"}
        bg={greyBgColor}
        m="0.5%"
        borderRadius="10px"
        p="10px"
        h="98%"
        overflowY="scroll"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: greyBgColor,
            borderRadius: "24px",
          },
        }}
      >
        <Flex h="100%">
          <Box
            w={!configurationCollapseFlag ? "95%" : "70%"}
            bg={"#151117"}
            p="3"
            borderRadius={"10px"}
            overflow="hidden"
          >
            <SharedFolder
              type="user"
              shared_space_id={shared_space_id}
              sharedSpaceName={spaceData?.name}
            />
          </Box>
          {configurationCollapseFlag ? (
            <Box
              w="30%"
              position="relative"
              p="3"
              borderRadius="15px"
              bg="#151117"
              ml="2"
            >
              <Box
                position="absolute"
                top="0"
                right="0"
                mr="2"
                mt="3"
                zIndex="999"
              >
                <AiFillRightCircle
                  color="#fff"
                  cursor="pointer"
                  size="20px"
                  bg={greyBgColor}
                  onClick={() => {
                    setConfiguationCollapseFlag(!configurationCollapseFlag);
                  }}
                />
              </Box>
              <Box
                w="100%"
                h="95%"
                overflow={"hidden"}
                overflowY="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#151117",
                    borderRadius: "24px",
                  },
                }}
              >
                <SharedDetails
                  shared_space_key={shared_space_id}
                  email={email}
                />
              </Box>
            </Box>
          ) : (
            <Box
              w="5%"
              p="3"
              borderRadius="10px"
              bg="#151117"
              h="100%"
              ml="2"
              pl="5"
            >
              <Box w={"100%"} h="100%">
                <AiFillLeftCircle
                  cursor="pointer"
                  color="#fff"
                  onClick={() => {
                    setConfiguationCollapseFlag(!configurationCollapseFlag);
                  }}
                  size="20px"
                />
                <Text
                  fontSize="16px"
                  color="#fff"
                  mt="2"
                  css={{
                    writingMode: "vertical-lr",
                    textOrientation: "mixed",
                  }}
                >
                  Expand Details
                </Text>
              </Box>
            </Box>
          )}
        </Flex>
      </Box>
    </Flex>
  );
}

export default UserSharedSpace;
