import React, { useState, useEffect } from 'react';

import { getEmailId } from '../../../utils/localStorageIndex';

import {
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';

import {
  themeFontSizeMediumHeading,
  defaultThemeColor,
  greyBgColor,
  defaultGreyColor
} from '../../../constants';

import {
    createBulkObjectUpload,
    listResourceBulkObjectUploads,
} from '../../../services/resourceTemplateService';

import { toastFunctionToaster } from '../../../utils/toastFunction';

function BulkTransfer(props) {
  const email = getEmailId();
  const toast = useToast();

  const [bulkObjectUploads, setBulkObjectUploads] = useState([]);
  const [sourceUrl, setSourceUrl] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sourceUrlInputOnChange = (e) => {
    setSourceUrl(e.target.value);
  };

  const startTransferButtonOnClick = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    createBulkObjectUpload({email: email, source_url: sourceUrl, resource_uuid: props.resourceUuid})
    .then(res => {
      if (res.status === 'failure') {
        toast(toastFunctionToaster(res.message, 'error'));
      }
      else if (res.status === 'success') {
        toast(toastFunctionToaster('Bulk transfer started successfully', 'success'));
        callListResourceBulkObjectUploads();
      }
    })
    .catch(err => {
      toast(toastFunctionToaster('Error occured while starting bulk transfer', 'error'));
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  };

  const callListResourceBulkObjectUploads = () => {
    const params = {
      email: email,
      resource_uuid: props.resourceUuid,
    };
    listResourceBulkObjectUploads(params).then(res => {
      toast(toastFunctionToaster('Bulk transfers retrieved successfully', 'success'));
      setBulkObjectUploads(res.data);
    })
    .catch(err => {
      toast(toastFunctionToaster('Error occured while retrieving active bulk transfers', 'error'));
      setBulkObjectUploads([]);
    });
  }

  useEffect(() => {
    callListResourceBulkObjectUploads();
  }, []);

  const inProgressBulkObjectUploads = bulkObjectUploads.filter(({status}) => status == 0 || status == 1);
  const pastBulkObjectUploads = bulkObjectUploads.filter(({status}) => status == 2 || status == 3);
  const anyInProgressBulkObjectUploads = inProgressBulkObjectUploads.length > 0;

  useEffect(() => {
    if (!anyInProgressBulkObjectUploads) return;
    const bulkObjectUploadsPollInterval = setInterval(() => {
      callListResourceBulkObjectUploads();
    }, 5000);

    return () => clearInterval(bulkObjectUploadsPollInterval);
  }, [anyInProgressBulkObjectUploads]);

  const bulkObjectUploadCard = (bulk_object_upload) => {
    const borderColor = {
      0: 'yellow',
      1: 'yellow',
      2: 'green',
      3: 'red'
    }[bulk_object_upload.status] || 'grey';

    return (
      <Box
        bg={greyBgColor}
        m="1"
        p="10px"
        borderLeftWidth="5px"
        borderColor={borderColor}
      >
        <Text mb="5px" fontSize={16}>
          {'Started on '}
          {(new Date(bulk_object_upload.datetime_created)).toDateString()}
          {' • '}
          {bulk_object_upload.objects_count &&
            (
          <Text as="span" fontSize={'xs'}>
            {bulk_object_upload.uploaded_objects_count} / {bulk_object_upload.objects_count} objects uploaded
          </Text>
          )}
          {bulk_object_upload.failure_reason && (
            <Text as="span" fontSize={'xs'}>
              {` Failed with error: ${bulk_object_upload.failure_reason}`}
            </Text>
          )}
        </Text>
      </Box>
    );
  };

  return (
    <Box w={'100%'} p={2}>
      <Box w={'100%'}>
        <Text fontSize={'xs'} align={"center"}>Please enter the sharing link of the Google Drive folder you want to transfer from.</Text>
        <Text fontSize={'xs'} align={"center"}>The sharing link must be accessible to the public or support@corbu.ai.</Text>
      </Box>
      <HStack w="100%">
        <FormControl isRequired>
          <Input
            h={'40px'}
            rows={1}
            resize={'none'}
            placeholder="https://drive.google.com/drive/folders/GDriveExampleFolderLink"
            size="sm"
            borderRadius="0px"
            fontSize={themeFontSizeMediumHeading}
            borderColor={defaultGreyColor}
            bg={greyBgColor}
            value={sourceUrl}
            isDisabled={isSubmitting}
            onChange={sourceUrlInputOnChange}
            name="sourceUrl"
          />
        </FormControl>
        <Button
          color={defaultThemeColor}
          variant="outline"
          borderRadius="5px"
          borderColor={defaultThemeColor}
          fontSize="12px"
          size="sm"
          m={2}
          isDisabled={sourceUrl.length === 0 || isSubmitting}
          isLoading={isSubmitting}
          onClick={startTransferButtonOnClick}
        >
          Start Transfer
        </Button>
      </HStack>
      <Box w={'100%'} pt={'20px'}>
        {inProgressBulkObjectUploads.length > 0 && (
          <Text fontSize={'md'}>In Progress Transfers</Text>
        )}
        { inProgressBulkObjectUploads.map(x => bulkObjectUploadCard(x)) }
        {pastBulkObjectUploads.length > 0 && (
          <Text fontSize={'md'}>Past Transfers</Text>
        )}
        { pastBulkObjectUploads.map(x => bulkObjectUploadCard(x)) }
      </Box>
    </Box>
  );
}

export default BulkTransfer;
