import React from 'react';
import { SimpleGrid, Box, Flex, Icon, Text } from '@chakra-ui/react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { defaultThemeColor } from '../../../constants';

function Content() {
  const headerData = [
    {
      label: 'Load data',
      subData: 'Load Data from different sources',
      icon: AiOutlineUserAdd,
    },
    {
      label: 'Create Notebook',
      subData: 'Create a notebook with Jupyter Notebooks',
      icon: AiOutlineUserAdd,
    },
    {
      label: 'Collaborate',
      subData: 'Add a team member',
      icon: AiOutlineUserAdd,
    },
  ];
  const mainData = [
    {
      label: 'Guides',
      subData: 'Explore our guides',
      subContent: [
        { label: 'About SingleStore DB Cloud', path: '' },
        { label: 'Size your workspace', path: '' },
        { label: 'Connect to your workspace', path: '' },
        { label: 'Load your data', path: '' },
      ],
    },
    {
      label: 'Release & Training',
      subData: 'View our latest releases',
      subContent: [
        { label: 'Comprehensive Learning Plan Training', path: '' },
        { label: 'Cloud Release Notes', path: '' },
        { label: 'Our open source content', path: '' },
        { label: 'Become a singleStore Pro', path: '' },
      ],
    },
    {
      label: 'Blog & Community',
      subData: 'Learn ',
      subContent: [
        { label: 'Build a full stack app', path: '' },
        { label: 'Load 100B Rows of Data', path: '' },
        { label: 'What is HTAP?', path: '' },
        { label: 'Solve MySQL out-of-memories issue', path: '' },
      ],
    },
  ];
  const renderHeaderData = headerData?.map((data, index) => (
    <Flex
      key={index}
      alignItems="center"
      borderWidth="0.5px"
      borderColor="#494552"
      borderRadius="10px"
      py="15px"
      px="10px"
    >
      <Icon w={5} h={5} as={data.icon} mr="2" color={defaultThemeColor}></Icon>
      <Box>
        <Text color="white" fontSize="16px">
          {data.label}
        </Text>
        <Text color="#d7d7d7" fontSize="10px">
          {data.subData}
        </Text>
      </Box>
    </Flex>
  ));

  const renderContentData = mainData?.map((data, idx) => (
    <Box key={idx} p="10px">
      <Text color="white" fontSize="14px">
        {data.label}
      </Text>
      <Text color="#d7d7d7" fontSize="10px" mb="15px">
        {data.subData}
      </Text>
      {data.subContent.map((innerData, index) => (
        <Text
          key={idx + index}
          color={defaultThemeColor}
          fontSize="12px"
          mb="5px"
        >
          {innerData.label}
        </Text>
      ))}
    </Box>
  ));
  return (
    <>
      <SimpleGrid columns={3} spacing={2} p="15px">
        {renderHeaderData}
      </SimpleGrid>
      <SimpleGrid columns={3} spacing={2} p="15px">
        {renderContentData}
      </SimpleGrid>
    </>
  );
}

export default Content;
