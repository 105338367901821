// React Chakra Imports
import React from "react";
import {
  Tab,
  Tabs,
  Heading,
  TabList,
  TabPanels,
  TabPanel,
  Box,
} from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor } from "../../constants";
import UserInfo from "./UserInfo/UserInfo";

function UserProfile() {
  return (
    <Box h="100%">
      <Heading mb="5" color="#fff" fontWeight="600" fontSize="24px">
        User Details
      </Heading>
      <Tabs w="100%" borderBottom={"0px"} mx="auto">
        <TabList border="0px" w="100%" h="5%">
          <Tab
            _selected={{
              color: defaultThemeColor,
              fontWeight: 600,
              borderBottomColor: defaultThemeColor,
            }}
            fontSize="16px"
            px="6"
            py="2"
            color="#fff"
          >
            Personal Information
          </Tab>
        </TabList>
        <TabPanels mt="3" h="100%">
          <TabPanel p="0">
            <UserInfo />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default UserProfile;
