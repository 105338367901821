import React from "react";
import { Box } from "@chakra-ui/react";
import WidgetDashboard from "./WidgetDashboard";
import ChartDashboard from "./ChartDashboard";

function BusinessDashboard() {
  return (
    <Box>
      <WidgetDashboard />
      <ChartDashboard />
    </Box>
  );
}

export default BusinessDashboard;
