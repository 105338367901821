import { useToast } from "@chakra-ui/react";

export const toastFunctionToaster = (message, status) => {
  return {
    title: message,
    status: status,
    isClosable: true,
    duration: 3000,
  };
};
