// React Imports
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineExpand, AiOutlineCopy } from "react-icons/ai";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor, greyBgColor } from "../../../constants";
import { maskRegionColors } from "../../../constants/Workflow";
import ImageXConfig from "../ProjectManagement/DesignProjects/ProjectConfiguration/CurrentImageConf/ImageXConfig";
import PopupImage from "../ProjectManagement/DesignProjects/ProjectConfiguration/CurrentImageConf/PopupImage";
import ImageWithMasks from "../ProjectManagement/ImageWithMasks";
import {
  convertBasicRLEToCocolibRLE,
  convertBasicRLEToRawData,
  convertCocolibRLEToBasicRLE,
  convertRawDataToBasicRLE,
  cloneAndScaleContext,
} from "../ProjectManagement/ImageWithMasksUtils";
import { setClipBoardValue } from "../../../store/actions/workFlowAction";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import CopyImageToClipboard from "../../Custom/CopyImageToClipboard";

function PopupImageModal({ imageUrl, objectName, showIcons }) {
  const dispatch = useDispatch();
  const toast = useToast();
  const ctxRefs = useRef([]);
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const [masks, setMasks] = useState([]);
  const [imageDimensions, setImageDimensions] = useState(null);
  const [dataArray, setDataArray] = useState(
    Object.fromEntries(maskRegionColors.map((_, index) => [index, []]))
  );

  const [redoData, setRedoData] = useState(
    Object.fromEntries(maskRegionColors.map((_, index) => [index, []]))
  );

  const { copyImageToClipboard } = CopyImageToClipboard(); //Custom Clipboard Function

  const copyToClipboard = () => {
    if (objectName?.length) {
      dispatch(setClipBoardValue(objectName));
      copyImageToData(objectName);
    }
  };

  // Todo:Custom Copy To Clipboard Hook
  const copyImageToData = async (imageName) => {
    try {
      await copyImageToClipboard(imageName);
      toast(toastFunctionToaster("Image copied to clipboard", "success"));
    } catch (error) {
      toast(toastFunctionToaster("Failed to copy image to clipboard", "error"));
    }
  };

  useEffect(() => {
    loadMaskFromRLEDatas(
      workflowDetails?.workflowObject?.maskData?.length
        ? workflowDetails?.workflowObject?.maskData
        : []
    );
  }, [workflowDetails?.workflowObject?.maskData]);

  // Todo:Set Mask Data
  const getMaskRawDatas = () => {
    return ctxRefs.current.map((context) => {
      const scaledImageData = context?.getImageData(
        0,
        0,
        Math.round(context.canvas.width),
        Math.round(context.canvas.height)
      );
      if (!scaledImageData.data.some((x) => x >= 128)) {
        return null;
      }
      return {
        size: [
          Math.round(context.canvas.height),
          Math.round(context.canvas.width),
        ],
        counts: convertBasicRLEToCocolibRLE(
          convertRawDataToBasicRLE(scaledImageData)
        ),
      };
    });
  };

  const loadMaskFromRLEDatas = (encodedDatas) => {
    if (!encodedDatas || !imageDimensions) {
      return;
    }

    const rawImageCanvases = encodedDatas.map((data, index) => {
      if (!data) {
        return null;
      }
      return convertBasicRLEToRawData(
        convertCocolibRLEToBasicRLE(data.counts),
        Math.round(data.size[0]),
        Math.round(data.size[1]),
        maskRegionColors[index]
      );
    });
    ctxRefs.current.map((context, index) => {
      if (!rawImageCanvases[index]) {
        return context;
      } else {
        context.putImageData(
          cloneAndScaleContext(
            rawImageCanvases[index],
            Math.round(context.canvas.width) / rawImageCanvases[index].width
          )?.getImageData(
            0,
            0,
            Math.round(context.canvas.width),
            Math.round(context.canvas.height)
          ),
          0,
          0
        );
      }
    });
  };

  useEffect(() => {
    // Create the initial masks from maskRegionColors
    // Loop through each color and create a new mask
    const newMasks = maskRegionColors.map((color, index) => ({
      name: `mask${maskRegionColors.length + index + 1}`,
      color: color,
    }));
    setMasks(newMasks);
  }, []);

  // Todo:Set Make Data To Redux When Mouse Up
  const handleMouseUpEvent = (index) => {
    if (!imageDimensions) {
      return;
    }

    const maskData = getMaskRawDatas();
    storeMaskDataTemp(index, maskData);
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "maskData", value: maskData },
    });
  };

  const storeMaskDataTemp = (index, maskData) => {
    if (index !== undefined && maskData[index]?.counts?.length) {
      let objArr = { ...dataArray };
      objArr[index].push(maskData[index]);
      setDataArray(objArr);

      let objArrClone = { ...redoData };
      objArrClone[index].push(maskData[index]);
      setRedoData(objArrClone);
    }
  };

  const undoMaskDataTemp = (index, value) => {
    if (!imageDimensions) return;

    const popArray = { ...dataArray };
    const popArrayClone = { ...redoData };

    if (value === "undo") {
      if (Array.isArray(popArray[index]) && popArray[index].length > 0) {
        popArray[index].pop();
      } else {
        popArray[index] = [];
      }
    } else if (value === "redo") {
      if (
        Array.isArray(popArrayClone[index]) &&
        popArrayClone[index].length > 0
      ) {
        const redoElement = popArrayClone[index].pop();
        if (!popArray[index]) popArray[index] = [];
        popArray[index].push(redoElement);
      } else {
        popArrayClone[index] = [];
      }
    }

    const transformedArray = Object.keys(popArray).map((key) => {
      const array = popArray[key];
      if (Array.isArray(array) && array.length > 0) {
        return array[array.length - 1];
      } else if (ctxRefs.current[key]) {
        const canvasWidth = ctxRefs.current[key].canvas.width;
        const canvasHeight = ctxRefs.current[key].canvas.height;
        ctxRefs.current[key].clearRect(0, 0, canvasWidth, canvasHeight);
        return getMaskRawDatas()[key];
      }
      return null;
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "maskData", value: transformedArray },
    });

    if (value === "undo") setDataArray(popArray);
    if (value === "redo") setRedoData(popArrayClone);
  };

  return imageUrl?.length ? (
    <Box position="relative" h="100%" w="100%">
      <ImageWithMasks
        imgUrl={imageUrl}
        masks={masks}
        setMasks={setMasks}
        setImageDimensions={setImageDimensions}
        ctxRefs={ctxRefs}
        onDataFromChild={handleMouseUpEvent}
        handleUndo={undoMaskDataTemp}
      />
      {showIcons ? (
        <Flex position="absolute" top="0" right="0" bg={greyBgColor} p="2">
          <Menu as={Button}>
            <MenuButton
              fontSize="25px"
              cursor="pointer"
              color={defaultThemeColor}
            >
              <AiOutlineExpand />
            </MenuButton>
            <MenuList
              bg={greyBgColor}
              border="none"
              minW="100px !important"
              position="relative"
            >
              <ImageXConfig
                imgUrl={{ object_name: objectName }}
                height={imageDimensions?.height}
                width={imageDimensions?.width}
                marginTop="0"
              />
            </MenuList>
          </Menu>
          <AiOutlineCopy
            size="25px"
            cursor="pointer"
            color={defaultThemeColor}
            onClick={copyToClipboard}
          />
          <PopupImage position={"relative"} imageUrl={imageUrl} />
        </Flex>
      ) : null}
    </Box>
  ) : null;
}

export default PopupImageModal;
