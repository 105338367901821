// Chakra Imports

import axios from "../../../services/axios";
import { useDropzone } from "react-dropzone";
import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  Button,
  Box,
  Flex,
  Image,
  Text,
  useToast,
  Progress,
} from "@chakra-ui/react";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";

// Api Imports
import {
  resourceLabellerRequest,
  bulkImageUpload,
} from "../../../services/resourceTemplateService";
import { defaultThemeColor } from "../../../constants";

function BulkUpload(props) {
  const toast = useToast();
  const [files, setFiles] = useState([]);
  const resourceId = props?.resourceUuid;
  const [loading, isLoading] = useState(false);
  const [imagesArr, setImagesArr] = useState([]);
  const [completedCountFlag, setCompletedCountFlag] = useState(0);

  // On file drop update use effect
  const onDrop = (acceptedFiles) => {
    setCompletedCountFlag(0);
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith("image/")
    );
    setFiles(imageFiles);
  };

  const uploadFiles = async () => {
    let fileListArr = [];
    files.forEach((file) => {
      let obj = {
        file_name:
          props?.folderKey && props?.folderKey !== "/"
            ? `${props?.folderKey}${file?.name}`
            : file?.name,
        file_type: file?.type,
      };
      fileListArr = [...fileListArr, obj];
    });
    uploadFileToSource(fileListArr);
  };

  const uploadFileToSource = (file) => {
    if (file) {
      let obj = {
        resource_uuid: resourceId,
        files_list: file,
        resource_choice: "string",
      };
      isLoading(true);
      bulkImageUpload(obj)
        .then((res) => {
          const checkArr = res?.data[0]?.signedPackage?.signedUrl;
          const updatedImagesArr = []; // Create an array to store updated images
          checkArr?.forEach((data, index) => {
            postObjectData(data?.s3Url, files[index]);
            updatedImagesArr.push(files[index]);
          });
          setImagesArr((prevArr) => [...updatedImagesArr, ...prevArr]); // Update imagesArr with all updated images
          setFiles([]); // Reset files array
          resourceLabellerRequest({
            resource_uuid: resourceId,
            labeler_json: JSON.stringify({
              model_sub_name: "index",
              exclude_dirs: ["output/"],
            }),
          })
            .then((res) => {
              if (res?.data?.length > 0 && res?.result) {
                toast(
                  toastFunctionToaster(
                    "Image search index is updating...",
                    "success"
                  )
                );
              } else {
                toast(
                  toastFunctionToaster(
                    "Error occured while updating image search index...",
                    "error"
                  )
                );
              }
            })
            .catch((err) => {
              toast(toastFunctionToaster(err?.message, "error"));
            });
        })
        .catch((err) => {
          toast(toastFunctionToaster(err?.message, "error"));
        })
        .finally(() => {
          isLoading(false);
        });
    }
  };

  function postObjectData(s3Data, selectedFile) {
    const { url, fields } = s3Data;
    const payload = new FormData();

    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    payload.append("file", selectedFile);

    const { data: result } = axios
      .post(url, payload)
      .then((res) => {
        if (res?.data?.length > 0) {
          toast(
            toastFunctionToaster("File is uploaded successfully", "success")
          );
        } else {
          toast(
            toastFunctionToaster("File is uploaded successfully", "success")
          );
          setCompletedCountFlag((prevState) => prevState + 1);
        }
      })
      .catch((err) => {
        toast(
          toastFunctionToaster("Something Went Wrong while uploading file", "error")
        );
      });
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const renderUploadedImages = () => {
    return files?.map((file, index) => (
      <Box position={"relative"} w="48%" h="150px" mr="1%" mb="1%" key={index}>
        <Image
          key={index}
          src={URL.createObjectURL(file)}
          alt={`Image ${index}`}
          w="100%"
          h="100%"
          objectFit={"cover"}
          cursor={"pointer"}
          borderRadius="10px"
        />

        <Box position={"absolute"} top="1" right="1">
          <AiOutlineCloseCircle
            fontSize="16px"
            cursor="pointer"
            color="red"
            onClick={() => {
              removeImage(index);
            }}
          />
        </Box>
      </Box>
    ));
  };

  const renderUploadCompletedImages = () => {
    return imagesArr?.map((file, index) => (
      <Box position={"relative"} w="48%" h="150px" mr="1%" mb="1%" key={index}>
        <Image
          key={index}
          src={URL.createObjectURL(file)}
          alt={`Image ${index}`}
          w="100%"
          h="100%"
          objectFit={"cover"}
          cursor={"pointer"}
          borderRadius="10px"
        />
      </Box>
    ));
  };

  const renderImagePath = () => {
    return files?.map((file, index) => (
      <Flex key={index} alignItems={"center"}>
        <Text mr="2">{file?.path}</Text>
        <AiOutlineCloseCircle
          fontSize="16px"
          cursor="pointer"
          color="red"
          onClick={() => {
            removeImage(index);
          }}
        />
      </Flex>
    ));
  };

  const removeImage = (index) => {
    const updatedImagesArr = [...files];
    updatedImagesArr.splice(index, 1);
    setFiles(updatedImagesArr);
  };

  return (
    <Box w="100%">
      {" "}
      <Flex w="100%" justifyContent={"space-between"}>
        <Box w={files?.length ? "48%" : "100%"}>
          {files?.length ? (
            <Box
              borderWidth={"2px"}
              borderColor={defaultThemeColor}
              borderStyle="dashed"
              cursor={"pointer"}
              h="500px"
              w="100%"
              p="2"
              alignItems={"center"}
              justifyContent={"center"}
              borderTopEndRadius={"10px"}
              borderTopLeftRadius={"10px"}
            >
              {renderImagePath()}
            </Box>
          ) : (
            <Flex
              {...getRootProps()}
              borderWidth={"2px"}
              borderColor={defaultThemeColor}
              borderStyle="dashed"
              cursor={"pointer"}
              h="490px"
              w="100%"
              p="2"
              alignItems={"center"}
              justifyContent={"center"}
              borderTopEndRadius={"10px"}
              borderTopLeftRadius={"10px"}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Text>Drop the files here ...</Text>
              ) : (
                <Text>
                  Drag 'n' drop some files here, or click to select files
                </Text>
              )}
            </Flex>
          )}

          <Box
            w="100%"
            textAlign={"right"}
            p="2"
            borderWidth={"2px"}
            borderColor={defaultThemeColor}
            borderStyle="dashed"
            borderTop={"0"}
            borderBottomEndRadius={"10px"}
            borderBottomLeftRadius={"10px"}
          >
            <Button
              colorScheme="green"
              size="xs"
              onClick={uploadFiles}
              isDisabled={!files?.length ? true : false}
              isLoading={loading ? true : false}
            >
              Upload
            </Button>
          </Box>
        </Box>
        {files?.length ? (
          <Box w="50%">
            <Box
              h="calc(100vh - 25vh)"
              overflow="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#fff",
                  borderRadius: "24px",
                },
              }}
            >
              <Flex flexWrap="wrap">
                {renderUploadedImages()}
                {renderUploadCompletedImages()}
              </Flex>
            </Box>
            {completedCountFlag === 0 ? null : (
              <Box w="100%" my="2">
                <Progress
                  colorScheme="green"
                  height="10px"
                  borderRadius={"20px"}
                  max={files?.length}
                  value={completedCountFlag}
                />
                <Text color="#fff" fontSize="sm">
                  {completedCountFlag} Image Upload Successful
                </Text>
              </Box>
            )}
          </Box>
        ) : null}
      </Flex>
    </Box>
  );
}

export default BulkUpload;
