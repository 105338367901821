// https://www.computerhope.com/htmcolor.htm
export const themeColor = "#F5E216"; //'#3780c2';
export const errorColor = "#e6474a";
export const borderBottomColor = "#494552";
export const defaultThemeColor = "#c6d704";
export const greyBgColor = "#252628";
export const defaultGreyColor = "#878787";

export const themeFontSizeXlHeading = "18px";
export const themeFontSizeLargeHeading = "16px";
export const themeFontSizeMediumHeading = "14px";
export const themeFontSizeSmallHeading = "12px";
export const themeFontSizeOddHeading = "11px";
export const themeFontSizeXSHeading = "10px";

export const themeColorWindTan = "#A25B00"; //Windsor Tan;

export const defaultHighlightedMenuOption = "#E8A317";

export const corbuLogo =
  "https://cdn.worldvectorlogo.com/logos/batman-30538.svg";

export const roleAccess = [
  { roleUser: "Admin", groupId: "100" },
  { roleUser: "Business", groupId: "200" },
  { roleUser: "HR", groupId: "300" },
];

export const userRole = [
  {
    roleName: "Home",
    group: ["100", "200", "300"],
  },
  {
    roleName: "Business",
    group: ["200", "300"],
  },
  {
    roleName: "Admin",
    group: ["100"],
  },
];
