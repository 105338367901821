// Chakra Imports
import React, { useState } from "react";
import { AiFillFolderAdd, AiOutlineEdit } from "react-icons/ai";
import {
  Button,
  FormLabel,
  Text,
  Input,
  Icon,
  Modal,
  useToast,
  ModalBody,
  FormControl,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton,
} from "@chakra-ui/react";

// Component Imports
import { themeFontSizeMediumHeading } from "../../../../constants";
import { toastFunctionToaster } from "../../../../utils/toastFunction";

// Api Imports
import {
  CreateProjectFolder,
  UpdateProjectFolder,
} from "../../../../services/projectTemplateService";

function AddProjectFolder({
  projectId,
  parentFolderId,
  editFolderId,
  resetUpdatedFolderDetails,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const folderSaveTitle = editFolderId ? "Edit" : "Add";

  //   Todo:Submit Folder details data
  const createFolderTemplateData = (event) => {
    event.preventDefault();
    if (folderName?.length) {
      setErrorFlag(false);
      isLoading(true);
      let obj = {
        project_uuid: projectId,
        project_folder_uuid: parentFolderId,
        folder_name: folderName,
      };
      if (parentFolderId?.length) {
        obj.parent_folder_uuid = parentFolderId;
      }
      CreateProjectFolder(obj)
        .then((res) => {
          if (res.result) {
            onCloseModal();
            resetUpdatedFolderDetails();
            toast(toastFunctionToaster(res.message, "success"));
          } else {
            toast(toastFunctionToaster(res.message, "error"));
          }
        })
        .catch((err) => {
          toast(toastFunctionToaster(err?.message, "error"));
        })
        .finally(() => {
          isLoading(false);
        });
    } else {
      setErrorFlag(true);
      isLoading(false);
    }
  };

  //   Todo:Update Folder Details
  const updateFolderTemplateForm = (event) => {
    event.preventDefault();
    if (folderName?.length) {
      setErrorFlag(false);
      isLoading(true);
      let obj = {
        project_uuid: projectId,
        project_folder_uuid: editFolderId,
        folder_name: folderName,
      };
      UpdateProjectFolder(obj)
        .then((res) => {
          if (res.status) {
            onCloseModal();
            resetUpdatedFolderDetails();
            toast(toastFunctionToaster(res.message, "success"));
          } else {
            toast(toastFunctionToaster(res.message, "error"));
          }
        })
        .catch((err) => {
          toast(toastFunctionToaster(err?.message, "error"));
        })
        .finally(() => {
          isLoading(false);
        });
    } else {
      setErrorFlag(true);
      isLoading(false);
    }
  };

  // Todo:On Model Close Reset Data
  const onCloseModal = () => {
    onClose();
    setErrorFlag(false);
    setFolderName("");
  };

  return (
    <>
      <Button
        ml={editFolderId ? "0" : "2"}
        size={editFolderId ? "xs" : "sm"}
        colorScheme={editFolderId ? "yellow" : "green"}
      >
        <Icon
          w={5}
          h={5}
          as={editFolderId ? AiOutlineEdit : AiFillFolderAdd}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        />
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onCloseModal();
        }}
      >
        <ModalOverlay />
        <ModalContent bg={"#131415"}>
          <ModalHeader color="#fff">Folder Name</ModalHeader>
          <ModalCloseButton color="red" />
          <ModalBody>
            <FormControl isRequired mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading} color="#fff">
                Folder Name
              </FormLabel>
              <Input
                placeholder="Enter Folder Name..."
                height={"50px"}
                border="none"
                fontSize="14px"
                borderColor="#494552"
                color="#fff"
                bg="#1c181e"
                size="sm"
                value={folderName}
                onChange={(e) => {
                  setFolderName(e.target.value);
                }}
                name="project_name"
              />
              {errorFlag && folderName?.length === 0 ? (
                <Text fontSize="sm" color={"red"} mt="1">
                  Folder Name is required
                </Text>
              ) : null}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              size="sm"
              colorScheme="green"
              isDisabled={loading}
              isLoading={loading}
              onClick={(e) => {
                editFolderId
                  ? updateFolderTemplateForm(e)
                  : createFolderTemplateData(e);
              }}
            >
              Save
            </Button>
            <Button colorScheme="red" onClick={() => onCloseModal()} size="sm">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddProjectFolder;
