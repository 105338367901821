import React from 'react';
import { Box } from '@chakra-ui/react';
import NestedRoutes from '../../SubRoute/NestedRoutes';

function CorbuTeam() {
  const notificationContent = [
    { number: 5, label: 'Corbu Users' },
    { number: 13, label: 'Businesses' },
    { number: 45, label: 'Job Workflows' },
  ];

  const businessHeaderArr = [
    { label: 'Team List (all)', value: 'AllTeamMembers' },
    { label: 'Team Status', value: 'AllTeamMembersStatus' },
    { label: 'Add New Team Member', value: 'AddNewTeamMember' },
  ];

  return (
    <Box>
      <NestedRoutes
        notificationContentData={notificationContent}
        headerArrData={businessHeaderArr}
        breadCrumTitle="Corbu Team Management"
        split={3}
      ></NestedRoutes>
    </Box>
  );
}

export default CorbuTeam;
