import React from 'react';
import {
  Box,
  Flex,
  Progress,
  Icon,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Img,
  Button,
  IconButton,
  SimpleGrid,
  HStack,
  VStack
} from '@chakra-ui/react';
import { BsGrid1X2Fill } from 'react-icons/bs';
import { AiFillSetting, AiOutlineCodeSandbox, AiOutlineUserAdd } from 'react-icons/ai';
import CloudSelection from '../../../image/workinprogress.jpg';
import { defaultThemeColor } from '../../../constants/index';

function AdminDashboards() {
    const headerData = [
        {
          label: 'Total Adminstrators',
          subData: 'All registered Adminstrators',
          icon: AiOutlineUserAdd,
        },
        {
          label: 'Registered Businesses',
          subData: 'All registered Businesses',
          icon: AiOutlineUserAdd,
        },
        {
          label: 'Registered Business Users',
          subData: 'All registered Business',
          icon: AiOutlineUserAdd,
        },
      ];

    const renderHeaderData = headerData?.map((data, index) => (
        <Flex
          key={index}
          alignItems="center"
          borderWidth="0.5px"
          borderColor="#494552"
          borderRadius="10px"
          py="15px"
          px="10px"
        >
          <HStack>
            <VStack>
                <HStack>
                    <Icon w={5} h={5} as={data.icon} mr="2" color={defaultThemeColor}></Icon>
                    <Box>
                        <Text color="white" fontSize="16px">
                        {data.label}
                        </Text>
                        <Text color="#d7d7d7" fontSize="10px">
                        {data.subData}
                        </Text>
                    </Box>
                </HStack>
                <HStack w={'100%'} borderTop={'1px'} borderColor="#494552" p={5}>
                    <Box >
                        <Text color="#d7d7d7" fontSize="14px">
                        {'Values comes here'}
                        </Text>
                    </Box>
                </HStack>
            </VStack>
          </HStack>
        </Flex>
      ));

  return (
    <Box>
      {/* <Flex p="10px" color="white" bgGradient="linear(to-r, #4a1d9b, #c552ff)">
        <Icon w={5} h={5} as={AiFillSetting} mr="10px"></Icon>
        <Text>13% / Deploying Nodes</Text>
      </Flex>
      <Progress colorScheme="purple" size="xs" value={13} /> */}
      <Flex
        p="10px"
        color="white"
        borderBottomWidth="0.5px"
        borderColor="#494552"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <Icon
            w={8}
            h={8}
            color={'#ee7733'}
            as={AiOutlineCodeSandbox}
            mr="10px"
          ></Icon>
          <Text fontSize={18}>Corbu AI Admin Dashboard</Text>
          {/* <Text
            ml="10px"
            fontSize={'10px'}
            p="3px"
            bg="#241a52"
            borderRadius={'5px'}
            color="#947ff5"
          >
            Master Admin
          </Text>
          <Text
            ml="10px"
            fontSize={'10px'}
            p="3px"
            bg="#183828"
            color="#36ad73"
            borderRadius={'5px'}
          >
            Administrator
          </Text> */}
        </Flex>
        <Flex alignItems="center">
          {/* <Text mr="10px" color="#d7d7d7">
            Last Updated: 12:29:47PM
          </Text> */}
          <Button
            color="#d7d7d7"
            variant="outline"
            borderRadius="0px"
            borderColor="#6b6b6b"
            fontSize="10px"
            size="xs"
            p="10px"
            mr="10px"
          >
            Contact US
          </Button>
          <IconButton
            color="#d7d7d7"
            variant="outline"
            borderRadius="0px"
            borderColor="#6b6b6b"
            fontSize="10px"
            size="xs"
            p="10px"
            icon={<BsGrid1X2Fill />}
          />
        </Flex>
      </Flex>
        <Flex
            m={4}
              p="10"
              borderWidth="0.5px"
              borderColor="#494552"
              borderRadius="10px"
              color="white"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Text mb="15px" fontSize="16px">
                  Corbu AI Administration
                </Text>
                <Text fontSize="12px">
                  Workspace Groups contain databases and workspaces.They are
                  created <br />
                  in a single region within a cloul provider.We recommend
                  isolating 'Dev',
                  <br /> 'Stagging' and 'Production' environments in seperate
                  workspace groups
                </Text>
              </Box>
              <Img src={CloudSelection} objectFit="contain" />
            </Flex>
      <SimpleGrid columns={3} spacing={2} p="15px">
        {renderHeaderData}
      </SimpleGrid>
    </Box>
  );
}

export default AdminDashboards;
