import React, { useState } from 'react';
import { CgAsterisk } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { createTeamMember } from '../../../../services/sharedServices';
import { defaultThemeColor, errorColor } from '../../../../constants/index';
import {
  Box,
  Grid,
  Text,
  Flex,
  Input,
  Button,
  GridItem,
  useToast,
} from '@chakra-ui/react';

function AddTeamMember() {
  const toast = useToast();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [teamMemberForm, setTeamMemberForm] = useState({
    email: getEmailId(),
    first_name: '',
    last_name: '',
    teammember_email: '',
  });
  const [retype_teammemberemail, setretype_teammemberemail] = useState('');
  const [retype_teammemberemailErr, setRetype_teammemberemailErr] = useState('');

  const onUpdateField = e => {
    const nextFormState = {
      ...teamMemberForm,
      [e.target.name]: e.target.value,
    };
    setTeamMemberForm(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = teamMemberForm;
    let errors = {};
    let formIsValid = true;
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields['first_name']) {
      formIsValid = false;
      errors['first_name'] = '*First Name is required.';
    }
    if (!fields['last_name']) {
      formIsValid = false;
      errors['last_name'] = '*Last Name is required.';
    }
    if (!fields['teammember_email']) {
      formIsValid = false;
      errors['teammember_email'] = '*Team Member Email is required.';
    } else if (!emailPattern.test(fields['teammember_email'])) {
      formIsValid = false;
      errors['teammember_email'] = '*Please enter valid email address.';
    }
    if (!retype_teammemberemail) {
      formIsValid = false;
      setRetype_teammemberemailErr('*Email Cannot Be Empty.');
    } else if (fields['teammember_email'] !== retype_teammemberemail) {
      formIsValid = false;
      setRetype_teammemberemailErr("*Email didn't match.");
    }
    setError(errors);
    return formIsValid;
  };

  const submitTeamMemberData = event => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      createTeamMember(teamMemberForm)
        .then(res => {
          isLoading(false);
          if (res.result) {
            toast({
              title: res.message,
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            navigate('/admin/corbuteam/AllTeamMembers');
          } else {
            isLoading(false);
            toast({
              title: res.message,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
          }
          setRetype_teammemberemailErr('');
        })
        .catch(err => {
          isLoading(false);
          if (err)
            toast({
              title: err?.response?.data?.detail,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
        });
    }
  };

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={5} p="3">
      <GridItem colSpan={1}>
        <Text fontSize="12px" mb="10px" color="#d7d7d7">
          ADD NEW Team Member
        </Text>
        <Text color="#d7d7d7">Lorem Ipsum is simply dummy text.</Text>
      </GridItem>
      <GridItem colStart={2} colEnd={4} color="#fff">
        <Box>
          <Box mb="15px">
            <Flex>
              <Text mb="8px">First Name</Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Enter First Name"
              size="sm"
              borderRadius="0px"
              fontSize="12px"
              borderColor="#494552"
              bg="#1c181e"
              value={teamMemberForm.first_name}
              name="first_name"
              onChange={onUpdateField}
              _placeholder={{ color: 'white' }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {error?.first_name}
            </Text>
          </Box>
          <Box mb="15px">
            <Flex>
              <Text mb="8px">Last Name</Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Enter Last Name"
              size="sm"
              borderRadius="0px"
              fontSize="12px"
              borderColor="#494552"
              bg="#1c181e"
              value={teamMemberForm.last_name}
              name="last_name"
              onChange={onUpdateField}
              _placeholder={{ color: 'white' }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {error?.last_name}
            </Text>
          </Box>
          <Box mb="15px">
            <Flex>
              <Text mb="8px">Team Menber Email</Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Enter Team Member Email"
              size="sm"
              borderRadius="0px"
              fontSize="12px"
              borderColor="#494552"
              bg="#1c181e"
              value={teamMemberForm.teammember_email}
              name="teammember_email"
              onChange={onUpdateField}
              _placeholder={{ color: 'white' }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {error?.teammember_email}
            </Text>
          </Box>
          <Box mb="15px">
            <Flex>
              <Text mb="8px">Verify Email</Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Re-Enter Email"
              size="sm"
              borderRadius="0px"
              fontSize="12px"
              borderColor="#494552"
              bg="#1c181e"
              value={retype_teammemberemail}
              name="retype_teammemberemail"
              onChange={e => {
                setretype_teammemberemail(e.target.value);
              }}
              _placeholder={{ color: 'white' }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {retype_teammemberemailErr}
            </Text>
          </Box>

          <Button
            size="sm"
            variant="outline"
            fontWeight="normal"
            fontSize="10px"
            borderColor="#494552"
            onClick={e => {
              submitTeamMemberData(e);
            }}
            type="button"
            _hover={{ bg: defaultThemeColor }}
            isDisabled={loading ? true : false}
            isLoading={loading ? true : false}
          >
            Submit
          </Button>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default AddTeamMember;
