// Chakra Imports
import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Flex,
  Heading,
  Tab,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

// Component Imports
import BulkUpload from "./BulkUpload";
import BulkTransfer from "./BulkTransfer";

import { borderBottomColor, defaultThemeColor } from "../../../constants";

function AdvanceSettings() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const resourceName = state?.resource_name;
  const resourceType = state?.resource_type;
  return (
    <>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"}>
          <AiOutlineArrowLeft
            color="#fff"
            fontSize={"20px"}
            cursor={"pointer"}
            onClick={() => {
              navigate(-1);
            }}
          />
          <Heading color={"#fff"} fontWeight="600" fontSize="18px" ml={"3"}>
            {resourceName}
          </Heading>
          <Text
            bg={"green.400"}
            ml="5"
            p="2"
            fontWeight={"600"}
            borderRadius={"10px"}
            color="#000"
          >
            {resourceType}
          </Text>
        </Flex>
      </Flex>
      <Tabs w={"100%"}>
        <TabList color="#d7d7d7" borderBottom={"0px"}>
          <Tab
            fontSize="16px"
            _selected={{
              color: "#fff",
              borderBottomColor: defaultThemeColor,
            }}
            _focus={{ boxShadow: "none" }}
          >
            Bulk Transfer from Google Drive
          </Tab>
          <Tab
            fontSize="16px"
            _selected={{
              color: "#fff",
              borderBottomColor: defaultThemeColor,
            }}
            _focus={{ boxShadow: "none" }}
          >
            Bulk Upload
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel id="Bulk Transfer">
            <Flex
              w={"100%"}
              pl="5"
              borderWidth="0.5px"
              borderColor={borderBottomColor}
              borderRadius="10px"
              color="white"
              alignItems="center"
              justifyContent="space-between"
            >
              <BulkTransfer resourceUuid={state.resource_uuid} />
            </Flex>
          </TabPanel>
          <TabPanel id="Bulk Upload">
            <Flex
              w={"100%"}
              borderWidth="0.5px"
              borderColor={borderBottomColor}
              rounded={"md"}
              color="white"
              alignItems={"start"}
              p="2"
            >
              <BulkUpload resourceUuid={state.resource_uuid} />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default AdvanceSettings;
