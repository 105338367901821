import React from "react";
import { Text } from "@chakra-ui/react";

function CreatePipeLine() {
  return (
    <Text color="#fff" p="10px" fontWeight={"600"}>
      Create PipeLine
    </Text>
  );
}

export default CreatePipeLine;
