export function convertBasicRLEToCocolibRLE(counts) {
    const R = {m: counts.length, cnts: counts};
    /* Similar to LEB128 but using 6 bits/char and ascii chars 48-111. */
    let m = R.m, p = 0, x, more;
    let s = new Array(m * 6);
    for(let i = 0; i < m; i++) {
        x = R.cnts[i]; if(i > 2) x -= R.cnts[i - 2]; more = 1;
        while(more) {
            let c = x & 0x1f; x >>= 5; more = (c & 0x10) ? x != -1 : x != 0;
            if(more) c |= 0x20; c += 48; s[p++] = String.fromCharCode(c);
        }
    }
    return s.join('');
}

export function convertCocolibRLEToBasicRLE(s) {
    let m = 0, p = 0, x, more;
    let cnts = new Array(s.length);
    while(p < s.length) {
        x = 0; more = 1;
        let k = 0;
        while(more) {
            let c = s.charCodeAt(p) - 48; x |= (c & 0x1f) << 5 * k; more = c & 0x20; p++; k++;
            if(!more && (c & 0x10)) x |= -1 << 5 * k;
        }
        if(m > 2) x += cnts[m - 2];
        cnts[m++] = x;
    }
    return cnts.slice(0, m)
}

export function convertBasicRLEToRawData(counts, height, width, fillColor) {
    let newCanvas = document.createElement('canvas');
    newCanvas.width = width;
    newCanvas.height = height;

    // Get the context of the new canvas
    let newContext = newCanvas.getContext('2d');
    newContext.fillStyle = fillColor;
    newContext.fillRect(0, 0, 1, 1);
    const newColorData = newContext.getImageData(0, 0, 1, 1).data;
    newContext.clearRect(0, 0, 1, 1);
    // Safeguards for memory boundary
    let newImageDataArr = new Uint8ClampedArray(height * width * 4);
    let c = 0;
    let v = false;
    for(let j = 0; j < counts.length; j++) {
        for(let k = 0; k < counts[j]; k++ ) {
            if ( c >= height * width ) {
              // Memory boundary would be crossed, wrong RLE
              return null;
            }
            const x = Math.trunc(c / height);
            const y = c % height;
            const i = (y * width + x) * 4;
            if (v) {
                for (let l = 0; l < 4; l++) {
                    newImageDataArr[i + l] = newColorData[l];
                }
            }
            c++;
        }
        v = !v;
    }
    const newImageData = new ImageData(newImageDataArr, width, height);
    newContext.putImageData(newImageData, 0, 0);
  return newCanvas;
}

export function convertRawDataToBasicRLE(canvasData) {
    let rle = [];
    let count = 0;
    let currentValue = 0;
    let width = canvasData.width;
    let height = canvasData.height;
    let data = canvasData.data;

    for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
            let i = (y * width + x) * 4;

            let pixelValue = (data[i] < 128 && data[i+1] < 128 && data[i+2] < 128) ? 0 : 1;

            if (pixelValue !== currentValue) {
                rle.push(count);
                count = 0;
                currentValue = pixelValue;
            }
            count++;
        }
    }

    rle.push(count);

    return rle;
}

export function cloneAndScaleContext(originalCanvas, scale) {
    // Create a new canvas
    let newCanvas = document.createElement('canvas');
    newCanvas.width = originalCanvas.width * scale;
    newCanvas.height = originalCanvas.height * scale;

    // Get the context of the new canvas
    let newContext = newCanvas.getContext('2d');

    // Draw the contents of the original canvas onto the new canvas
    newContext.drawImage(originalCanvas, 0, 0, newCanvas.width, newCanvas.height);

    // Return the context of the new canvas
    return newContext;
}

export function loadBasicRLEToRawData(counts, height, width, context) {
    // Get the context of the new canvas
    let newImageDataArr = new Uint8ClampedArray(height * width * 4);
    let c = 0;
    let v = false;
    for(let j = 0; j < counts.length; j++) {
        for(let k = 0; k < counts[j]; k++ ) {
            if ( c >= height * width ) {
              // Memory boundary would be crossed, wrong RLE
              return null;
            }
            const x = Math.trunc(c / height);
            const y = c % height;
            const i = (y * width + x) * 4;
            if (v) {
                for (let l = 0; l < 4; l++) {
                    newImageDataArr[i + l] = 255;
                }
            }
            c++;
        }
        v = !v;
    }
    const newImageData = new ImageData(newImageDataArr, width, height);
    context.putImageData(newImageData, 0, 0);
}
