import React from 'react';
import {
  Box,
  Flex,
  Progress,
  Icon,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Img,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { BsGrid1X2Fill } from 'react-icons/bs';
import { AiFillSetting, AiOutlineCodeSandbox } from 'react-icons/ai';
import CloudSelection from '../../../image/workinprogress.jpg';
import { defaultThemeColor } from '../../../constants/index';

function Workspace() {
  return (
    <Box>
      <Flex p="10px" color="white" bgGradient="linear(to-r, #4a1d9b, #c552ff)">
        <Icon w={5} h={5} as={AiFillSetting} mr="10px"></Icon>
        <Text>13% / Deploying Nodes</Text>
      </Flex>
      <Progress colorScheme="purple" size="xs" value={13} />
      <Flex
        p="10px"
        color="white"
        borderBottomWidth="0.5px"
        borderColor="#494552"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <Icon
            w={5}
            h={5}
            color={'#ee7733'}
            as={AiOutlineCodeSandbox}
            mr="10px"
          ></Icon>
          <Text>Prodmap Workspace Group 1</Text>
          <Text
            ml="10px"
            fontSize={'10px'}
            p="3px"
            bg="#241a52"
            borderRadius={'5px'}
            color="#947ff5"
          >
            AWS/US WEST 2 (OREGON)
          </Text>
          <Text
            ml="10px"
            fontSize={'10px'}
            p="3px"
            bg="#183828"
            color="#36ad73"
            borderRadius={'5px'}
          >
            FREE TRIAL
          </Text>
        </Flex>
        <Flex alignItems="center">
          {/* <Text mr="10px" color="#d7d7d7">
            Last Updated: 12:29:47PM
          </Text> */}
          <Button
            color="#d7d7d7"
            variant="outline"
            borderRadius="0px"
            borderColor="#6b6b6b"
            fontSize="10px"
            size="xs"
            p="10px"
            mr="10px"
          >
            Contact US
          </Button>
          <IconButton
            color="#d7d7d7"
            variant="outline"
            borderRadius="0px"
            borderColor="#6b6b6b"
            fontSize="10px"
            size="xs"
            p="10px"
            icon={<BsGrid1X2Fill />}
          />
        </Flex>
      </Flex>
      <Tabs>
        <TabList color="#d7d7d7">
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomColor: '#c552ff',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Overwier
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomColor: '#c552ff',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Workspace
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomColor: { defaultThemeColor },
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Database
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomColor: { defaultThemeColor },
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Access
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomColor: { defaultThemeColor },
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Firewall
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomColor: { defaultThemeColor },
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Security
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomColor: { defaultThemeColor },
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Updates
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex
              p="10"
              borderWidth="0.5px"
              borderColor="#494552"
              borderRadius="10px"
              color="white"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Text mb="15px" fontSize="16px">
                  WorkspaceGroups
                </Text>
                <Text fontSize="12px">
                  Workspace Groups contain databases and workspaces.They are
                  created <br />
                  in a single region within a cloul provider.We recommend
                  isolating 'Dev',
                  <br /> 'Stagging' and 'Production' environments in seperate
                  workspace groups
                </Text>
              </Box>
              <Img src={CloudSelection} objectFit="contain" />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Workspace;
