// save session_id in localstorage
const saveSessionId = session_id => {
  localStorage.setItem('session_id', session_id);
};

// get session_id from localstorage
const getSessionId = () => {
  return localStorage.getItem('session_id');
};

// save email id in localstorage
const saveEmailId = emailId => {
  localStorage.setItem('emailId', emailId);
};

// get email id from localstorage
const getEmailId = () => {
  return localStorage.getItem('emailId');
};

// save groupId in localstorage
const savegroupId = groupId => {
  localStorage.setItem('groupId', groupId);
};

// get groupId from localstorage
const getGroupId = () => {
  return localStorage.getItem('groupId');
};

const clearLocalStorage = () => {
  return localStorage.clear();
};

// save Auth token in localstorage
const saveAuthToken = authToken => {
  localStorage.setItem('authToken', authToken);
};
// get Auth token from localstorage
const getAuthToken = () => {
  return localStorage.getItem('authToken');
};


export {
  saveSessionId,
  getSessionId,
  saveEmailId,
  getEmailId,
  savegroupId,
  getGroupId,
  clearLocalStorage,
  saveAuthToken,
  getAuthToken
};
