import React, { useState } from 'react';
import { BsGrid1X2Fill } from 'react-icons/bs';
import { defaultThemeColor } from '../../../constants/index';
import {
  Box,
  Flex,
  Icon,
  Text,
  Img,
  Button,
  IconButton,
  SimpleGrid,
  HStack,
  VStack,
} from '@chakra-ui/react';
import {
  AiFillCloseSquare,
  AiOutlineCodeSandbox,
  AiOutlineUserAdd,
} from 'react-icons/ai';
import CloudSelection from '../../../image/workinprogress.jpg';

function BusinessAnalysis() {
  const headerData = [
    {
      label: 'Total Users',
      subData: 'All registered Users',
      icon: AiOutlineUserAdd,
    },
    {
      label: 'Registered Businesses',
      subData: 'All registered Businesses',
      icon: AiOutlineUserAdd,
    },
    {
      label: 'Registered Business Users',
      subData: 'All registered Business Users',
      icon: AiOutlineUserAdd,
    },
  ];

  const [toggleHelpButton, setToggleHelpButton] = useState(true);

  const renderHeaderData = headerData?.map((data, index) => (
    <Flex
      key={index}
      alignItems="center"
      borderWidth="0.5px"
      borderColor="#494552"
      borderRadius="10px"
      py="15px"
      px="10px"
    >
      <HStack>
        <VStack>
          <HStack>
            <Icon
              w={5}
              h={5}
              as={data.icon}
              mr="2"
              color={defaultThemeColor}
            ></Icon>
            <Box>
              <Text color="white" fontSize="16px">
                {data.label}
              </Text>
              <Text color="#d7d7d7" fontSize="10px">
                {data.subData}
              </Text>
            </Box>
          </HStack>
          <HStack w={'100%'} borderTop={'1px'} borderColor="#494552" p={5}>
            <Box>
              <Text color="#d7d7d7" fontSize="14px">
                {'Values comes here'}
              </Text>
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </Flex>
  ));

  return (
    <Box>
      <Flex
        p="10px"
        color="white"
        borderBottomWidth="0.5px"
        borderColor="#494552"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <Icon
            w={8}
            h={8}
            color={'#ee7733'}
            as={AiOutlineCodeSandbox}
            mr="10px"
          ></Icon>
          <Text fontSize={18}>Business & Job Analysis Report</Text>
          {/* <Text
            ml="10px"
            fontSize={'10px'}
            p="3px"
            bg="#241a52"
            borderRadius={'5px'}
            color="#947ff5"
          >
            Master Admin
          </Text>
          <Text
            ml="10px"
            fontSize={'10px'}
            p="3px"
            bg="#183828"
            color="#36ad73"
            borderRadius={'5px'}
          >
            Administrator
          </Text> */}
        </Flex>
        <Flex alignItems="center">
          {/* <Text mr="10px" color="#d7d7d7">
            Last Updated: 12:29:47PM
          </Text> */}
          <Button
            color="#d7d7d7"
            variant="outline"
            borderRadius="0px"
            borderColor="#6b6b6b"
            fontSize="10px"
            size="xs"
            p="10px"
            mr="10px"
          >
            Contact US
          </Button>
          <IconButton
            color="#d7d7d7"
            variant="outline"
            borderRadius="0px"
            borderColor="#6b6b6b"
            fontSize="10px"
            size="xs"
            p="10px"
            icon={<BsGrid1X2Fill />}
          />
        </Flex>
      </Flex>
      <Box p="5">
        <Flex justifyContent="end" mb="3">
          {toggleHelpButton ? null : (
            <Button
              size="xs"
              borderRadius="0px"
              bg={defaultThemeColor}
              onClick={() => {
                setToggleHelpButton(!toggleHelpButton);
              }}
            >
              Help ?
            </Button>
          )}
        </Flex>
        {toggleHelpButton ? (
          <Box
            borderWidth="0.5px"
            borderColor="#494552"
            borderRadius="10px"
            p="5"
            my={4}
          >
            <Flex justifyContent="end" mb="4">
              <AiFillCloseSquare
                color="white"
                fontSize="16px"
                cursor="pointer"
                onClick={() => {
                  setToggleHelpButton(!toggleHelpButton);
                }}
              />
            </Flex>
            <Flex
              color="white"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Text mb="15px" fontSize="16px">
                  Business and Job Analysis
                </Text>
                <Text fontSize="12px">...... TODO .......</Text>
              </Box>
              <Img src={CloudSelection} objectFit="contain" />
            </Flex>
          </Box>
        ) : null}
        <SimpleGrid columns={3} spacing={2}>
          {renderHeaderData}
        </SimpleGrid>
      </Box>
    </Box>
  );
}

export default BusinessAnalysis;
