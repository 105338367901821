// Chakra - React Imports
import React, { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { Box, Text, Flex, Spinner } from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor, greyBgColor } from "../../constants";

// Api Imports
import { fetchSnapshotDetails } from "../../services/sharedServices";

function SharedDetails({ shared_space_id, email, code, setSharedSpaceName }) {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (code?.length) {
      handleSnapshotDetails();
    }
  }, [code]);

  useEffect(() => {
    handleSnapshotDetails();
  }, [currentPage]);

  const handleSnapshotDetails = () => {
    setLoading(true);
    let obj = {
      shared_space_key: shared_space_id,
      user_email: email,
      password: code ? code : "",
    };
    let body = {
      records_per_page: 20,
      page_id: currentPage,
    };
    fetchSnapshotDetails(obj, body)
      .then((res) => {
        setLoading(false);
        if (res?.result) {
          setDetails(res?.data[0]?.pagination_data[0]?.records);
          setTotalPages(res?.data[0]?.pagination_data[0]?.total_pages);
          setSharedSpaceName(res?.data[0]?.shared_space_name);
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
        }
      });
  };

  // Todo:Render Snapshot Details
  function renderSnapshotDetails() {
    if (details && details.length > 0) {
      return details.map((data, index) => (
        <Box
          mb="2"
          key={index}
          borderWidth={"1px"}
          borderRadius={"10px"}
          borderColor={greyBgColor}
          mt="3"
          color={"#fff"}
          fontSize={"14px"}
        >
          <Flex
            w="100%"
            justifyContent="space-between"
            bg={greyBgColor}
            p="1"
            borderTopLeftRadius={"5px"}
            borderTopRightRadius={"5px"}
          >
            <Text fontWeight="500">Entry-{index + 1}</Text>
            <Text fontWeight="500" fontSize={"12px"} color={defaultThemeColor}>
              {data?.created}
            </Text>
          </Flex>
          <Box p="2">
            <Text fontSize={"12px"}>
              {data?.count} {data?.type} added by{" "}
              <Text as="span" color="green.200">
                {data?.author}
              </Text>
            </Text>
          </Box>
        </Box>
      ));
    } else {
      return (
        <Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
          <Text mb="5px" fontSize={16} color={defaultThemeColor}>
            No Records Found
          </Text>
        </Box>
      );
    }
  }
  return (
    <Box>
      <Text color="#fff" textAlign={"center"} fontSize={"20px"}>
        Shared Space Summary
      </Text>
      {loading ? (
        <Flex h="40vh" alignItems="center" justifyContent="center" mt="10">
          <Spinner size="xl" color="#fff" />
        </Flex>
      ) : (
        renderSnapshotDetails()
      )}
      {totalPages > 1 && (
        <Box mt="4">
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={(pageNumber) => {
              setCurrentPage(pageNumber);
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default SharedDetails;
