// Chakra Import
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  Box,
  Flex,
  Spinner,
  useToast,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";

// Component Import
import CardFlip from "./CardFlip";
import ImageGenerationConfig from "../../ImageGenerationConfig";
import ResponsivePagination from "react-responsive-pagination";
import { getEmailId } from "../../../../../utils/localStorageIndex";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import { defaultThemeColor, greyBgColor } from "../../../../../constants";

// Api Import
import { CollectPreviousData } from "../../../../../services/projectTemplateService";

function PreviousImageConfig({ projectId, workflowId, workFlowImage, tabFlag }) {
  const toast = useToast();
  const copyConfigRefs = useRef([]);
  const [loading, isLoading] = useState(false);
  const [configLoading, isConfigLoading] = useState(Array(5).map(() => true));
  const [prevImageData, setPrevImageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);

  useEffect(() => {
    if (projectId && workflowId && tabFlag === 1) {
      renderPreviousImage();
    }
  }, [tabFlag, currentPage]);

  const renderPreviousImage = () => {
    isLoading(true);
    let obj = {
      email: getEmailId(),
      project_uuid: projectId,
      workflow_uuid: workflowId,
      page_id: currentPage,
      records_per_page: 5,
    };
    CollectPreviousData(obj)
      .then((res) => {
        if (res?.data?.length) {
          setPrevImageData(res?.data[0]?.records);
          setTotalPages(res?.data[0]?.total_pages);
        } else {
          setPrevImageData([]);
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  useEffect(() => {
    renderCardFlip();
  }, [prevImageData]);

  const renderCardFlip = () => {
    if (prevImageData?.length) {
      return prevImageData.map((data, index) => renderImages(data, index));
    } else {
      return (
        <Text
          fontWeight="600"
          fontSize="16px"
          p="2"
          bg={greyBgColor}
          m="2"
          color="#fff"
        >
          No Data Found....
        </Text>
      );
    }
  };

  // Todo:Merge Json and Image into an array and render the image
  const renderImages = (data, outerIndex) => {
    const rowJsonData = data?.images[1][0]?.json;
    let imageJsonData = [];
    data?.images?.forEach((subData) => {
      imageJsonData.push(subData[0]);
    });

    const groupedArrays = imageJsonData.reduce(
      (result, currentObject, index, array) => {
        if (index % 2 === 0) {
          result.push([...array.slice(index, index + 2)]);
        }
        return result;
      },
      []
    );

    // Todo:Received Data From Like Component > Card Flip Component
    const resetAfterLikeImage = (value) => {
      if (value === "true") {
        renderPreviousImage();
      }
    };

    return (
      <>
        <Box
          textAlign="left"
          color={defaultThemeColor}
          p="2"
          fontSize="15px"
          fontWeight="600"
          borderWidth="0.5px"
          borderColor={"#585858"}
          key={`Title ${outerIndex}`}
        >
          <Flex justifyContent={"space-between"} px="2">
            <Text>
              Workflow Results (ID # {data?.folder}):{" "}
              {groupedArrays?.length} Images
            </Text>
            <Button
              size={"xs"}
              colorScheme="purple"
              isLoading={configLoading[outerIndex]}
              onClick={() => {
                if (copyConfigRefs.current[outerIndex]) {
                  copyConfigRefs.current[outerIndex]();
                }
              }}
            >
              Copy Settings To Current Design
            </Button>
          </Flex>
        </Box>
        <Box
          borderWidth={"0.5px"}
          borderColor={"#585858"}
          borderTop={"0px"}
          key={outerIndex}
          pt="3"
          mb="3"
        >
          <Box
            display={"-webkit-box"}
            overflow="auto"
            overflowY="hidden"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#fff",
                borderRadius: "10px",
              },
            }}
            cursor="pointer"
          >
            {groupedArrays?.map((value, index) => (
              <CardFlip
                key={outerIndex + index}
                frontImage={value}
                likeRating={data?.star_data ? data?.star_data : []}
                cardFlipStatus={true}
                indexValue={index}
                outerIndexValue={outerIndex}
                workflowCounter={data?.folder}
                sendDataToPrevImageComp={resetAfterLikeImage}
                imageJsonData={prevImageData}
              />
            ))}
          </Box>
          <Accordion allowMultiple>
            <AccordionItem border={"0px"}>
              <AccordionButton justifyContent={"end"} mt="2">
                <Button size="xs" colorScheme="yellow">
                  More Info
                </Button>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <ImageGenerationConfig
                  ref={(element) => {
                    copyConfigRefs.current[outerIndex] = element;
                  }}
                  configLoading={configLoading[outerIndex]}
                  setConfigLoading={(val) => {
                    isConfigLoading((arr) =>
                      [arr.slice(0, outerIndex), val, arr.slice(outerIndex + 1)]
                    );
                  }}
                  rowJsonData={rowJsonData}
                  workFlowImage={workFlowImage}
                  useBackground={(
                    workflowDetails?.workflowObject?.modelCode === 1 ||
                    workflowDetails?.workflowObject?.modelCode === 3 ||
                    workflowDetails?.workflowObject?.modelCode === 4
                  )}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </>
    );
  };

  return (
    <Box>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10" h="60vh">
          <Box textAlign={"center"}>
            <Spinner size="xl" color="#fff" />
            <Text color={defaultThemeColor} fontWeight={"600"} mt="2">
              Loading Previous Design Data...
            </Text>
          </Box>
        </Flex>
      ) : (
        <Box
          h="calc(100vh - 100px)"
          overflow="auto"
          overflowX="hidden"
          mt="3"
          css={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#fff",
              borderRadius: "24px",
            },
          }}
        >
          {renderCardFlip()}
          {prevImageData?.length ? (
            <Box mt="4">
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
}

export default PreviousImageConfig;
