import axios from 'axios';
import { getSessionId, getEmailId,  
    getAuthToken,
    clearLocalStorage,
 } from '../utils/localStorageIndex';

function setBaseUrl() {
  var baseURL;
  console.log(process.env.REACT_APP_DEPLOYENT_TYPE);
  if (process.env.REACT_APP_DEPLOYENT_TYPE === 'aws'){
      console.log(process.env.REACT_APP_BASE_URL_AWS);
      baseURL = process.env.REACT_APP_BASE_URL_AWS;
  } else {
    console.log(process.env.REACT_APP_BASE_URL_LOCAL);
    baseURL = process.env.REACT_APP_BASE_URL_LOCAL;
  }
  if (!baseURL) {
    baseURL = "http://ec2-54-185-207-60.us-west-2.compute.amazonaws.com:8020";
  }
  return baseURL
}

// Generate axios instance
const instance = axios.create({
  // baseURL: setBaseUrl(),
  baseURL: "https://corbu.ai",
  // baseURL: "http://ec2-54-185-207-60.us-west-2.compute.amazonaws.com:8020",
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

instance.interceptors.request.use(
  async requestConfig => {
    if (requestConfig.url.startsWith("https://")) {
      return requestConfig;
    }
    let token = await getAuthToken(); // Retrieve the token
    if (token && token.trim().length > 0) {
      requestConfig.headers['Authorization'] = `Bearer ${token}`; // Use the token in the request headers
    }
    return requestConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response.status === 401) {
      clearLocalStorage();
      window.location = '/';
    } else if (error.response.status === 404) {
      window.location = '/';
    }
    throw error;
  }
);

export default instance;
