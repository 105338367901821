// React Chakra Imports
import React, { useEffect, useState } from "react";
import { FaChartBar, FaChartLine } from "react-icons/fa";
import { Box, Flex, Button, Heading, Spinner, useToast } from "@chakra-ui/react";
import ResponsivePagination from "react-responsive-pagination";

import {
  Bar,
  BarChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { toastFunctionToaster } from "../../../utils/toastFunction";
import {
  likedImageStatistics,
  projectStatistics,
  workflowActionStatistics,
  workflowStatistics,
} from "../../../services/projectTemplateService";
import { promptStatistics } from "../../../services/promptServices";
import { resourceStatistics } from "../../../services/resourceTemplateService";

function ChartDashboard() {
  const [filterInterval, setFilterInterval] = useState("week");
  const [statType, setStatType] = useState("wfaction");
  const [statData, setStatData] = useState([]);
  const [loading, isLoading] = useState(true);
  const [useLine, setUseLine] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();

  useEffect(() => {
    fetchStatistics(statType, filterInterval, currentPage);
  }, [statType, filterInterval, currentPage]);

  // Fetch Statistics
  const fetchStatistics = (stat, interval, page) => {
    const objData = {
      filter_interval: interval,
      filterEnabled: "",
      records_per_page: 0,
      page_id: page,
    };
    let fetchStatsApiChoice = null;
    switch(stat) {
      case "project":
        fetchStatsApiChoice = projectStatistics;
        break;
      case "resource":
        fetchStatsApiChoice = resourceStatistics;
        break;
      case "workflow":
        fetchStatsApiChoice = workflowStatistics;
        break;
      case "wfaction":
        fetchStatsApiChoice = workflowActionStatistics;
        objData["filter_engine"] = "corbu";
        break;
      case "imglike":
        fetchStatsApiChoice = likedImageStatistics;
        break;
      case "prompt":
        fetchStatsApiChoice = promptStatistics;
        break;
      default:
        return
    }
    isLoading(true);
    fetchStatsApiChoice(objData)
      .then((res) => {
        if (res?.data[0]?.records?.length > 0) {
          setStatData(res?.data[0]?.records);
          if (stat === "wfaction") {
            objData["filter_engine"] = "stability";
            fetchStatsApiChoice(objData)
              .then((res) => {
                if (res?.data[0]?.records?.length > 0) {
                  setStatData((data) => (
                    data.map((entry, index) => (
                      {
                        label: entry.label,
                        "Corbu AI": entry.count,
                        "Stability SD3": res?.data[0]?.records[index].count
                      }
                    ))
                  ));
                  toast(toastFunctionToaster(res?.message, "success"));
                } else {
                  setStatData([]);
                  toast(toastFunctionToaster(res?.message, "error"));
                }
              })
              .catch((err) => {
                setStatData([]);
                toast(toastFunctionToaster("Something Went Wrong", "error"));
              });
          } else {
            toast(toastFunctionToaster(res?.message, "success"));
          }
        } else {
          setStatData([]);
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        setStatData([]);
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  return (
    <Box mb="5">
      <Flex justifyContent={"space-between"}>
        <Heading alignItems={"left"} color="#fff" fontWeight="600" fontSize="24px">
          <>
            Activity Charts
            <Button size="xs" ml="3" colorScheme="teal"
              onClick={(e) => {setUseLine(!useLine)}}
            >
              {useLine ? <FaChartBar fontSize="18px" /> : <FaChartLine fontSize="18px" />}
            </Button>
          </>
        </Heading>
        <Flex alignItems={"center"}>
          <Button size="sm" mr="2" colorScheme="purple"
            onClick={(e) => {setStatType("project")}}
            isActive={statType === "project"}
          >
            New Projects
          </Button>
          <Button size="sm" mr="2" colorScheme="purple"
            onClick={(e) => {setStatType("resource")}}
            isActive={statType === "resource"}
          >
            New Resources
          </Button>
          <Button size="sm" mr="2" colorScheme="purple"
            onClick={(e) => {setStatType("workflow")}}
            isActive={statType === "workflow"}
          >
            New Workflows
          </Button>
          <Button size="sm" mr="2" colorScheme="purple"
            onClick={(e) => {setStatType("wfaction")}}
            isActive={statType === "wfaction"}
          >
            Workflow Actions
          </Button>
          <Button size="sm" mr="2" colorScheme="purple"
            onClick={(e) => {setStatType("imglike")}}
            isActive={statType === "imglike"}
          >
            Liked Images
          </Button>
          <Button size="sm" colorScheme="purple"
            onClick={(e) => {setStatType("prompt")}}
            isActive={statType === "prompt"}
          >
            Saved Prompts
          </Button>
        </Flex>
        <Flex alignItems={"right"}>
          <Button size="sm" mr="2" colorScheme="yellow"
            onClick={(e) => {setFilterInterval("hour")}}
            isActive={filterInterval === "hour"}
          >
            Hour
          </Button>
          <Button size="sm" mr="2" colorScheme="yellow"
            onClick={(e) => {setFilterInterval("day")}}
            isActive={filterInterval === "day"}
          >
            Day
          </Button>
          <Button size="sm" mr="2" colorScheme="yellow"
            onClick={(e) => {setFilterInterval("week")}}
            isActive={filterInterval === "week"}
          >
            Week
          </Button>
          <Button size="sm" mr="2" colorScheme="yellow"
            onClick={(e) => {setFilterInterval("month")}}
            isActive={filterInterval === "month"}
          >
            Month
          </Button>
          <Button size="sm" colorScheme="yellow"
            onClick={(e) => {setFilterInterval("year")}}
            isActive={filterInterval === "year"}
          >
            Year
          </Button>
        </Flex>
      </Flex>
      <Flex
        width="100%"
        bg={"#151117"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt="4"
        p="10px"
        borderRadius={"10px"}
      >
        <ResponsiveContainer height={500}>
          {loading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner size="xl" color="#fff" />
            </Flex>
          ) : (
            useLine ? (
              <LineChart
                data={statData}
                margin={{
                top: 5,
                right: 20,
                left: 10,
                bottom: 5,
              }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey={statType === "wfaction" ? "Corbu AI": "count"}
                  stroke="#ff7300"
                  yAxisId={0}
                />
                {statType === "wfaction" &&
                  <Line type="monotone" dataKey="Stability SD3" stroke="#387908" yAxisId={0} />
                }
              </LineChart>
            ) : (
              <BarChart
                data={statData}
                margin={{
                  top: 5,
                  right: 20,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey={statType === "wfaction" ? "Corbu AI": "count"} fill="#8884d8" />
                {statType === "wfaction" &&
                  <Bar dataKey="Stability SD3" fill="#82ca9d" />
                }
              </BarChart>
            )
          )}
        </ResponsiveContainer>
      </Flex>
      <Box
        width="100%"
        alignItems={"center"}
        justifyContent={"space-between"}
        mt="4"
      >
        <ResponsivePagination
          current={currentPage}
          total={10}
          onPageChange={(pageNumber) => {
            setCurrentPage(pageNumber);
          }}
        />
      </Box>
    </Box>
  );
}

export default ChartDashboard;
