import React, { useState } from "react";
import { Flex, Box } from "@chakra-ui/react";
import PromptResult from "./PromptResult";
import PromptLibraryCrud from "./PromptLibraryCrud";

function PromptLibrary() {
  const [imageUrl, setImageUrl] = useState(null);
  return (
    <Flex w="100%" h="100%" justifyContent={"space-between"}>
      <Box w="75%">
        <PromptLibraryCrud setExampleImageUrl={setImageUrl}/>
      </Box>
      <Box w="24%">
        <PromptResult imageUrl={imageUrl}/>
      </Box>
    </Flex>
  );
}

export default PromptLibrary;
