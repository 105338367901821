import { setupReferenceObject } from "../../services/projectTemplateService";
import { getEmailId } from "../../utils/localStorageIndex";

const CopyImageToClipboard = () => {
  const email = getEmailId();
  const copyImageToClipboard = async (objectName) => {
    let objBody = {
      email: email,
      object_info: objectName,
    };

    try {
      const res = await setupReferenceObject(objBody);
      const img = res?.data[0]?.object[0]?.url;

      // Fetch the image as Blob
      const response = await fetch(img);
      let blob = await response.blob();
      try {
        // Get the actual MIME type of the Blob
        let mimeType = blob.type;

        // If MIME type is binary/octet-stream, set a default MIME type
        if (blob.type !== "image/png") {
          blob = blob.slice(0, blob.size, "image/png");
        }

        // Create a ClipboardItem with the image Blob
        const clipboardItem = new ClipboardItem({
          [blob.type]: blob,
        });

        await navigator.clipboard.write([clipboardItem]);
      } catch (error) {
        console.error("Failed to copy image to clipboard: ", error);
      }
    } catch (err) {
      console.error("Error setting up reference object: ", err);
    }
  };

  return { copyImageToClipboard };
};

export default CopyImageToClipboard;
