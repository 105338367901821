// React Chakra Imports
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  HStack,
  Button,
  Tooltip,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";

// Component Imports
import axios from "../../../../../../services/axios";
import { getEmailId } from "../../../../../../utils/localStorageIndex";
import { defaultThemeColor, greyBgColor } from "../../../../../../constants";
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";

// Api Services
import { setupReferenceObject } from "../../../../../../services/projectTemplateService";
import { fetchObjectUploadUrl } from "../../../../../../services/resourceTemplateService";
import { setTabFlag } from "../../../../../../store/actions/workFlowAction";
import { FaCheck, FaEraser, FaTimes, FaUndo } from "react-icons/fa";

function LineDraw(props) {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const divContent = useRef(null);
  const canvasRefData = useRef(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [isErasing, setIsErasing] = useState(false);
  const [lineWidth, setLineWidth] = useState(2);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [canvasWidth, setCanvasWidth] = useState(512);
  const [canvasHeight, setCanvasHeight] = useState(512);
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const workflowDetailsData = workflowDetails?.workflowObject;
  const sourceImage = workflowDetailsData?.source_image?.length
    ? workflowDetailsData?.source_image
    : "";

  const resource_uuid = workflowDetailsData?.resource_uuid;
  const [uploadStatus, setUploadStatus] = useState();

  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFileUrl, setUploadFileUrl] = useState(null);
  const [brushColor, setBrushColor] = useState("black");
  const [aspectRatio, setAspectRatio] = useState("landscape");
  const [canvasHistory, setCanvasHistory] = useState([]);
  const [historyStep, setHistoryStep] = useState(-1);

  useEffect(() => {
    // Save initial blank canvas state
    const canvas = canvasRefData.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear any existing drawing
    saveToHistory(); // Save the initial state
  }, []);

  useEffect(() => {
    if (props?.tabFlag === 3) {
      handleImageLoad();
    }
  }, [props?.tabFlag]);

  useEffect(() => {
    if (aspectRatio === "landscape") {
      setCanvasHeight(512);
      setCanvasWidth(704);
    } else if (aspectRatio === "square") {
      setCanvasHeight(512);
      setCanvasWidth(512);
    } else if (aspectRatio === "portrait") {
      setCanvasHeight(704);
      setCanvasWidth(512);
    }
  }, [aspectRatio]);

  const getCoordinates = (event) => {
    const canvas = canvasRefData.current;
    const rect = canvas.getBoundingClientRect();
    return {
      offsetX: event.clientX - rect.left,
      offsetY: event.clientY - rect.top,
    };
  };

  const startDrawing = (event) => {
    event.preventDefault();
    const { offsetX, offsetY } = getCoordinates(event);
    setIsDrawing(true);
    const canvas = canvasRefData.current;
    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  // Todo:Draw canvas image
  const draw = (event) => {
    event.preventDefault();
    if (!isDrawing) {
      return;
    }
    const { offsetX, offsetY } = getCoordinates(event);
    const canvas = canvasRefData.current;
    const ctx = canvas.getContext("2d");

    if (isErasing) {
      ctx.globalCompositeOperation = "destination-out";
      ctx.lineWidth = lineWidth;
    } else {
      ctx.globalCompositeOperation = "source-over";
      ctx.strokeStyle = brushColor;
      ctx.lineWidth = lineWidth;
    }
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  const endDrawing = (event) => {
    event.preventDefault();
    setIsDrawing(false);
    if (event?.type !== "pointerleave") {
      saveToHistory();
    }
  };

  // Todo:Convert canvas to image object and store to AWS S3
  const exportImage = () => {
    const canvas = canvasRefData.current;
    const ctx = canvas.getContext("2d");
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    uploadImageToS3DB(canvas);
  };

  const toggleEraserMode = () => {
    setIsErasing(!isErasing);
  };

  useEffect(() => {
    if (isErasing) {
      setLineWidth(50);
    } else {
      setLineWidth(2);
    }
  }, [isErasing]);

  // Todo:Set canvs height width as per image height width
  const handleImageLoad = () => {
    if (divContent.current) {
      const { width, height } = divContent.current.getBoundingClientRect();
      setCanvasWidth(width);
      setCanvasHeight(height);
    }
  };

  // Todo:Upload Image
  const uploadImageToS3DB = (image) => {
    const randomFileName = generateRandomFileName() + ".png";
    const dataURL = canvasToDataURL(image);
    // Convert image object to Blob
    dataURLToImage(dataURL)
      .then((image) => {
        return imageToBlob(image);
      })
      .then((blob) => {
        const file = new File([blob], randomFileName, { type: "image/png" });
        setUploadFile(file);
        setUploadFileUrl(URL.createObjectURL(file));
      });
  };

  function generateRandomFileName() {
    const timestamp = new Date().getTime();
    return "LineDraw" + timestamp;
  }

  //  Todo:Function to convert canvas content to data URL
  function canvasToDataURL(canvas) {
    return canvas.toDataURL("image/png");
  }

  //  Todo:Function to convert data URL to image object
  function dataURLToImage(dataURL) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = dataURL;
    });
  }

  // Todo:Convert Image to type blob
  function imageToBlob(img) {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  }

  useEffect(() => {
    if (uploadFileUrl?.length) {
      handleSubmitNew();
    }
  }, [uploadFileUrl, uploadFile]);

  // Todo:Add new uploaded sketch to source or guidance image
  const handleSubmitNew = async () => {
    if (uploadFile) {
      isLoading(true);
      let objData = {
        email: getEmailId,
        resource_uuid: resource_uuid,
        file_name: uploadFile.name,
        file_type: uploadFile.type,
        resource_choice: "object",
      };
      const response = await fetchObjectUploadUrl(objData);
      if (
        response &&
        response?.data &&
        response?.data?.length > 0 &&
        response.data[0]?.signedPackage?.signedUrl
      ) {
        const finalUrl = response.data[0]?.signedPackage?.signedUrl;
        isLoading(false);
        setUploadStatus("Fetching Sketch Image Signed URL..");
        const s3UploadResult = await postObjectData(finalUrl, uploadFile);
      } else {
        isLoading(false);
        setUploadStatus("Unable to get Sketch Image Signed URL..");
        toast(toastFunctionToaster(response?.message, "error"));
      }
    }
  };

  function postObjectData(s3Data, selectedFile) {
    isLoading(true);

    const { url, fields } = s3Data;
    const payload = new FormData();

    // add all the other fields
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    payload.append("file", selectedFile);
    const { data: result } = axios
      .post(url, payload)
      .then((res) => {
        isLoading(false);
        if (res?.data?.length > 0) {
          isLoading(false);
          setUploadStatus("File is uploaded successfully.");
          SupplementObjectHandler(fields);
          toast(
            toastFunctionToaster("File is uploaded successfully", "success")
          );
        } else {
          isLoading(false);
          SupplementObjectHandler(fields);
          setUploadStatus("File is uploaded successfully..");
          toast(
            toastFunctionToaster("File is uploaded successfully", "success")
          );
        }
      })
      .catch((err) => {
        isLoading(false);
        setUploadStatus("Upload failed with Error :" + err);
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      });
  }

  // Upload Image hoc function
  const SupplementObjectHandler = (data, object_role) => {
    try {
      if (data !== null || data !== undefined) {
        let objBody = {
          email: getEmailId,
          object_info: data.key,
        };
        setupReferenceObject(objBody)
          .then((res) => {
            if (res?.data[0]?.object[0]?.url.length) {
              if (workflowDetailsData?.modelCode === 4) {
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: {
                    key: "source_image",
                    value: res?.data[0]?.object[0]?.url,
                  },
                });
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: {
                    key: "object_name",
                    value: res?.data[0]?.object[0]?.object_name,
                  },
                });
              } else {
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: {
                    key: "control_guidance_image",
                    value: res?.data[0]?.object[0]?.url,
                  },
                });
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: {
                    key: "control_guidance_image_name",
                    value: res?.data[0]?.object[0]?.object_name,
                  },
                });
              }
              dispatch(setTabFlag(0));
              toast(
                toastFunctionToaster("Sketch Image Set Successfully", "success")
              );
            } else {
              toast(toastFunctionToaster(res?.message, "error"));
            }
          })
          .catch((err) => {
            toast(toastFunctionToaster(err?.message, "error"));
          });
      }
    } catch (err) {
      toast(toastFunctionToaster(err?.message, "error"));
    }
  };

  // Todo:Clear entire mask data
  const clearMask = () => {
    const canvas = canvasRefData.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
  };

  // Todo:Save History of generated masks
  const saveToHistory = () => {
    const canvas = canvasRefData.current;
    const dataURL = canvas.toDataURL();
    const newHistory = canvasHistory.slice(0, historyStep + 1); // Discard any "redo" history
    newHistory.push(dataURL);
    setCanvasHistory(newHistory);
    setHistoryStep(newHistory.length - 1);
  };

  // Todo:Undo & Redo Mask
  const undoMasking = (action) => {
    const canvas = canvasRefData.current;
    const ctx = canvas.getContext("2d");

    if (action === "undo" && historyStep > 0) {
      const newHistoryStep = historyStep - 1;
      const previousDataURL = canvasHistory[newHistoryStep];
      const img = new Image();
      img.src = previousDataURL;
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);
        setHistoryStep(newHistoryStep);
      };
    } else if (action === "redo" && historyStep < canvasHistory.length - 1) {
      const newHistoryStep = historyStep + 1;
      const nextDataURL = canvasHistory[newHistoryStep];
      const img = new Image();
      img.src = nextDataURL;
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);
        setHistoryStep(newHistoryStep);
      };
    }
  };

  // Todo:Undo Mask By Keyboard Events (ctrl+Z || ctrl+Y)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault();
        undoMasking("undo");
      } else if (event.ctrlKey && event.key === "y") {
        event.preventDefault();
        undoMasking("redo");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [historyStep, canvasHistory]);

  return (
    <Box
      bg={"#151117"}
      borderRadius="10px"
      h="100%"
      my="1"
      p="3"
      position="relative"
      w="100%"
    >
      <Box
        className="draw-canvas-one"
        style={{ position: "relative", width: "fit-content" }}
        h="95%"
        mx="auto"
        overflow={"hidden"}
        ref={divContent}
      >
        {sourceImage?.length ? (
          <img
            alt="Source"
            src={sourceImage}
            style={{
              width: `100%`,
              height: `100%`,
              userSelect: "none",
              userdrag: "none",
              pointerEvents: "none",
            }}
            onLoad={handleImageLoad}
          />
        ) : (
          <img
            src={`https://dummyimage.com/${canvasWidth}x${canvasHeight}/fff/fff.png`}
            alt="Line Draw Canvas"
            style={{
              width: `${canvasWidth}px`,
              height: `${canvasHeight}px`,
              userSelect: "none",
              userdrag: "none",
              pointerEvents: "none",
              objectFit: "contain",
            }}
          />
        )}
        <canvas
          ref={canvasRefData}
          onTouchStart={startDrawing}
          onTouchEnd={endDrawing}
          onTouchMove={draw}
          onPointerDown={startDrawing}
          onPointerUp={endDrawing}
          onPointerMove={draw}
          onPointerLeave={endDrawing}
          width={`${canvasWidth}px`}
          height={`${canvasHeight}px`}
          style={{
            position: "absolute",
            left: "0",
            top: "0",
            zIndex: "2",
            opacity: "0.5",
            touchAction: "none",
          }}
        />
      </Box>

      <HStack
        h="5%"
        width={"100%"}
        bg={greyBgColor}
        p="2"
        borderRadius={"10px"}
      >
        <Flex ml="2" w="100%">
          {!isErasing ? (
            <Flex w="50%">
              <Text color="#fff" w="60%">
                Brush Size
              </Text>
            </Flex>
          ) : null}
          <Slider
            ml="3"
            id="line-width-slider"
            // defaultValue={lineWidth}
            value={lineWidth}
            min={1}
            max={100}
            onChange={(v) => {
              setLineWidth(v);
            }}
            mr="2"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <SliderTrack>
              <SliderFilledTrack bg={defaultThemeColor} />
            </SliderTrack>
            <Tooltip
              hasArrow
              bg={defaultThemeColor}
              color="white"
              placement="top"
              isOpen={showTooltip}
              label={`${lineWidth}%`}
            >
              <SliderThumb />
            </Tooltip>
          </Slider>
          {isErasing ? (
            <Text color="#fff" ml="1" w="60%">
              Eraser Size
            </Text>
          ) : null}
        </Flex>
        {!sourceImage?.length && (
          <Tooltip label="Aspect Ratio">
            <RadioGroup onChange={setAspectRatio} value={aspectRatio} ml="3">
              <Stack direction="row" color="#fff">
                <Radio value="landscape" color="#fff">
                  Landscape
                </Radio>
                <Radio value="square" color="#fff">
                  Square
                </Radio>
                <Radio value="portrait" color="#fff">
                  Portrait
                </Radio>
              </Stack>
            </RadioGroup>
          </Tooltip>
        )}
        {!isErasing && (
          <Tooltip label="Brush Color">
            <RadioGroup onChange={setBrushColor} value={brushColor} ml="3">
              <Stack direction="row" color="#fff">
                <Radio value="black" color="#fff">
                  Black
                </Radio>
                <Radio value="red" color="#fff">
                  Red
                </Radio>
              </Stack>
            </RadioGroup>
          </Tooltip>
        )}
        <Tooltip label="Undo">
          <Button
            size={"xs"}
            mr="1"
            onClick={() => {
              undoMasking("undo");
            }}
            colorScheme="yellow"
          >
            <FaUndo />
          </Button>
        </Tooltip>
        <Tooltip label="Redo">
          <Button
            size={"xs"}
            mr="1"
            onClick={() => {
              undoMasking("redo");
            }}
            colorScheme="blue"
            style={{
              transform: `rotateY(180deg)`,
            }}
          >
            <FaUndo />
          </Button>
        </Tooltip>
        <Tooltip label="Toggle Add / Erase Mode">
          <Button
            size={"xs"}
            mr="2"
            onClick={toggleEraserMode}
            bg={isErasing ? defaultThemeColor : "#d7d7d7"}
          >
            <FaEraser />
          </Button>
        </Tooltip>
        <Tooltip label="Clear Sketch">
          <Button size={"xs"} mr="2" onClick={clearMask} colorScheme="red">
            <FaTimes />
          </Button>
        </Tooltip>
        <Tooltip
          label={`Set Sketch As 
          ${
            workflowDetailsData?.modelCode === 4
              ? "Source Image"
              : "Guidance Image"
          }`}
        >
          <Button
            size={"xs"}
            mr="2"
            onClick={() => {
              exportImage();
            }}
            isDisabled={loading ? true : false}
            isLoading={loading ? true : false}
            colorScheme="green"
          >
            <FaCheck />
          </Button>
        </Tooltip>
      </HStack>
    </Box>
  );
}

export default LineDraw;
