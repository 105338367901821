// React Imports
import React from "react";
import {
  Tab,
  Tabs,
  Heading,
  TabList,
  TabPanels,
  TabPanel,
  Box,
} from "@chakra-ui/react";

// Component Imports
import DepartmentList from "../Department/DepartmentList";
import ViewBusinessInfo from "./BusinessInfo/ViewBusiness";
import BusinessUsersListMgmt from "../BUManagement/BusinessUsersMgmt/BusinessUsersMgmt";
import { defaultThemeColor } from "../../../constants";

function Settings() {
  return (
    <Box>
      <Heading mb="5" color="#fff" fontWeight="600" fontSize="24px">
        Settings
      </Heading>
      <Tabs w="100%" borderBottom={"0px"} mx="auto">
        <TabList border="0px" w="100%" h="5%">
          <Tab
            _selected={{
              color: defaultThemeColor,
              fontWeight: 600,
              borderBottomColor: defaultThemeColor,
            }}
            fontSize="16px"
            px="6"
            py="2"
            color="#fff"
          >
            Team Management
          </Tab>
          <Tab
            _selected={{
              color: defaultThemeColor,
              fontWeight: 600,
              borderBottomColor: defaultThemeColor,
            }}
            fontSize="16px"
            px="6"
            py="2"
            color="#fff"
          >
            Business
          </Tab>
          <Tab
            _selected={{
              color: defaultThemeColor,
              fontWeight: 600,
              borderBottomColor: defaultThemeColor,
            }}
            fontSize="16px"
            px="6"
            py="2"
            color="#fff"
          >
            Departments
          </Tab>
        </TabList>

        <TabPanels mt="3">
          <TabPanel p="0">
            <BusinessUsersListMgmt />
          </TabPanel>
          <TabPanel p="0">
            <ViewBusinessInfo />
          </TabPanel>
          <TabPanel p="0">
            <DepartmentList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Settings;
