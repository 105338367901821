import * as actionType from "../actionTypes";

export const setTopNotificationMessage = (value) => {
  return {
    type: actionType.SET_NOTIFICATION_MESSAGE,
    value: value,
  };
};

export const setTopNotoficationStatusColor = (value) => {
  return {
    type: actionType.SET_NOTIFICATION_STATUS_COLOR,
    value: value,
  };
};

export const setBusinessInfoStatus = (value) => {
  return {
    type: actionType.SET_BUSINESS_INFO_STATUS,
    value: value,
  };
};

export const setExpandMinMenu = (value) => {
  return {
    type: actionType.SET_EXPAND_MIN_SIDEMENU,
    value: value,
  };
};

export const setProfileLogo = (value) => {
  return {
    type: actionType.PROFILE_LOGO,
    value: value,
  };
};
