import React, { useState } from "react";
import { Box, Button, Textarea, useToast } from "@chakra-ui/react";
import { FaComment } from "react-icons/fa";
import { createShareSpaceComment } from "../../services/businessServices";
import { externalCreateComment } from "../../services/sharedServices";
import { toastFunctionToaster } from "../../utils/toastFunction";

function AddComments({
  type,
  sharedSpaceId,
  parentSharedContentId,
  email,
  code,
  handleSpaceData,
  name,
}) {
  const toast = useToast();
  const [comment, setComment] = useState("");
  const [commentLoader, setCommentLoader] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);

  const storeCommentData = () => {
    setCommentLoader(true);
    if (type === "external") {
      const params = {
        shared_space_key: sharedSpaceId,
        user_email: email,
        password: code,
      };
      let obj = {
        comment_text: comment,
        comment_type: "comment",
      };
      if (parentSharedContentId) {
        obj.parent_shared_content_uuid = parentSharedContentId;
      }
      externalCreateComment(params, obj)
        .then((res) => {
          if (res?.result) {
            handleSpaceData();
            setShowTextArea(false);
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster(err?.message, "error"));
          }
        })
        .finally(() => {
          setCommentLoader(false);
        });
    } else {
      let obj = {
        shared_space_uuid: sharedSpaceId,
        comment_text: comment,
        comment_type: "comment",
      };
      if (parentSharedContentId) {
        obj.parent_shared_content_uuid = parentSharedContentId;
      }
      createShareSpaceComment(obj)
        .then((res) => {
          if (res?.result) {
            handleSpaceData();
            setShowTextArea(false);
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster(err?.message, "error"));
          }
        })
        .finally(() => {
          setCommentLoader(false);
        });
    }
  };

  return (
    <>
      <Button
        ml="1"
        size={"xs"}
        colorScheme="purple"
        rightIcon={<FaComment />}
        onClick={() => {
          setShowTextArea(!showTextArea);
        }}
      >
        {name}
      </Button>
      {showTextArea ? (
        <>
          <Textarea
            mt="3"
            color={"white"}
            borderColor={"#252628"}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          {comment?.length > 0 ? (
            <Box w="100%" textAlign="right">
              <Button
                mt="2"
                size={"xs"}
                colorScheme="red"
                onClick={() => {
                  setComment("");
                }}
              >
                Clear
              </Button>
              <Button
                mt="2"
                ml="1"
                size={"xs"}
                colorScheme="green"
                isDisabled={commentLoader ? true : false}
                isLoading={commentLoader ? true : false}
                onClick={() => {
                  storeCommentData();
                }}
              >
                Comment
              </Button>
            </Box>
          ) : null}
        </>
      ) : null}
    </>
  );
}

export default AddComments;
