// React Chakra Imports
import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  Flex,
  Text,
  Spinner,
  useToast,
  Avatar,
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
} from "@chakra-ui/react";

// Component Imports
import { FaRegEdit, FaTimesCircle } from "react-icons/fa";
import AddDepartmentModal from "./AddDepartmentModel";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { getEmailId } from "../../../utils/localStorageIndex";
import ResponsivePagination from "react-responsive-pagination";
import {
  defaultGreyColor,
  defaultThemeColor,
  greyBgColor,
} from "../../../constants/index";

// Api Services Import
import {
  fetchDepartmentServices,
  updateDepartmentData,
} from "../../../services/departmentServices";
import {
  fetchAllHRMangerListPaginated,
  storeDepartmentBusinessUser,
} from "../../../services/businessServices";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function DepartmentList() {
  const toast = useToast();
  const email = getEmailId();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, isLoading] = useState(true);
  const [deptLoading, setDeptLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [assignLoader, setAssignLoader] = useState(false);
  const [departmentDetails, setDepartmentDetails] = useState("");
  const [editDepartmentData, setEditDepartmentData] = useState({});
  const [onlyEnabledFilter, isOnlyEnabledFilter] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [departmentId, setDepartmentId] = useState("");

  useEffect(() => {
    departmentDetailsList();
    fetchBusinessHRMgrsDetails();
    fetchDepartmentHRMgrsDetails();
  }, []);

  // Fetch Department Details
  const departmentDetailsList = () => {
    let objBody = {
      filterEnabled: onlyEnabledFilter ? "true" : "false",
      searchString: searchString,
      email: email,
      records_per_page: 0,
      page_id: currentPage,
    };
    isLoading(true);
    fetchDepartmentServices(objBody)
      .then((res) => {
        if (res?.data[0]?.total_records > 0) {
          setTotalPages(res.data[0].total_pages);
          setDepartmentDetails(res.data[0].records);
        } else {
          setDepartmentDetails([]);
        }
      })
      .catch((err) => {
        setDepartmentDetails([]);
      })
      .finally(() => {
        isLoading(false);
      });
  };

  // Todo:Fetch Business Managers
  const fetchBusinessHRMgrsDetails = () => {
    let objBody = {
      records_per_page: 100,
      page_id: 1,
    };
    fetchAllHRMangerListPaginated(objBody)
      .then((res) => {
        if (res.data.length > 0) {
          setTableData(res.data[0].records);
        }
      })
      .catch((err) => {});
  };

  // Todo:Fetch Department User List
  const fetchDepartmentHRMgrsDetails = (department_uuid) => {
    let objBody = {
      records_per_page: 100,
      page_id: 1,
      department_uuid: department_uuid,
    };
    setDeptLoading(true);
    fetchAllHRMangerListPaginated(objBody)
      .then((res) => {
        if (res.data.length > 0) {
          setDeptData(res.data[0].records);
        } else {
          setDeptData([]);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setDeptLoading(false);
      });
  };

  const storeEditedDepartmentData = (updatedData) => {
    updateDepartmentData(updatedData)
      .then((res) => {
        if (res.status) {
          toast({
            title: res.message,
            status: "success",
            isClosable: true,
            duration: 3000,
          });
          departmentDetailsList();
          setEditDepartmentData({});
        } else {
          toast({
            title: res.message,
            status: "error",
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch((err) => {});
  };

  function renderHrManagerDataCaller() {
    if (tableData && tableData.length > 0) {
      return tableData.map((data) => (
        <Box
          w="100%"
          pl="3"
          borderBottomWidth={"1px"}
          borderBottomColor={greyBgColor}
        >
          <Checkbox
            className="assignMember"
            w="100%"
            defaultChecked={selectedUsers.includes(data.email)}
            onChange={(e) => handleCheckboxChange(data.email, e.target.checked)}
          >
            <Flex
              color="#fff"
              p="10px"
              cursor={"pointer"}
              alignItems={"center"}
              justifyContent={"space-between"}
              key={data?.user_uuid}
            >
              <Flex alignItems={"center"}>
                <Flex alignItems={"center"}>
                  <Avatar
                    size="md"
                    name={data.first_name ? data.first_name : "-"}
                    bg="yellow.500"
                    mr="2"
                    color={"#000"}
                  />
                  <Flex flexWrap={"wrap"}>
                    <Text mb="5px" fontSize={16}>
                      {data.first_name ? data.first_name : "-"}
                      <Text as="span">
                        {" "}
                        {data.last_name ? data.last_name : "-"}
                      </Text>
                    </Text>
                    <Flex alignItems={"center"} w="100%">
                      <Box>
                        <AiOutlineMail
                          color={defaultGreyColor}
                          fontSize={"16px"}
                        />
                      </Box>
                      <Text as="span" ml="2" color={defaultGreyColor}>
                        {data.email ? data.email : "-"}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Checkbox>
        </Box>
      ));
    } else {
      return (
        <Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
          <Text mb="5px" fontSize={16} color={defaultThemeColor}>
            {"No Data Available"}
          </Text>
        </Box>
      );
    }
  }

  // Render Department Details
  const renderDepartmentalList = () => {
    if (departmentDetails?.length > 0) {
      return departmentDetails.map((data) => (
        <Box
          color="#fff"
          p="15px"
          borderBottomWidth={"1px"}
          borderBottomColor={greyBgColor}
          key={data.department_uuid}
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            cursor={"pointer"}
            onClick={() => {
              if (departmentId && data?.department_uuid === departmentId) {
                setDepartmentId("");
                setDeptData([]);
              } else {
                setDepartmentId(data?.department_uuid);
                fetchDepartmentHRMgrsDetails(data?.department_uuid);
              }
            }}
          >
            <Flex alignItems={"center"}>
              <Avatar
                size="md"
                name={data.department_name ? data.department_name : "-"}
                bg="yellow.500"
                mr="2"
                color={"#000"}
              />
              <Text mb="5px" fontSize={16} color="#fff">
                {data.department_name ? data.department_name : "-"}
              </Text>
            </Flex>
            <Flex alignItems={"center"}>
              <Button
                mr="2"
                size="sm"
                colorScheme="yellow"
                onClick={(e) => {
                  e.stopPropagation();
                  onOpen();
                  setSelectedDepartment(data);
                }}
              >
                Assign Team
              </Button>
              <Box mr="2">
                <FaRegEdit
                  color={defaultGreyColor}
                  fontSize={"20px"}
                  cursor={"pointer"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditDepartmentData(data);
                  }}
                />
              </Box>
              <Icon
                as={
                  data?.department_uuid === departmentId
                    ? BsCaretUpFill
                    : BsCaretDownFill
                }
                color={defaultGreyColor}
                fontSize={"22px"}
              />
            </Flex>
          </Flex>
          {data?.department_uuid === departmentId && renderAssignedUsers()}
        </Box>
      ));
    } else {
      return (
        <Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
          <Text mb="5px" fontSize={16} color={defaultThemeColor}>
            You haven't created any business departments yet. Please create a
            business department by selecting "Add New Department".
          </Text>
        </Box>
      );
    }
  };

  // Todo:Remove Assigned Users
  const removeUserFromDepartment = (data) => {
    let obj = { business_user_email: [data?.email] };
    storeDepartmentBusinessUser(obj)
      .then((res) => {
        if (res?.data) {
          setAssignLoader(true);
          resetData();
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          setAssignLoader(true);
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        setAssignLoader(true);
        if (err) {
          toast(toastFunctionToaster("Something Went Wrong!!!", "error"));
        }
      });
  };

  // Todo:Render Assigned Users
  const renderAssignedUsers = () => {
    if (deptLoading) {
      return (
        <Flex alignItems="center" justifyContent="center">
          <Spinner size="lg" color="#fff" />
        </Flex>
      );
    } else if (deptData?.length) {
      return (
        <Box>
          {deptData?.map((data) => (
            <Flex
              color="#fff"
              p="15px"
              borderBottomWidth={"1px"}
              borderBottomColor={greyBgColor}
              borderTopWidth={"1px"}
              borderTopColor={greyBgColor}
              cursor={"pointer"}
              alignItems={"center"}
              justifyContent={"space-between"}
              key={data?.user_uuid}
            >
              <Flex alignItems={"center"}>
                <Flex alignItems={"center"}>
                  <Avatar
                    size="md"
                    name={data.first_name ? data.first_name : "-"}
                    bg="yellow.500"
                    mr="2"
                    color={"#000"}
                  />
                  <Text mb="5px" fontSize={"16px"}>
                    {data.first_name ? data.first_name : "-"}
                    <Text as="span">
                      {" "}
                      {data.last_name ? data.last_name : "-"}
                    </Text>
                  </Text>
                </Flex>
                <Flex alignItems={"center"} ml="2">
                  <Box>
                    <AiOutlinePhone
                      color={defaultGreyColor}
                      fontSize={"16px"}
                    />
                  </Box>
                  <Text as="span" ml="2" color={defaultGreyColor}>
                    {data.phone_number ? data.phone_number : "-"}
                  </Text>
                </Flex>
                <Flex alignItems={"center"} ml="2">
                  <Box>
                    <AiOutlineMail color={defaultGreyColor} fontSize={"16px"} />
                  </Box>
                  <Text as="span" ml="2" color={defaultGreyColor}>
                    {data.email ? data.email : "-"}
                  </Text>
                </Flex>
              </Flex>
              <Button size={"xs"} colorScheme="red">
                <Icon
                  w={5}
                  h={5}
                  as={FaTimesCircle}
                  onClick={() => {
                    removeUserFromDepartment(data);
                  }}
                />
              </Button>
            </Flex>
          ))}
        </Box>
      );
    } else {
      return (
        <Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
          <Text mb="5px" fontSize={16} color={defaultThemeColor}>
            You haven't assigned any business users to this department yet.
            Please assign business users by selecting "Assign Team".
          </Text>
        </Box>
      );
    }
  };

  // Todo:Re-render list after storing data
  const reRenderDepartmentList = (flagValue) => {
    if (flagValue === true) {
      departmentDetailsList();
    }
  };

  // Todo:Reset Department Data
  const resetDepartmentData = () => {
    setEditDepartmentData({});
    setAssignLoader(false);
  };

  //
  const handleCheckboxChange = (userEmail, isChecked) => {
    if (isChecked) {
      setSelectedUsers((prev) => [...prev, userEmail]);
    } else {
      setSelectedUsers((prev) => prev.filter((email) => email !== userEmail));
    }
  };

  const assignBusinessUser = () => {
    if (selectedUsers?.length && selectedDepartment?.department_uuid) {
      setAssignLoader(true);
      let obj = {
        department_uuid: selectedDepartment?.department_uuid,
        business_user_email: selectedUsers,
      };
      storeDepartmentBusinessUser(obj)
        .then((res) => {
          if (res?.data) {
            setAssignLoader(true);
            resetData();
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            setAssignLoader(true);
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          setAssignLoader(true);
          if (err) {
            toast(toastFunctionToaster("Something Went Wrong!!!", "error"));
          }
        });
    }
  };

  const resetData = () => {
    onClose();
    setSelectedUsers([]);
    setAssignLoader(false);
    setSelectedDepartment({});
    setDepartmentId("");
    setDeptData([]);
  };

  return (
    <Box p="15px">
      <Flex>
        <Box
          as="span"
          flex="1"
          textAlign="left"
          color="#fff"
          fontWeight={"600"}
          fontSize="20px"
        >
          Departments
        </Box>
        <AddDepartmentModal
          sendEditableData={editDepartmentData}
          onSaveSetFlag={reRenderDepartmentList}
          fetchEditedData={storeEditedDepartmentData}
          resetDepartmentDetails={resetDepartmentData}
        />
      </Flex>

      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
          <Spinner size="xl" color="#fff" />
        </Flex>
      ) : (
        <Box
          bg={"#151117"}
          borderRadius={"20px"}
          p="15px"
          h="calc(100vh - 35vh)"
          mt="3"
          overflow="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: { greyBgColor },
              borderRadius: "24px",
            },
          }}
        >
          {renderDepartmentalList()}
        </Box>
      )}
      <Box mt="4">
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={(pageNumber) => {
            setCurrentPage(pageNumber);
          }}
        />
      </Box>
      <Modal isOpen={isOpen} onClose={resetData} size="lg">
        <ModalOverlay />
        <ModalContent bg="#1c181e" color="#fff">
          <ModalHeader borderBottom={"1px solid #333333"}>
            <Text as="span" fontWeight={"400"}>
              Assign Team Members to{" "}
              <Text as="span" fontWeight={"500"}>
                {selectedDepartment?.department_name}
              </Text>
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0px">
            <Box
              overflow="auto"
              height={"400px"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: { greyBgColor },
                  borderRadius: "24px",
                },
              }}
            >
              {renderHrManagerDataCaller()}
            </Box>
          </ModalBody>

          <ModalFooter borderTop={"1px solid #333333"}>
            <Button
              colorScheme="green"
              size="sm"
              mr={3}
              isDisabled={selectedUsers?.length === 0 ? true : false}
              isLoading={assignLoader ? true : false}
              onClick={assignBusinessUser}
            >
              Assign
            </Button>
            <Button colorScheme="red" size="sm" onClick={resetData}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default DepartmentList;
