import * as actionType from "../actionTypes";

export const setWorkflowObject = (value) => {
  return {
    type: actionType.SET_WORKFLOW_OBJECT,
    value: value,
  };
};

export const setWorkFlowImageData = (value) => {
  return {
    type: actionType.WORK_FLOW_IMAGE_DATA,
    value: value,
  };
};

export const setWorkFlowEngineLoader = (value) => {
  return {
    type: actionType.WORKFLOW_ENGINE_LOADER,
    value: value,
  };
};

export const setWorkFlowEngineQueue = (value) => {
  return {
    type: actionType.WORKFLOW_ENGINE_QUEUE,
    value: value,
  };
};

export const setImposeFlagData = (value) => {
  return {
    type: actionType.REFERENCE_IMAGE_IMPOSE_FLAG,
    value: value,
  };
};

export const setWorkflowImgCounter = (value) => {
  return {
    type: actionType.WORKFLOW_IMG_COUNTER,
    value: value,
  };
};

export const setSelectedImageType = (value) => {
  return {
    type: actionType.SELECTED_IMG_TYPE,
    value: value,
  };
};

export const setLineDrawFlag = (value) => {
  return {
    type: actionType.LINEDRAW_FLAG,
    value: value,
  };
};

export const setTabFlag = (value) => {
  return {
    type: actionType.SELECTED_TAB_FLAG,
    value: value,
  };
};

export const setSelectedImage = (value) => {
  return {
    type: actionType.SELECTED_CURRENT_IMAGE,
    value: value,
  };
};

export const setStabilityValue = (value) => {
  return {
    type: actionType.TEXT_IMAGE_STABILITY,
    value: value,
  };
};

export const setClipBoardValue = (value) => {
  return {
    type: actionType.COPY_TO_CLIP,
    value: value,
  };
};

export const setDeseignProjectObj = (value) => {
  return {
    type: actionType.STORE_DESIGN_PROJECT_OBJ,
    value: value,
  };
};
