// React Chakra Imports
import React from "react";
import { greyBgColor } from "../../constants";
import { useNavigate } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { Flex, Box, Text, Icon, Avatar } from "@chakra-ui/react";

function Logout(props) {
  const navigate = useNavigate();
  // Todo:Destructure props field.
  const { userEmailId, sideMenuStatus } = props;

  //Todo:Call Logout Function From Parent
  const logout = () => {
    props?.triggerLogoutFunction();
  };

  // Todo:Fetch Business Details

  return (
    <>
      {userEmailId ? (
        sideMenuStatus ? (
          <Flex
            p="10px"
            borderRadius="10px"
            bg={greyBgColor}
            alignItems="center"
            position=" fixed"
            w="15%"
            overflow={"hidden"}
            bottom="10px"
            left="0"
            onClick={() => {
              navigate("/personal/Info");
            }}
            cursor={"pointer"}
          >
            <Box h="50px" w="50px" mr="3" cursor="pointer">
              <Avatar name={userEmailId} />
            </Box>
            <Box>
              <Text
                w="90%"
                whiteSpace="noWrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {userEmailId}
              </Text>
              <Text
                color={"red"}
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  logout();
                }}
                as="span"
              >
                Logout
              </Text>
            </Box>
          </Flex>
        ) : (
          <Box
            textAlign="center"
            cursor="pointer"
            mt="3"
            position=" fixed"
            bottom="4"
            left="5"
          >
            <Icon
              _hover={{ bg: "red.400", color: "white" }}
              onClick={() => {
                logout();
              }}
              rounded={"md"}
              w={10}
              h={10}
              color={"red"}
              as={AiOutlineLogout}
              pl={1}
              pr={1}
            ></Icon>
          </Box>
        )
      ) : null}
    </>
  );
}

export default Logout;
