import React from 'react';
import {
  Grid,
  GridItem,
  SimpleGrid,
  Box,
  Text,
  Button,
} from '@chakra-ui/react';
import { defaultThemeColor } from '../../../constants/index';

function DefaultDashboard() {
  const mainData = [
    {
      label: 'Guides',
      subContent: [
        { label: 'About SingleStore DB Cloud', path: '' },
        { label: 'Size your workspace', path: '' },
        { label: 'Connect to your workspace', path: '' },
        { label: 'Load your data', path: '' },
      ],
    },
    {
      label: 'Release & Training',
      subContent: [
        { label: 'Comprehensive Learning Plan Training', path: '' },
        { label: 'Cloud Release Notes', path: '' },
        { label: 'Our open source content', path: '' },
        { label: 'Become a singleStore Pro', path: '' },
      ],
    },
  ];

  const blogHelpData = [
    {
      label: 'Blog & Community',
      subContent: [
        { label: 'Build a fullstack app', path: '' },
        { label: 'Load 100B Rows of Data', path: '' },
        { label: 'What is HTAP?', path: '' },
        { label: 'Solve MYSQL out-of-memory issue', path: '' },
      ],
    },
    {
      label: 'Help',
      subContent: [
        { label: 'Interactive tutorials', path: '' },
        { label: 'Documentation', path: '' },
        { label: 'System Status?', path: '' },
        { label: 'Ask the community', path: '' },
      ],
    },
  ];

  const renderContentData = mainData?.map((data, idx) => (
    <Box key={idx} p="10px">
      <Text color="white" fontSize="14px">
        {data.label}
      </Text>
      <Text color="#d7d7d7" fontSize="10px" mb="15px">
        {data.subData}
      </Text>
      {data.subContent.map((innerData, index) => (
        <Text
          key={idx + index}
          color={defaultThemeColor}
          fontSize="12px"
          mb="5px"
        >
          {innerData.label}
        </Text>
      ))}
    </Box>
  ));
  const renderBottomContentData = blogHelpData?.map((data, idx) => (
    <Box key={idx} p="10px">
      <Text color="white" fontSize="14px">
        {data.label}
      </Text>
      <Text color="#d7d7d7" fontSize="10px" mb="15px">
        {data.subData}
      </Text>
      {data.subContent.map((innerData, index) => (
        <Text
          key={idx + index}
          color={defaultThemeColor}
          fontSize="12px"
          mb="5px"
        >
          {innerData.label}
        </Text>
      ))}
    </Box>
  ));
  return (
    <>
      <Grid templateColumns="repeat(5, 1fr)" gap={5} p="3">
        <GridItem colSpan={3}>
          <SimpleGrid
            columns={2}
            spacing={10}
            padding="25px"
            border="1px"
            borderColor="#494552"
            borderRadius="5px"
          >
            {renderContentData}
          </SimpleGrid>
          <SimpleGrid
            columns={2}
            spacing={10}
            padding="25px"
            border="1px"
            borderColor="#494552"
            borderRadius="5px"
            mt="5"
          >
            {renderBottomContentData}
          </SimpleGrid>
        </GridItem>
        <GridItem colStart={4} colEnd={6}>
          <Box border="1px" borderColor="#494552" borderRadius="5px" p="5">
            <Text color="#fff">Ask SQrL,your SingleStore Co-pilot</Text>
            <Text color="#fff" mt="5px">
              Hi! I'm a chat bot trained on everything from <br />
              SingleStoreDB documnetation to code
            </Text>
            <Text color="#fff">Question Examples"</Text>
            <Box>
              <Button
                color="#8c34b8"
                variant="outline"
                borderRadius="5px"
                borderColor="#8c34b8"
                fontSize="10px"
                size="sm"
                mt="10px"
              >
                What is a SingleStoreDB workspace ?
              </Button>
            </Box>
            <Box>
              <Button
                color="#8c34b8"
                variant="outline"
                borderRadius="5px"
                borderColor="#8c34b8"
                fontSize="10px"
                size="sm"
                mt="10px"
              >
                How do I load my data into SingleStoreDB ?
              </Button>
            </Box>
            <Box>
              <Button
                color="#8c34b8"
                variant="outline"
                borderRadius="5px"
                borderColor="#8c34b8"
                fontSize="10px"
                size="sm"
                mt="10px"
              >
                How do I connect my application to my workspace ?
              </Button>
            </Box>
            <Box>
              <Button
                color="#8c34b8"
                variant="outline"
                borderRadius="5px"
                borderColor="#8c34b8"
                fontSize="10px"
                size="sm"
                mt="10px"
              >
                What are notebooks and how go I get started ?
              </Button>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </>
  );
}

export default DefaultDashboard;
