import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { defaultThemeColor, errorColor } from '../../../../constants';
import { fetchTeamMemberData } from '../../../../services/teamServices';
import {
  setTopNotificationMessage,
  setTopNotoficationStatusColor,
} from '../../../../store/actions/topNotificationActions';
import {
  Tr,
  Th,
  Td,
  Box,
  Text,
  Flex,
  Thead,
  Table,
  Tbody,
  Spinner,
  useToast,
  TableContainer,
} from '@chakra-ui/react';

function CorbuTeamList() {
  const toast = useToast();
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [teamMemberData, setTeamMemberData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetchTeamMemberData()
        .then(res => {
          isLoading(false);
          if (res.result && res.data.length) {
            setTeamMemberData(res.data[0]);
            dispatch(setTopNotificationMessage(res?.message));
            dispatch(setTopNotoficationStatusColor('green'));
          } else {
            isLoading(false);
            dispatch(setTopNotificationMessage(res?.message));
            dispatch(setTopNotoficationStatusColor('red'));
          }
        })
        .catch(err => {
          isLoading(false);
        });
    };
    fetchData();
  }, []);

  const renderTeamData = teamMemberData?.map((data, index) => (
    <Box
      bg="#1c181e"
      m="1"
      p="10px"
      borderLeftWidth="5px"
      borderColor={
        data.is_enable === true
          ? defaultThemeColor
          : data.is_enable === false
          ? errorColor
          : 'grey'
      }
    >
      <Text mb="5px" fontSize={16}>
        {' '}
        {data.first_name ? data.first_name : '-'}
        <Text as="span" pl="10px">
          {data.last_name ? data.last_name : '-'}
        </Text>
      </Text>
      <Text mb="5px"> {data.email ? data.email : '-'}</Text>
    </Box>
  ));

  return (
    <>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10">
          <Spinner size="xl" />
        </Flex>
      ) : teamMemberData?.length ? (
        <Box
          h="calc(100vh - 200px)"
          overflow="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#fff',
              borderRadius: '24px',
            },
          }}
        >
          {renderTeamData}
        </Box>
      ) : (
        <Text p="10px">No Team Member Found</Text>
      )}
    </>
  );
}

export default CorbuTeamList;
