// React Chakra Imports
import React, { useState, useEffect } from "react";
import {
  AiFillEdit,
  AiFillLeftCircle,
  AiFillRightCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import {
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi"
import {
  Box,
  Icon,
  Text,
  Flex,
  Input,
  Spinner,
  Image,
  Button,
  useToast,
  Heading,
  useDisclosure,
  VStack,
  HStack,
  Modal,
  AlertDialogHeader,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogOverlay,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Textarea,
} from "@chakra-ui/react";

// Component Import
import { toastFunctionToaster } from "../../../utils/toastFunction";
import {
  defaultGreyColor,
  defaultThemeColor,
  greyBgColor,
} from "../../../constants";

// Services Import
import { fetchImageLabels } from "../../../services/businessServices";
import {
  deleteResourceLabelData,
  updateResourceLabelData,
} from "../../../services/projectTemplateService";

function ObjectMetadataPanel(props) {
  const toast = useToast();
  const btnRef = React.useRef();
  const [loading, isLoading] = useState(false);
  const [editResourceLoading, setEditResourceLoading] = useState(false);
  const [labelPrompt, setLabelPrompt] = useState("");
  const [labelCaption, setLabelCaption] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [objectLabels, setObjectLabels] = useState([]);
  const [imageLabelsCollapseFlag, setImageLabelsCollapseFlag] = useState(false);

  const {
    isOpen: isModalTwoOpen,
    onOpen: onModalTwoOpen,
    onClose: onModalTwoClose,
  } = useDisclosure();
  const cancelRef = React.useRef();

  const showMetadataPanel = props.showMetadataPanel;

  useEffect(() => {
    if (showMetadataPanel) {
      onOpen();
      getObjectLabels();
    }
  }, [props]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === "ArrowLeft") {
        props?.handleResourceObject("left");
      } else if (e.code === "ArrowRight") {
        props?.handleResourceObject("right");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props]);

  const resetMetaDataDetails = (data) => {
    setImageLabelsCollapseFlag(false);
    onClose();
    props.resetMetadataPanelState(false);
  };

  const renderObjectLabels = () => {
    if (loading) {
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          mt="10"
          h="100%"
          w="100%"
        >
          <Spinner size="xl" color="#fff" />
        </Flex>
      );
    } else if (objectLabels && objectLabels.length > 0) {
      return objectLabels.map((data) => (
        <Box
          borderWidth={"1px"}
          borderRadius={"5px"}
          borderColor={defaultGreyColor}
          mb="3"
        >
          <HStack
            w="100%"
            cursor={"pointer"}
            alignItems={"start"}
            borderBottomWidth={"1px"}
            borderBottomColor={defaultGreyColor}
          >
            <Text w="40%" fontWeight={600} color={defaultThemeColor} p="2">
              {data.prompt}
            </Text>
            <Text
              p="2"
              w="50%"
              borderLeft={"1px"}
              borderColor={defaultGreyColor}
              color="#fff"
            >
              {data.caption}
            </Text>
            <VStack
              p="2"
              w="10%"
              justifyContent={"flex-end"}
            >
              <Button
                size={"xs"}
                mb="2"
                colorScheme="yellow"
                onClick={(e) => {
                  onModalTwoOpen();
                  setLabelPrompt(data.prompt);
                  setLabelCaption(data.caption);
                }}
              >
                <Icon w={4} h={4} as={AiFillEdit} />
              </Button>
              <Button
                size={"xs"}
                colorScheme="red"
                onClick={() => {
                  deleteResourceLabel(data);
                }}
              >
                <Icon w={4} h={4} as={AiOutlineDelete} />
              </Button>
            </VStack>
          </HStack>
        </Box>
      ));
    } else {
      return (
        <Text fontWeight={600} color={defaultThemeColor}>
          {" "}
          No Labels Found{" "}
        </Text>
      );
    }
  };

  // Todo:Delete Resource Label
  const deleteResourceLabel = (data) => {
    let obj = {
      objectKey: props.selectedObjectName,
      label_prompt: data?.prompt,
    };
    deleteResourceLabelData(obj)
      .then((res) => {
        if (res?.result) {
          getObjectLabels();
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        toastFunctionToaster(err, "error");
      });
  };

  // Todo:Update/Edit Resouce Label Data
  const editResourceLabel = () => {
    setEditResourceLoading(true);
    let obj = {
      objectKey: props.selectedObjectName,
      label_prompt: labelPrompt,
      label_caption: labelCaption,
    };
    updateResourceLabelData(obj)
      .then((res) => {
        setEditResourceLoading(false);
        if (res?.result) {
          getObjectLabels();
          onModalTwoClose();
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        onModalTwoClose();
        setEditResourceLoading(false);
        toastFunctionToaster(err, "error");
      });
  };

  function getObjectLabels() {
    if (props.selectedObjectName && props.resource_uuid) {
      let objBody = { objectKey: props.selectedObjectName };
      isLoading(true);
      fetchImageLabels(objBody)
        .then((res) => {
          isLoading(false);
          if (res?.length > 0) {
            const labels = JSON.parse(res[0]?.json);
            if (labels?.prompts?.length > 0 && labels?.captions?.length > 0) {
              setObjectLabels(
                labels?.prompts?.map((data, index) => {
                  return { prompt: data, caption: labels?.captions?.[index] };
                })
              );
            }
          } else {
            setObjectLabels([]);
          }
        })
        .catch((err) => {
          isLoading(false);
          toast(
            toastFunctionToaster("Error collecting object labels...", "error")
          );
          setObjectLabels([]);
        });
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        placement="right"
        finalFocusRef={btnRef}
        fontFamily="Poppins"
        onClose={() => {
          resetMetaDataDetails(true);
        }}
        size="xxl"
        p="20px"
      >
        <ModalOverlay />
        <ModalContent p="0px" w="80%" bg={greyBgColor} display="flex">
          <ModalCloseButton
            color="white"
            onClick={() => {
              resetMetaDataDetails(true);
            }}
          />
          <ModalBody p="0px" color="#fff">
            <Flex>
              {props?.selectedImageIndex > 0 ? (
                <Box
                  position={"absolute"}
                  left={"10px"}
                  bottom="0%"
                  cursor={"pointer"}
                  onClick={() => {
                    props?.handleResourceObject("left");
                  }}
                  tabIndex="0"
                  zIndex={2}
                >
                  <Icon
                    w={9}
                    h={9}
                    borderRadius={"50px"}
                    bg="#131415"
                    p="5px"
                    color={"#fff"}
                    as={BiLeftArrowAlt}
                    mr="10px"
                  ></Icon>
                </Box>
              ) : null}
              {imageLabelsCollapseFlag ? (
                <VStack w="40%" h="95vh">
                  <Flex justifyContent={"space-between"}>
                    <AiFillLeftCircle
                      position={"absolute"}
                      left="2"
                      top="2"
                      color="#fff"
                      cursor="pointer"
                      size="24px"
                      zIndex="999"
                      bg={greyBgColor}
                      onClick={() => {
                        setImageLabelsCollapseFlag(!imageLabelsCollapseFlag);
                      }}
                    />
                    <Heading
                      as="span"
                      textAlign="center"
                      color={defaultThemeColor}
                      fontSize="24px"
                      fontWeight="600"
                    >
                      Image Labels
                    </Heading>
                  </Flex>
                  <Box
                    overflow="auto"
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "4px",
                      },
                      "&::-webkit-scrollbar-track": {
                        width: "6px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#fff",
                        borderRadius: "24px",
                      },
                    }}
                  >
                    {renderObjectLabels()}
                  </Box>
                </VStack>
              ) : (
                <Box
                  w="5%"
                  p="3"
                  borderRadius="10px"
                  bg={greyBgColor}
                  h="100%"
                  ml="2"
                  pl="5"
                >
                  <Box w={"100%"} h="100%">
                    <AiFillRightCircle
                      cursor="pointer"
                      color="#fff"
                      onClick={() => {
                        setImageLabelsCollapseFlag(!imageLabelsCollapseFlag);
                      }}
                      size="24px"
                    />
                    <Text
                      fontSize="24px"
                      color={defaultThemeColor}
                      mt="2"
                      css={{
                        writingMode: "vertical-lr",
                        textOrientation: "mixed",
                      }}
                    >
                      Expand Image Labels
                    </Text>
                  </Box>
                </Box>
              )}
              <Box
                w={imageLabelsCollapseFlag ? "60%" : "95%"}
                h="95vh"
                borderRightWidth={"1px"}
                borderRightColor={"#3a3a3a"}
              >
                <Image
                  h="100%"
                  w="100%"
                  objectFit={"contain"}
                  src={props.selectedObjectUrl}
                  borderTopLeftRadius={"5px"}
                  borderBottomLeftRadius={"5px"}
                />
              </Box>
              {props?.selectedImageIndex < props?.dataCount - 1 ? (
                <Box
                  position={"absolute"}
                  right={"10px"}
                  bottom="0%"
                  cursor={"pointer"}
                  onClick={() => {
                    props?.handleResourceObject("right");
                  }}
                  tabIndex="0"
                  zIndex={"2"}
                >
                  <Icon
                    w={9}
                    h={9}
                    borderRadius={"50px"}
                    p="5px"
                    borderColor={defaultThemeColor}
                    as={BiRightArrowAlt}
                    mr="10px"
                    bg="#131415"
                    color={"#fff"}
                  ></Icon>
                </Box>
              ) : null}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Todo:Alert For Unlike Image */}
      <AlertDialog
        isOpen={isModalTwoOpen}
        leastDestructiveRef={cancelRef}
        onClose={onModalTwoClose}
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="#1c181e" color="#fff">
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              borderBottomWidth={"1px"}
              borderBottomColor={defaultGreyColor}
            >
              Update Resource Label
            </AlertDialogHeader>
            <AlertDialogBody p="4">
              <Input
                placeholder="Enter Label Prompt"
                size="md"
                mb="3"
                borderRadius="5px"
                fontSize="16px"
                borderColor={!labelPrompt?.length ? "red.400" : "#494552"}
                bg="#1c181e"
                value={labelPrompt}
                name="labelPrompt"
                onChange={(e) => {
                  setLabelPrompt(e.target.value);
                }}
                _placeholder={{ color: "white" }}
                _hover={{
                  boxShadow: "none",
                  borderColor: "none",
                }}
                _focus={{
                  boxShadow: "none",
                  borderColor: "none",
                }}
              />
              <Textarea
                borderRadius="5px"
                minHeight={"150px"}
                placeholder="Enter Label Caption"
                size="md"
                fontSize="16px"
                borderColor={!labelCaption?.length ? "red.400" : "#494552"}
                bg="#1c181e"
                value={labelCaption}
                name="labelCaption"
                onChange={(e) => {
                  setLabelCaption(e.target.value);
                }}
                _placeholder={{ color: "white" }}
                _hover={{
                  boxShadow: "none",
                  borderColor: "none",
                }}
                _focus={{
                  boxShadow: "none",
                  borderColor: "none",
                }}
              />
            </AlertDialogBody>

            <AlertDialogFooter
              mt="2"
              borderTopWidth={"1px"}
              borderTopColor={defaultGreyColor}
            >
              <Button
                size="sm"
                colorScheme="red"
                ref={cancelRef}
                onClick={onModalTwoClose}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="green"
                fontWeight={"600"}
                onClick={(e) => {
                  editResourceLabel();
                }}
                ml={3}
                isDisabled={
                  !labelPrompt?.length || !labelCaption?.length ? true : false
                }
                isLoading={editResourceLoading ? true : false}
              >
                Update
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default ObjectMetadataPanel;
