// Chakra Imports
import React, { useState } from "react";
import { FaMagic } from "react-icons/fa";
import { Button, Icon, Tooltip, useToast} from "@chakra-ui/react";

// Api Imports
import { fetchPromptEnhanceData } from "../../../services/promptServices";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";

function PromptEnhance({ promptTitleData, sendPromptDetails }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const fetchPromptEnhance = (data) => {
    setLoading(true);
    const obj = {
      prompt: [data],
      max_new_tokens: 10,
    };
    fetchPromptEnhanceData(obj)
      .then((res) => {
        if (res?.data[0]?.data?.length) {
          sendPromptDetails(res?.data[0]?.data[0]);
        }
      })
      .catch(() => {
        toast(toastFunctionToaster("Something Went Wrong...", "error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Tooltip label="Magic Prompt Enhancer">
      <Button
        colorScheme="blue"
        isDisabled={!promptTitleData?.length}
        isLoading={loading}
        mr="2"
        size="sm"
        onClick={() => {
          fetchPromptEnhance(promptTitleData)
        }}
      >
        <Icon as={FaMagic}/>
      </Button>
    </Tooltip>
  );
}

export default PromptEnhance;
