import React, { useEffect, useState, useRef } from "react";
import { Box, Text, Grid, GridItem, Flex, Spinner } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import CardFlip from "../../PreviousImageConfig/CardFlip";
import { setSelectedImageType } from "../../../../../../store/actions/workFlowAction";

function PreviousGeneratedImage(props) {
  const dispatch = useDispatch();
  const workflowDetails = useSelector((store) => store?.workflowDesignHeader);
  const previousDataArr =
    workflowDetails?.workflowObject?.previousGeneratedData;
  const cardFlipStatus = props?.flipCardStatus;
  const [innerIndexData, setInnerIndexData] = useState(null);
  const [outerIndexData, setOuterIndexData] = useState(null);

  const outerRefs = useRef([]);
  const innerRefs = useRef({});

  // Todo: Make Image Keydown Event
  useEffect(() => {
    if (
      outerRefs.current[outerIndexData] &&
      innerRefs.current[outerIndexData]?.[innerIndexData]
    ) {
      innerRefs.current[outerIndexData][innerIndexData].focus();
    }
  }, [innerIndexData, outerIndexData]);

  useEffect(() => {
    if (workflowDetails?.selectedImageType !== "previous") {
      setInnerIndexData(null);
      setOuterIndexData(null);
    }
  }, [workflowDetails?.selectedImageType]);

  const renderCardFlip = () => {
    const newArray = previousDataArr?.slice(
      workflowDetails?.workflowImageData?.length ? 1 : 0
    );
    return newArray?.length
      ? newArray?.map((data, outerIndex) => {
          return renderImages(data, outerIndex);
        })
      : null;
  };

  // Todo:Merge Json and Image into an array and render the image
  const renderImages = (data, outerIndex) => {
    let imageJsonData = [];
    data?.images?.map((subData) => {
      imageJsonData.push(subData);
    });

    const groupedArrays = imageJsonData.reduce(
      (result, currentObject, index, array) => {
        if (index % 2 === 0) {
          result.push([...array.slice(index, index + 2)]);
        }
        return result;
      },
      []
    );

    // Todo:Received Data From Like Component > Card Flip Component
    const resetAfterLikeImage = (value) => {
      if (value === "true") {
        renderCardFlip();
      }
    };

    // Callback function to receive data from Card Flip For SourceImage
    const receiveImageWithIndexValue = (data) => {
      props?.passImageData(data);
      dispatch(setSelectedImageType("previous"));
    };

    // Todo:Handle Border Color
    const handleBorderColor = (innerIndex, outerIndex) => {
      setInnerIndexData(innerIndex);
      setOuterIndexData(outerIndex);
    };

    // Todo:Key Board Control Events
    const handleKeyDown = (event, index, outerIndex) => {
      if (event.key === "ArrowRight") {
        const newIndex = index + 1;
        if (newIndex < groupedArrays.length) {
          updateImageDetails(newIndex, outerIndex);
        }
      } else if (event.key === "ArrowLeft") {
        const newIndex = index - 1;
        if (newIndex >= 0) {
          updateImageDetails(newIndex, outerIndex);
        }
      }
    };

    // Update Data When KeyPressed Down
    const updateImageDetails = (newIndex, outerIndex) => {
      setInnerIndexData(newIndex);
      setOuterIndexData(outerIndex);
      let objBody = {
        imageUrl: groupedArrays[newIndex]?.[0]?.url,
        imageObjName: groupedArrays[newIndex]?.[0]?.object_name,
        indexValue: newIndex,
        workflowCounter:
          previousDataArr[
            outerIndex + (workflowDetails?.workflowImageData?.length ? 1 : 0)
          ]?.workflowCounter,
      };
      receiveImageWithIndexValue(objBody);
    };

    return (
      <Box borderRadius="10px" w="100%" key={outerIndex}>
        <Flex
          overflow="auto"
          overflowY="hidden"
          p="3"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "4px",
              height: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#fff",
              borderRadius: "10px",
            },
          }}
          cursor="pointer"
          ref={(el) => (outerRefs.current[outerIndex] = el)}
        >
          {groupedArrays?.map((data, index) => (
            <Box
              mr="3"
              onClick={() => {
                handleBorderColor(index, outerIndex);
              }}
              borderWidth={
                innerIndexData === index && outerIndexData === outerIndex
                  ? "3px"
                  : null
              }
              borderColor={
                innerIndexData === index && outerIndexData === outerIndex
                  ? "green.500"
                  : null
              }
              key={index}
              tabIndex={0} //Todo:Make Ref Focused When Changed
              ref={(el) => {
                if (!innerRefs.current[outerIndex]) {
                  innerRefs.current[outerIndex] = [];
                }
                innerRefs.current[outerIndex][index] = el;
              }}
              onKeyDown={(e) => {
                handleKeyDown(e, index, outerIndex);
              }}
              outline={"0"}
            >
              <CardFlip
                height="200px"
                width="100%"
                key={outerIndex + index}
                frontImage={data}
                likeRating={[]}
                cardFlipStatus={cardFlipStatus}
                passImageIndexValue={receiveImageWithIndexValue}
                indexValue={index}
                workflowDetails={workflowDetails}
                workflowCounter={
                  previousDataArr[
                    outerIndex +
                      (workflowDetails?.workflowImageData?.length ? 1 : 0)
                  ]?.workflowCounter
                }
                sendDataToPrevImageComp={resetAfterLikeImage}
                imageJsonData={previousDataArr}
              />
            </Box>
          ))}
        </Flex>
      </Box>
    );
  };

  return <Box>{renderCardFlip()}</Box>;
}

export default PreviousGeneratedImage;
