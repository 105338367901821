import { Rnd } from "react-rnd";
import { Image, Flex } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdRotate90DegreesCcw } from "react-icons/md";
import { defaultThemeColor } from "../../../constants";
import { FaRegCircle } from "react-icons/fa";

function MaskedImageWrapper({
  url,
  opacity,
  drawMaskMode,
  canvasHeight,
  canvasWidth,
  sourceHeight,
  sourceWidth,
  rotateImage,
  lockAspectRatio,
}) {
  const dispatch = useDispatch();
  const rndRef = useRef(null);
  const workflowDetails = useSelector((store) => store?.workflowDesignHeader);
  const workflowDetailData = workflowDetails?.workflowObject;
  const [showRotateIcon, setShowRotateIcon] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(rotateImage);
  const [prevMouseX, setPrevMouseX] = useState(null);
  const [prevMouseY, setPrevMouseY] = useState(null);

  canvasHeight = canvasHeight > 100 ? canvasHeight : null;

  useEffect(() => {
    setRotationAngle(rotateImage);
  }, [rotateImage]);

  useEffect(() => {
    if (!workflowDetailData?.controlBoundingBox) {
      const right = Math.round(
        Math.min(
          sourceWidth,
          workflowDetailData?.controlAspectRatio * sourceHeight
        )
      );
      const bottom = Math.round(
        Math.min(
          sourceHeight,
          sourceWidth / workflowDetailData?.controlAspectRatio
        )
      );
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "controlBoundingBox",
          value: [0, 0, right, bottom],
        },
      });
    }
  }, [workflowDetailData?.controlAspectRatio]);

  useEffect(() => {
    if (rndRef.current && workflowDetailData?.controlBoundingBox) {
      const box = workflowDetailData?.controlBoundingBox;
      const rndCoords = {
        x: (box[0] * canvasWidth) / sourceWidth,
        y: (box[1] * canvasHeight) / sourceHeight,
        width: ((box[2] - box[0]) * canvasWidth) / sourceWidth,
        height: ((box[3] - box[1]) * canvasHeight) / sourceHeight,
      };
      rndRef.current.updatePosition({ x: rndCoords.x, y: rndCoords.y });
      rndRef.current.updateSize({
        width: rndCoords.width,
        height: rndCoords.height,
      });
    }
  }, [workflowDetailData?.controlBoundingBox, canvasWidth, canvasHeight]);

  // Todo:Set Flag value true when move the rotate icon with previous data
  const handleRotateStart = (e) => {
    setIsRotating(true);
    setPrevMouseX(e.clientX);
    setPrevMouseY(e.clientY);
  };

  // Todo:Reset Value
  const handleRotateEnd = () => {
    setIsRotating(false);
    setPrevMouseX(null);
    setPrevMouseY(null);
  };

  const handleRotate = (e) => {
    if (isRotating) {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const deltaX = mouseX - prevMouseX;
      const deltaY = mouseY - prevMouseY;
      const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
      const newRotationAngle = rotationAngle + angle;
      setPrevMouseX(mouseX);
      setPrevMouseY(mouseY);
      const smoothingFactor = 0.2;
      setRotationAngle(
        (prevRotationAngle) =>
          prevRotationAngle +
          (newRotationAngle - prevRotationAngle) * smoothingFactor
      );
    }
  };

  const handleImageClick = () => {
    setShowRotateIcon(true);
  };

  const handleOutsideClick = () => {
    setShowRotateIcon(false);
  };

  return (
    <>
      {canvasHeight && canvasWidth && (
        <>
          <Rnd
            ref={rndRef}
            minWidth={32}
            minHeight={32}
            width={canvasWidth}
            height={canvasHeight}
            lockAspectRatio={lockAspectRatio}
            resizeHandleComponent={
              drawMaskMode
                ? null
                : {
                    topRight: <FaRegCircle fontSize="20px" opacity={opacity} />,
                    bottomRight: (
                      <FaRegCircle fontSize="20px" opacity={opacity} />
                    ),
                    bottomLeft: (
                      <FaRegCircle fontSize="20px" opacity={opacity} />
                    ),
                    topLeft: <FaRegCircle fontSize="20px" opacity={opacity} />,
                  }
            }
            onDragStop={(e, d) => {
              const boundingBox = workflowDetailData?.controlBoundingBox;
              const left = Math.max(
                32 - (boundingBox[2] - boundingBox[0]),
                Math.min(
                  sourceWidth - 32,
                  Math.round((d.x * sourceWidth) / canvasWidth)
                )
              );
              const top = Math.max(
                32 - (boundingBox[3] - boundingBox[1]),
                Math.min(
                  sourceHeight - 32,
                  Math.round((d.y * sourceHeight) / canvasHeight)
                )
              );
              const right = left + boundingBox[2] - boundingBox[0];
              const bottom = top + boundingBox[3] - boundingBox[1];
              dispatch({
                type: "SET_WORKFLOW_OBJECT",
                payload: {
                  key: "controlBoundingBox",
                  value: [left, top, right, bottom],
                },
              });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              const left = Math.max(
                32 -
                  Math.round(
                    (parseInt(ref.style.width) * sourceWidth) / canvasWidth
                  ),
                Math.min(
                  sourceWidth - 32,
                  Math.round((position.x * sourceWidth) / canvasWidth)
                )
              );
              const top = Math.max(
                32 -
                  Math.round(
                    (parseInt(ref.style.height) * sourceHeight) / canvasHeight
                  ),
                Math.min(
                  sourceHeight - 32,
                  Math.round((position.y * sourceHeight) / canvasHeight)
                )
              );
              const right =
                left +
                Math.round(
                  (parseInt(ref.style.width) * sourceWidth) / canvasWidth
                );
              const bottom =
                top +
                Math.round(
                  (parseInt(ref.style.height) * sourceHeight) / canvasHeight
                );
              dispatch({
                type: "SET_WORKFLOW_OBJECT",
                payload: {
                  key: "controlBoundingBox",
                  value: [left, top, right, bottom],
                },
              });
            }}
            onDragStart={(e, data) => {
              if (isRotating) {
                e.preventDefault();
              }
            }}
          >
            <Flex
              position={"absolute"}
              top="-60px"
              zIndex="9"
              justifyContent="center"
              w="100%"
              onClick={handleOutsideClick}
            >
              {showRotateIcon && (
                <div
                  style={{
                    background: "#000",
                    cursor: "crosshair",
                    transition: "transform 0.1s ease-in-out",
                    transform: `rotate(${rotationAngle}deg)`,
                    transformOrigin: "center",
                  }}
                  onMouseDown={handleRotateStart}
                  onMouseUp={handleRotateEnd}
                  onMouseMove={handleRotate}
                >
                  <MdRotate90DegreesCcw
                    color={defaultThemeColor}
                    fontSize={"24px"}
                  />
                </div>
              )}
            </Flex>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
              onClick={handleImageClick}
            >
              <Image
                alt="Guidance Image"
                src={url}
                opacity={opacity}
                userSelect="none"
                userdrag="none"
                pointerEvents="none"
                style={{
                  width: "100%",
                  height: "100%",
                  transition: "transform 0.1s ease-in-out",
                  transform: `rotate(${rotationAngle}deg)`,
                  transformOrigin: "center",
                }}
              />
            </div>
          </Rnd>
        </>
      )}
    </>
  );
}

export default MaskedImageWrapper;
