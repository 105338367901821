import React from "react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import RouteComponent from "./routes/index";
import store from "./store/index";
import "../src/style.css";

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <RouteComponent />
      </ChakraProvider>
    </Provider>
  );
}

export default App;
