import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { AiOutlineDownload } from "react-icons/ai";
import { defaultThemeColor } from "../../../../../constants";

function DownloadImage(props) {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (props?.imageUrl) {
      setImageUrl(props?.imageUrl);
    }
  }, [props]);

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();

    // Todo:Download Image Error => Returning Cors Error
    // fetch(imageUrl)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(blob);
    //     reader.onloadend = () => {
    //       const base64data = reader.result;

    //       const link = document.createElement("a");
    //       link.href = base64data;
    //       link.download = "image.png";

    //       link.click();
    //     };
    //   })
    //   .catch((error) => console.error("Error fetching image:", error));
  };

  return (
    <Box
      position={props?.position ? props?.position : "absolute"}
      right={props?.right ? props?.right : "30px"}
      top="1px"
      borderRadius={"50px"}
    >
      <AiOutlineDownload
        size="20px"
        cursor="pointer"
        target="_blank"
        onClick={() => {
          downloadImage();
        }}
        color={defaultThemeColor}
      />
    </Box>
  );
}

export default DownloadImage;
