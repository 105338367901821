// Chakra Imports
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineFullscreen } from "react-icons/ai";
import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import LazyLoad from "react-lazyload";

// Component Imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { defaultThemeColor } from "../../../../../../constants";

function PopupImage({ fontSize, imageData, imageUrl, indexValue, position }) {
  const popupSliderRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalWidth, setModalWidth] = useState(0);

  const handleKeyDown = (e) => {
    if (!popupSliderRef.current) return;
    if (e.key === "ArrowLeft") {
      popupSliderRef.current.slickPrev();
    } else if (e.key === "ArrowRight") {
      popupSliderRef.current.slickNext();
    }
  };

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: imageData?.length, // Disable arrows until all images are loaded
    dots: imageData?.length, // Disable dots until all images are loaded
    draggable: imageData?.length, // Disable dragging until all images are loaded
    swipe: imageData?.length, // Disable swipping until all images are loaded
    initialSlide: indexValue,
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleImageLoad = (e) => {
    setModalWidth(e.target.naturalWidth);
  };

  return (
    <Box>
      <Box
        position={position ? position : "absolute"}
        right={position ? "0" : "5px"}
        top={position ? "0" : "1px"}
        borderRadius={"50px"}
      >
        <AiOutlineFullscreen
          size={fontSize ? fontSize : "25px"}
          cursor="pointer"
          onClick={onOpen}
          color={defaultThemeColor}
        />
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        style={{ width: "100% !important" }}
      >
        <ModalOverlay />
        <ModalContent maxW={"80%"} width={modalWidth} mt="5" bg="#0b090c">
          <ModalCloseButton
            color="red.500"
            fontWeight={"600"}
            bg="#fff"
            top="0"
            right="0"
            zIndex="999"
          />
          {imageData ? (
            <Slider ref={popupSliderRef} {...sliderSettings}>
              {imageData?.map((data, index) => (
                <LazyLoad key={index}>
                  <Image
                    src={data?.url}
                    alt="Image"
                    objectFit="contain"
                    height="95vh"
                    bg="#0b090c"
                    width="100%"
                    onLoad={handleImageLoad}
                  />
                </LazyLoad>
              ))}
            </Slider>
          ) : (
            <LazyLoad height={"100%"} width={"100%"} offset={100}>
              <Image
                src={imageUrl}
                objectFit={"scale-down"}
                onLoad={(e) => {
                  setModalWidth(e.target.naturalWidth);
                }}
              />
            </LazyLoad>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default PopupImage;
