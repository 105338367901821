import React from 'react';
import {
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Input,
  Grid,
  GridItem,
  Button,
} from '@chakra-ui/react';
import { defaultThemeColor } from '../../../constants';

function AddForm() {
  return (
    <Box>
      <Tabs>
        <TabList
          color="#d7d7d7"
          borderBottomWidth="0.5px"
          borderBottomColor="#494552"
        >
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomWidth: '0.5px',
              borderBottomColor: { defaultThemeColor },
              marginBottom: '-1px',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Notebooks
          </Tab>
          <Tab
            fontSize="12px"
            _selected={{
              color: '#fff',
              borderBottomWidth: '0.5px',
              borderBottomColor: { defaultThemeColor },
              marginBottom: '-1px',
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Firewall
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p="0">
            <Grid templateColumns="repeat(3, 1fr)" gap={5} p="3">
              <GridItem colSpan={1}>
                <Text fontSize="12px" mb="10px" color="#d7d7d7">
                  SECURE CONNECTIONS
                </Text>
                <Text color="#d7d7d7">
                  Secure outbound access to all notebooks
                </Text>
              </GridItem>
              <GridItem colStart={2} colEnd={4} color="#fff">
                <Box>
                  <Text mb="3">FQDN Allowlist</Text>
                  <Box mb="15px">
                    <Text mb="8px">pyip.org: </Text>
                    <Input
                      placeholder="files.pythonhosted.org"
                      size="sm"
                      borderRadius="0px"
                      fontSize="12px"
                      borderColor="#494552"
                      bg="#1c181e"
                    />
                  </Box>
                  <Box mb="15px">
                    <Text mb="8px">*.amazonaws.com: </Text>
                    <Input
                      placeholder="**.amazonaws.com"
                      size="sm"
                      borderRadius="0px"
                      fontSize="12px"
                      borderColor="#494552"
                      bg="#1c181e"
                    />
                  </Box>
                  <Box mb="15px">
                    <Text mb="8px">**..amazonaws.com: </Text>
                    <Input
                      placeholder="**.mongodb.net"
                      size="sm"
                      borderRadius="0px"
                      fontSize="12px"
                      borderColor="#494552"
                      bg="#1c181e"
                    />
                  </Box>
                  <Box mb="15px">
                    <Text mb="8px">github.com: </Text>
                    <Input
                      placeholder="raw.githubusercontent.com"
                      size="sm"
                      borderRadius="0px"
                      fontSize="12px"
                      borderColor="#494552"
                      bg="#1c181e"
                    />
                  </Box>
                  <Box mb="15px">
                    <Text mb="8px">gitlab.com: </Text>
                    <Input
                      placeholder="openaipublic.blob.core.windows.net"
                      size="sm"
                      borderRadius="0px"
                      fontSize="12px"
                      borderColor="#494552"
                      bg="#1c181e"
                    />
                  </Box>
                  <Box mb="15px">
                    <Text mb="8px">openai.com: </Text>
                    <Input
                      placeholder="huggingphase.co"
                      size="sm"
                      borderRadius="0px"
                      fontSize="12px"
                      borderColor="#494552"
                      bg="#1c181e"
                    />
                  </Box>
                  <Box mb="15px">
                    <Button
                      size="xs"
                      variant="outline"
                      fontWeight="normal"
                      fontSize="10px"
                      borderColor="#494552"
                    >
                      Save{' '}
                    </Button>
                  </Box>
                </Box>
              </GridItem>
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default AddForm;
