import axios from "./axios";
import { serviceUrls } from "./serviceUrls";

export const fetchResourcesListingServices = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getResourceList, data, config);
};

export const createResourceTemplate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addResourceTemplate, data, config);
};

export const createBulkObjectUpload = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().createBulkObjectUpload, data, config);
};

export const updateResourceTemplateDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateResourceContent, data, config);
};

export const updateResourceEntityStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().manageResourceStatus, data, config);
};

export const getResourceEntity = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getResourceContent, data, config);
};

export const fetchObjectUploadUrl = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getObjectUploadUrl, data, config);
};

export const listResourceObjects = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().listResourceObjects, data, config);
};

export const listResourceFolders = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().listResourceFolders, data, config);
};

export const listResourceBulkObjectUploads = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().listResourceBulkObjectUploads,
    data,
    config
  );
};

export const listObjectsInFolders = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().listFolderObjects, data, config);
};

export const countObjectsInFolders = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().countFolderObjects, data, config);
};

export const resourceLabellerRequest = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().labelerStartRequest, data, config);
};

export const viewResourceObject = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().viewResourceObject, data, config);
};

export const deleteResourceObject = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().deleteResourceObject, data, config);
};

export const fetchObjectMedata = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getObjectMetadata, data, config);
};

export const updateObjectMedata = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateObjectMetadata, data, config);
};

export const searchImageResource = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().searchImageResource, data, config);
};

export const bulkImageUpload = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().uploadBulkImages, data, config);
};

export const resourceStatistics = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().resourceStatistics, data, config);
};
