import {
  setSelectedImage,
  setImposeFlagData,
  setLineDrawFlag,
  setSelectedImageType,
  setTabFlag,
  setWorkFlowImageData,
  setWorkflowImgCounter,
  setStabilityValue,
} from "../store/actions/workFlowAction";

export const resetWorkflowData = (dispatch) => {
  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "workflow_name",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "resource_uuid",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "project_uuid",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "resource_uuid",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "workflow_uuid",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "source_image",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "reference_image",
      value: ["", "", "", "", ""],
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "reference_name",
      value: ["", "", "", "", ""],
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "control_guidance_image",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "control_guidance_image_name",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "modelAdapterName",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "modelAdapterWeight",
      value: 1,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "inferenceSteps",
      value: 4,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "guidanceScale",
      value: 1.5,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "guidanceType",
      value: "canny",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "conditionScale",
      value: 0.8,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "inpaintStrength",
      value: 1.0,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "maskCropPadding",
      value: 0.6,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "refAdainScale",
      value: 0.5,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "refAttnScale",
      value: 0.5,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "imageHeight",
      value: 1024,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "imageWidth",
      value: 1024,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "imageSeed",
      value: null,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "object_name",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "promptData",
      value: ["", "", "", "", ""],
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "negativePrompt",
      value: "worst quality, lowres, warped, distorted, cropped, blurry, ugly",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "negativeRefImage",
      value: "",
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "maskData",
      value: [],
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "controlBoundingBox",
      value: null,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "controlAspectRatio",
      value: 1,
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "previousGeneratedData",
      value: [],
    },
  });

  dispatch({
    type: "SET_WORKFLOW_OBJECT",
    payload: {
      key: "generatedImageCount",
      value: 1,
    },
  });

  dispatch(setWorkFlowImageData([]));
  dispatch(setImposeFlagData(false));
  dispatch(setWorkflowImgCounter(null));
  dispatch(setSelectedImageType(""));
  dispatch(setLineDrawFlag(false));
  dispatch(setTabFlag(0));
  dispatch(setSelectedImage(""));
  dispatch(setStabilityValue(false));
};
