// Chakra Imports
import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ResponsivePagination from "react-responsive-pagination";
import {
  Img,
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Drawer,
  Spinner,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Text,
  Grid,
  GridItem,
  useToast,
  VStack,
} from "@chakra-ui/react";

// API Imports
import { searchImageResource } from "../../../../../../services/resourceTemplateService";

// Component Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";

// Constant Imports
import { defaultGreyColor } from "../../../../../../constants";

function ImageFromResource(props) {
  const toast = useToast();
  const btnRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, isLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [imageSearchValue, setImageSearchValue] = useState([]);
  const resourceId = props?.resourceId?.length ? props.resourceId : "";

  const searchSelectedResource = (searchQuery, page) => {
    let obj = {
      resource_uuid: resourceId,
      image_tags: [searchQuery],
      query: [searchQuery],
      rerank_results: false,
      page_id: page,
      records_per_page: 10,
    };
    isLoading(true);
    searchImageResource(obj)
      .then((res) => {
        isLoading(false);
        if (res?.data[0]?.length) {
          setImageSearchValue(res?.data[0]);
          setTotalPages(Math.max(totalPages, page + 1));
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          setTotalPages(Math.max(1, page - 1));
          toast(toastFunctionToaster("No Results", "success"));
        }
      })
      .catch((err) => {
        isLoading(false);
        toast(toastFunctionToaster(err, "error"));
      });
  };

  const renderImageData = imageSearchValue?.length
    ? imageSearchValue?.map((data, index) => {
        return (
          <GridItem
            key={index}
            w="100%"
            h="150px"
            borderRadius={"5px"}
            cursor={"pointer"}
            onClick={() => {
              setImageDetails(data, props?.objectRole);
            }}
          >
            <Img
              h="100%"
              w="100%"
              objectFit={"contain"}
              src={data?.payload?.url}
              borderRadius={"5px"}
            />
          </GridItem>
        );
      })
    : null;

  const setImageDetails = (imageDetails, objectRole) => {
    if (props?.clickEvent) {
      return false;
    } else {
      props?.passResourceDetails(imageDetails, objectRole);
    }
  };

  // Todo:Reset Fields
  const onCloseModal = () => {
    onClose();
    setSearchValue("");
    setCurrentPage(1);
    setTotalPages(1);
    setImageSearchValue([]);
  };
  return (
    <Box>
      <Button
        position={props?.position ? props?.position : "absolute"}
        left={props?.position ? "none" : "50%"}
        top={props?.position ? "none" : "75%"}
        transform={props?.position ? "none" : "translate(-50%, -50%)"}
        zIndex={999}
        size={props?.size ? props?.size : "sm"}
        ref={btnRef}
        colorScheme={props?.color ? props?.color : "teal"}
        onClick={onOpen}
      >
        {props?.title ? props?.title : "Image From Resource"}
      </Button>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          onCloseModal();
        }}
        finalFocusRef={btnRef}
        size={props?.drawerSize ? props?.drawerSize : "md"}
      >
        <DrawerOverlay />
        <DrawerContent
          bg={"#131415"}
          borderWidth={"1px"}
          borderColor={"#1a202c"}
        >
          <DrawerCloseButton
            fontSize={15}
            color="red.500"
            borderWidth={"1px"}
            borderColor={"red.500"}
          />
          <DrawerHeader borderBottomWidth={"1px"} borderColor={"#1a202c"}>
            {props?.objectRole ? (
              <Text color={"#fff"} fontWeight={"500"} fontSize={"18px"}>
                Select{" "}
                {props?.objectRole === "controlGuidedImage"
                  ? "Guidance"
                  : "Reference"}{" "}
                Image From Resource
              </Text>
            ) : null}
          </DrawerHeader>

          <DrawerBody>
            <Box mb="5">
              {loading ? (
                <Flex alignItems="center" justifyContent="center" mt="10">
                  <Spinner size="xl" />
                </Flex>
              ) : (
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  {renderImageData}
                </Grid>
              )}
            </Box>
          </DrawerBody>

          <DrawerFooter borderTopWidth={"1px"} borderColor={"#1a202c"}>
            <VStack w="100%">
              <Flex
                justifyContent="space-between"
                alignItems={"center"}
                w="100%"
              >
                <InputGroup borderColor={defaultGreyColor} w="80%">
                  <InputLeftElement pointerEvents="none">
                    <AiOutlineSearch color="white" />
                  </InputLeftElement>
                  <Input
                    type="text"
                    color="#fff"
                    placeholder="Search Images"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        searchSelectedResource(searchValue, currentPage);
                      }
                    }}
                    isDisabled={loading ? true : false}
                  />
                </InputGroup>
                <Button
                  cursor={"pointer"}
                  colorScheme="yellow"
                  size="sm"
                  onClick={(e) => {
                    searchSelectedResource(searchValue, currentPage);
                  }}
                  isDisabled={loading ? true : false}
                  isLoading={loading ? true : false}
                >
                  Search
                </Button>
              </Flex>
              <Box
                justifyContent="space-between"
                alignItems={"center"}
                w="100%"
              >
                {imageSearchValue?.length && !loading ? (
                  <Box mt="4">
                    <ResponsivePagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={(pageNumber) => {
                        setCurrentPage(pageNumber);
                        searchSelectedResource(searchValue, pageNumber);
                      }}
                    />
                  </Box>
                ) : null}
              </Box>
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default ImageFromResource;
