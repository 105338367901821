import React, { useState, useEffect, useRef } from "react";
import { FaEdit, FaPaintBrush } from "react-icons/fa";
import {
  Flex,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Excalidraw } from "@excalidraw/excalidraw";
import { greyBgColor } from "../../constants";
import { externalCreateComment, externalUpdateComment } from "../../services/sharedServices";
import { createShareSpaceComment, updateShareSpaceComment } from "../../services/businessServices";
import { toastFunctionToaster } from "../../utils/toastFunction";

const ExcaliComp = ({
  type,
  sharedSpaceId,
  email,
  code,
  sharedContentData,
  name,
  parentSharedContentId,
  handleSpaceData,
}) => {
  const [elements, setElements] = useState(
    sharedContentData?.info?.length
      ? JSON.parse(sharedContentData?.info)
      : []
  );
  const [saveLoading, setsaveLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Create a ref for the canvas to observe
  const canvasRef = useRef(null);

  async function storeExcalidrawData() {
    setsaveLoading(true);
    let response = null;
    try {
      if (sharedContentData?.shared_content_uuid?.length) {
        if (type === "external") {
          const params = {
            shared_space_key: sharedSpaceId,
            user_email: email,
            password: code,
          };
          let obj = {
            shared_content_uuid: sharedContentData?.shared_content_uuid,
            comment_text: JSON.stringify(elements),
          };
          response = await externalUpdateComment(params, obj);
        } else {
          let objData = {
            shared_content_uuid: sharedContentData?.shared_content_uuid,
            comment_text: JSON.stringify(elements),
          };
          response = await updateShareSpaceComment(objData);
        }
      } else {
        if (type === "external") {
          const params = {
            shared_space_key: sharedSpaceId,
            user_email: email,
            password: code,
          };
          let obj = {
            comment_text: JSON.stringify(elements),
            comment_type: "drawing",
          };
          if (parentSharedContentId) {
            obj.parent_shared_content_uuid = parentSharedContentId;
          }
          response = await externalCreateComment(params, obj);
        } else {
          let objData = {
            shared_space_uuid: sharedSpaceId,
            comment_text: JSON.stringify(elements),
            comment_type: "drawing",
          };
          if (parentSharedContentId) {
            objData.parent_shared_content_uuid = parentSharedContentId;
          }
          response = await createShareSpaceComment(objData);
        }
      }
    } catch (err) {
      toast(toastFunctionToaster(err?.message, "error"));
    }

    if (response?.result) {
      toast(toastFunctionToaster(response?.message, "success"));
      handleSpaceData();
    } else {
      toast(toastFunctionToaster(response?.message, "error"));
    }
    setsaveLoading(false);
  };


  const onChange = (newState) => {
    setElements(newState);
  };

  const exileDraw = () => (
    <Excalidraw initialData={{ elements }} onChange={onChange} theme="dark" />
  );

  return (
    <>
      {sharedContentData?.info?.length ? (
        <>
          <div style={{ marginBottom: "10px" }}>
            <Button colorScheme="purple" my="2" size="xs" onClick={onOpen}>
              Maximize
            </Button>
            <Button
              colorScheme="yellow"
              my="2"
              ml="1"
              size="xs"
              rightIcon={<FaEdit />}
              onClick={storeExcalidrawData}
              isLoading={saveLoading}
              isDisabled={saveLoading}
            >
              Edit Drawing
            </Button>
          </div>
          {/* Excalidraw Editor in normal view */}
          {!isOpen && (
            <Box
              style={{ height: "400px" }}
              borderWidth={"1px"}
              borderStyle={"dotted"}
              borderColor={greyBgColor}
              borderRadius={"10px"}
              padding={"5px"}
              overflow="auto"
            >
              {exileDraw()}
            </Box>
          )}
        </>
      ) : (
        <Button
          colorScheme="blue"
          ml="1"
          size="xs"
          rightIcon={<FaPaintBrush />}
          onClick={onOpen}
        >
          {name}
        </Button>
      )}

      {/* Fullscreen Modal for Excalidraw */}
      <Modal isOpen={isOpen} onClose={onClose} overflow={"hidden"}>
        <ModalOverlay />
        <ModalContent
          maxWidth={`${window.innerWidth * 0.9}px`}
          maxHeight={`${window.innerHeight * 0.9}px`}
          overflow={"hidden"}
        >
          <ModalBody
            p="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Flex
              ref={canvasRef}
              bg={"#121212"}
              alignItems="center"
              justifyContent="center"
              w="100%"
              h="90vh" // Make sure it occupies the full height of the viewport
            >
              {exileDraw()}
            </Flex>
          </ModalBody>
          <Button
            colorScheme="red"
            position="absolute"
            top="15px"
            right="10px"
            size="xs"
            zIndex={10}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            colorScheme={sharedContentData?.info?.length ? "yellow" : "green"}
            position="absolute"
            top="15px"
            right="90px"
            size="xs"
            zIndex={10}
            onClick={storeExcalidrawData}
            isLoading={saveLoading}
            isDisabled={saveLoading}
          >
            {sharedContentData?.info?.length ? "Edit Drawing" : "Save Drawing"}
          </Button>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExcaliComp;
