import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Tooltip,
  Modal,
  Spinner,
  useDisclosure,
  useToast,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import ResponsivePagination from "react-responsive-pagination";

import { AiFillCalendar, AiOutlineShareAlt } from "react-icons/ai";
import { defaultThemeColor, greyBgColor } from "../../../../../constants";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import { addShareSpaceContent, fetchShareSpaceData } from "../../../../../services/businessServices";

function SharedSpaceWorkflow({ projectId, objectKey, fontSize }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sharedData, setSharedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sharedLoader, setSharedLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Todo:Fetch Share Space List
  const fetchShareSpaceDetails = () => {
    setSharedLoader(true);
    let obj = {
      project_uuid: projectId,
      filter_enabled: true,
      records_per_page: 10,
      page_id: currentPage,
    };
    fetchShareSpaceData(obj)
      .then((res) => {
        if (res?.data?.length) {
          setSharedData(res?.data[0]?.records);
          setTotalPages(res?.data[0]?.total_pages);
        } else {
          setSharedData([]);
          setTotalPages(0);
        }
      })
      .catch((err) => {
        if (err) {
          toast(toastFunctionToaster(err?.message, "error"));
        }
      })
      .finally(() => {
        setSharedLoader(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      fetchShareSpaceDetails();
    }
  }, [currentPage, isOpen]);

  const uploadImageToWorkspace = (data) => {
    setLoading(true);
    let obj = {
      shared_space_uuid: data?.shared_space_uuid,
      object_key: objectKey,
      // parent_shared_content_uuid: "",
    };
    addShareSpaceContent(obj)
      .then((res) => {
        if (res?.result) {
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        if (err) {
          toast(toastFunctionToaster(err?.message, "error"));
        }
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  // Todo:Render Share Space List
  const renderSharedSpaceData = () => {
    if (sharedData && sharedData.length > 0) {
      return sharedData.map((data, index) => (
        <Flex
          key={data?.shared_space_uuid}
          color="#fff"
          p="10px"
          borderBottomWidth={"1px"}
          borderBottomColor={greyBgColor}
          cursor={"pointer"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Flex alignItems={"center"} justifyContent={"space-between"} w="100%">
            <Text fontSize={"14px"} w="150px" mb="0">
              {data?.name}
            </Text>
            <Flex alignItems={"center"} ml="2">
              <Flex alignItems={"center"}>
                <AiFillCalendar fontSize={"14px"} />
                <Text fontSize={"14px"} as="span" ml="1">
                  {data?.started || "-"}
                </Text>
              </Flex>
            </Flex>
            <Button
              colorScheme="green"
              size="xs"
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
              onClick={() => {
                uploadImageToWorkspace(data);
              }}
            >
              Add Image To Shared Space
            </Button>
          </Flex>
        </Flex>
      ));
    } else {
      return (
        <Text bg={greyBgColor} color="#fff" p="2">
          No Shared Spaces Found
        </Text>
      );
    }
  };

  return (
    <>
      <Tooltip label="Share" placement="top">
        <Box>
          <AiOutlineShareAlt
            color={defaultThemeColor}
            size={fontSize || "30px"}
            cursor="pointer"
            onClick={() => {
              onOpen();
            }}
          />
        </Box>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg={greyBgColor}>
          <ModalHeader pb="0" color={"#fff"}>
            {" "}
            Select Workflow To Share Image
          </ModalHeader>
          <ModalCloseButton color="red" />
          <ModalBody>
            <Flex bg={"#151117"} borderRadius={"10px"}>
              <Box w={"100%"}>
                <Box
                  bg={"#151117"}
                  borderRadius={"20px"}
                  h="50vh"
                  overflow="auto"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: { greyBgColor },
                      borderRadius: "24px",
                    },
                  }}
                >
                  <>
                    {sharedLoader ? (
                      <Flex
                        alignItems="center"
                        h="50vh"
                        color={"#fff"}
                        justifyContent="center"
                        mt="10"
                      >
                        <Spinner size="xl" />
                      </Flex>
                    ) : (
                      <>{renderSharedSpaceData()}</>
                    )}
                  </>
                </Box>
                {totalPages > 1 ? (
                  <Box my="4">
                    <ResponsivePagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={(pageNumber) => {
                        setCurrentPage(pageNumber);
                      }}
                    />
                  </Box>
                ) : null}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SharedSpaceWorkflow;
