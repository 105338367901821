import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { getGroupId } from '../utils/localStorageIndex';
import Unauthorized from '../components/Unauthorized/Unauthorized';
import { userRole } from '../constants/index';

export default function AdminProtectedLayout() {
  const group_id = getGroupId();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    userRole.map(subData => {
      if (subData.roleName === 'Admin') {
        if (subData.group.includes(group_id)) {
          setIsAuthenticated(true);
        }
      }
    });
  }, [location]);

  return <>{isAuthenticated ? <Outlet /> : <Unauthorized />}</>;
}
