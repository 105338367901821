// Chakra React Imports
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  Grid,
  Button,
  GridItem,
  HStack,
  Spinner,
  InputGroup,
  Input,
  Menu,
  MenuList,
  MenuButton,
  InputLeftElement,
} from "@chakra-ui/react";

// Component Imports
import { BsGrid, BsList } from "react-icons/bs";
import { BiDotsVerticalRounded } from "react-icons/bi";
import "react-responsive-pagination/themes/classic.css";
import AddResourceTemplate from "./AddResourceTemplate";
import demoSofa from "../../../image/demo-sofa-image-1.jpg";
import { AiFillEdit, AiOutlineSearch } from "react-icons/ai";
import {
  borderBottomColor,
  defaultThemeColor,
  themeFontSizeOddHeading,
  themeFontSizeSmallHeading,
} from "../../../constants/index";

// Api Services Imports
import { fetchResourcesListingServices } from "../../../services/resourceTemplateService";

function ResourcesListing() {
  const navigate = useNavigate();
  const recordsPerPage = 10;
  const [loading, isLoading] = useState(true);
  const [onlyEnabledFilter, isOnlyEnabledFilter] = useState(true);
  const [viewUpdateFlag, setViewUpdateFlag] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTemplateData, setSelectedTemplateData] = useState({});
  const [fullResourcesDetails, setFullResourcesDetails] = useState([]);
  const [filterResourceName, setFilterResourceName] = useState("");
  const [filteredResourcesDetails, setFilteredResourcesDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [gridColumn, setGridColumn] = useState(4);
  const emptyResourcesErr =
    'You haven\'t created any resources yet. Please create a new resource by selecting "Create New Resource".';

  useEffect(() => {
    onPageLoadResourcesList();
  }, [onlyEnabledFilter, currentPage]);

  // Fetch Resource Details
  const onPageLoadResourcesList = () => {
    let objBody = {
      searchKey: "",
      searchValue: "",
      filterEnabled: String(onlyEnabledFilter),
      records_per_page: recordsPerPage,
      page_id: currentPage,
    };
    isLoading(true);
    fetchResourcesListingServices(objBody)
      .then((res) => {
        isLoading(false);
        if (res?.data[0]?.total_records > 0) {
          setFullResourcesDetails(res.data[0].records);
          setFilteredResourcesDetails(res?.data[0]?.records);
          setTotalPages(res.data[0].total_pages);
        } else {
          setErrorMessage(emptyResourcesErr);
          setFullResourcesDetails([]);
        }
      })
      .catch((err) => {
        isLoading(false);
        setFullResourcesDetails([]);
      });
  };

  // Todo:Filter Search Data From Resource Details
  const filterResourceDetails = () => {
    let cloneFullResourcesDetails = [...fullResourcesDetails];
    let filteredData = [];
    if (filterResourceName?.length) {
      filteredData = cloneFullResourcesDetails.filter((item) =>
        item?.resource_name
          ?.toLowerCase()
          .includes(filterResourceName.toLowerCase())
      );
    } else {
      filteredData = cloneFullResourcesDetails;
    }
    setFilteredResourcesDetails(filteredData);
  };

  useEffect(() => {
    filterResourceDetails();
  }, [filterResourceName]);

  // Render Resources Listing
  const renderFullResourcesList = () => {
    if (filteredResourcesDetails && filteredResourcesDetails.length > 0) {
      var resourcesListResult = filteredResourcesDetails.map((data) => (
        <GridItem
          key={data.project_uuid}
          display={gridColumn === 4 ? "block" : "flex"}
          alignItems="center"
          justifyContent="space-between"
          background={gridColumn === 4 ? null : "#151117"}
          mb="2"
        >
          <Box
            p="10px"
            bgColor={gridColumn === 4 ? "#151117" : null}
            _hover={{ cursor: "pointer" }}
            h={gridColumn === 4 ? "20vh" : "100px"}
            w={gridColumn === 4 ? "100%" : "200px"}
            borderTopLeftRadius={gridColumn === 4 ? "10px" : "0"}
            borderTopRightRadius={gridColumn === 4 ? "10px" : "0"}
            bgImage={
              data?.resource_cover?.length
                ? data?.resource_cover[0]?.url
                : demoSofa
            }
            bgSize={"cover"}
            bgRepeat={"no-repeat"}
            onClick={() =>
              navigate("resources", {
                state: {
                  resource_uuid: data.resource_uuid,
                  gridColumn: gridColumn,
                },
              })
            }
          ></Box>
          <Flex
            w="100%"
            justifyContent={gridColumn === 4 ? "space-between" : "flex-end"}
            bg="#151117"
            alignItems={"center"}
            borderBottomLeftRadius={"10px"}
            borderBottomRightRadius={"10px"}
          >
            <Text
              fontSize={themeFontSizeSmallHeading}
              fontWeight="600"
              color={"#fff"}
              borderBottomLeftRadius={"10px"}
              borderBottomRightRadius={"10px"}
              p="2"
            >
              {data.resource_name ? data.resource_name : "-"}
            </Text>
            {/* {gridColumn !== 4 ? ( */}
            <Text textAlign w="auto" mr="2">
              <Button
                size="xs"
                colorScheme="yellow"
                borderRadius="0px"
                fontWeight="normal"
                fontSize={themeFontSizeOddHeading}
                rounded={"md"}
                onClick={() => {
                  setSelectedTemplateData(data);
                  setViewUpdateFlag("editResourceTemplate");
                }}
              >
                <Icon w={3} h={3} as={AiFillEdit}></Icon>
              </Button>
            </Text>
            {/* ) : null} */}
          </Flex>
        </GridItem>
      ));
      return resourcesListResult;
    } else {
      return (
        <Box
          border="1px"
          borderRadius="10px"
          bg="#151117"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          p="10"
        >
          <Text
            color={defaultThemeColor}
            fontWeight="bold"
            textAlign={"center"}
          >
            {errorMessage ? errorMessage : "No Resources Found"}
          </Text>
        </Box>
      );
    }
  };

  // Todo:Reset Project data if popup-closed
  const resetUpdatedProjFormData = (data) => {
    if (data === true) {
      setSelectedTemplateData({});
      setViewUpdateFlag("");
    }
    if (data === "renderProjData") {
      setSelectedTemplateData({});
      setViewUpdateFlag("");
      onPageLoadResourcesList();
    }
  };

  return (
    <Box>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottomWidth={"1px"}
        borderColor={borderBottomColor}
        mb="2"
        pb="4"
      >
        <Heading color="#fff" fontWeight="600" fontSize="24px">
          Resources
        </Heading>
        <Box>
          <Menu as={Box} position="relative" zIndex={10}>
            <MenuButton
              position={"absolute"}
              right="10"
              top="5"
              bg={"white"}
              color="#000"
              fontSize={"20px"}
              borderRadius={"10px"}
              fontWeight={"500"}
              zIndex={1}
            >
              <BiDotsVerticalRounded />
            </MenuButton>
            <MenuList
              bg={"#fff"}
              border="none"
              minW="100px !important"
              position="absolute"
              left="-90px"
            >
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                px="2"
              >
                <Button
                  size="sm"
                  borderRadius={"0px"}
                  bg={gridColumn === 4 ? { defaultThemeColor } : "#ffff"}
                  fontSize={"20px"}
                  onClick={() => {
                    setGridColumn(4);
                  }}
                >
                  <Icon as={BsGrid} />
                </Button>

                <Button
                  size="sm"
                  borderRadius={"0px"}
                  bg={gridColumn === 1 ? { defaultThemeColor } : "#ffff"}
                  fontSize={"20px"}
                  onClick={() => {
                    setGridColumn(1);
                  }}
                >
                  <Icon as={BsList} />
                </Button>
              </Flex>
            </MenuList>
          </Menu>
        </Box>
        <AddResourceTemplate
          sendEditableData={selectedTemplateData}
          resetEditableData={resetUpdatedProjFormData}
          resourceEditViewFlag={viewUpdateFlag}
        />
      </Flex>
      <Flex>
        {loading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            mt="10"
            h="50vh"
            w="100%"
          >
            <Spinner size="xl" color="#fff" />
          </Flex>
        ) : (
          <>
            <Box w={"100%"}>
              <Flex alignItems={"center"}>
                <InputGroup border="none">
                  <InputLeftElement pointerEvents="none">
                    <AiOutlineSearch color="#fff" />
                  </InputLeftElement>
                  <Input
                    type="text"
                    color="#fff"
                    placeholder="Search Resources By Name"
                    _hover={{ boxShadow: "none" }}
                    onChange={(e) => {
                      setFilterResourceName(e.target.value);
                    }}
                    background={"#151117"}
                    border="none"
                    w="50%"
                  />
                </InputGroup>
              </Flex>
              <Box
                w={"100%"}
                my="5"
                h="calc(100vh - 160px)"
                overflow="auto"
                overflowX="hidden"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#fff",
                    borderRadius: "24px",
                  },
                }}
              >
                <Grid templateColumns={`repeat(${gridColumn}, 1fr)`} gap={2}>
                  {renderFullResourcesList()}
                </Grid>
              </Box>
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}

export default ResourcesListing;
