// React Imports
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCloseSquare } from "react-icons/ai";
import { Button, Box, VStack, Center, Image } from "@chakra-ui/react";

// Component Imports
import LikeImage from "../../LikeImage/LikeImage";
import { greyBgColor } from "../../../../../../constants";
import ObjectUploader from "../../../../Playground/ObjectUploader";
import PopupImageModal from "../../../../Playground/PopupImageModal";
import SharedSpaceWorkflow from "../../PreviousImageConfig/SharedSpaceWorkflow.js";
import ImageFromResource from "../../ProjectWrokspace/BaseConfig/ImageFromResource";
import { setupReferenceObject } from "../../../../../../services/projectTemplateService";
import CompareImage from "./CompareImage.tsx";
import { resetWorkflowData } from "../../../../../../utils/resetWorkflowData.js";
import { setLineDrawFlag, setTabFlag } from "../../../../../../store/actions/workFlowAction.js";
import AddNewWorkflow from "../../AddNewWorkflow/AddNewWorkflow.js";

function SourceImage({
  currentImage,
  resetImage,
  imageIndexValue,
  workflowCounter,
  imageObjectName,
}) {
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const [divWidth, setDivWidth] = useState(0);

  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const workflowDetailsData = workflowDetails?.workflowObject;
  const sourceImage =
    workflowDetailsData?.modelCode === 1
      ? `https://dummyimage.com/${workflowDetailsData?.imageWidth}x${workflowDetailsData?.imageHeight}/fff/fff.png`
      : workflowDetailsData?.source_image?.length
      ? workflowDetailsData?.source_image
      : "";
  const createObject = { resource_uuid: workflowDetailsData?.resource_uuid };

  const updateResourceImageDetails = (data, object_role) => {
    let obj = {
      key: data?.payload?.object_name,
    };
    SupplementObjectHandler(obj, object_role);
  };

  const SupplementObjectHandler = (data, object_role) => {
    if (data !== null || data !== undefined) {
      let objBody = {object_info: data.key};
      setupReferenceObject(objBody)
        .then((res) => {
          if (res?.data[0]?.object[0]?.url.length) {
            if (object_role === "source_image") {
              dispatch({
                type: "SET_WORKFLOW_OBJECT",
                payload: {
                  key: "source_image",
                  value: res?.data[0]?.object[0]?.url,
                },
              });
              dispatch({
                type: "SET_WORKFLOW_OBJECT",
                payload: {
                  key: "object_name",
                  value: res?.data[0]?.object[0]?.object_name,
                },
              });
            }
          }
        })
        .catch((err) => {
          // Show an error here...
        });
    }
  };

  useEffect(() => {
    // Update div width after the component mounts and whenever the window resizes
    const updateDivWidth = () => {
      if (divRef.current) {
        setDivWidth(divRef.current.getBoundingClientRect().width);
      }
    };

    updateDivWidth();

    window.addEventListener("resize", updateDivWidth);
    return () => {
      window.removeEventListener("resize", updateDivWidth);
    };
  }, []);

  useEffect(() => {
    if (divRef?.current) {
      setDivWidth(divRef?.current?.getBoundingClientRect()?.width);
    }
  }, [sourceImage]);

  return (
    <>
      {sourceImage?.length === 0 ? (
        <VStack
          w={"100%"}
          mt={2}
          rounded={"lg"}
          bg={greyBgColor}
          borderRadius={"10px"}
        >
          <Center w={"100%"} h={"100%"} color="#fff">
            <VStack w={"100%"} position="relative">
              <Box w={"100%"}>
                {workflowDetailsData?.modelCode === 4 ? (
                  <Box>
                    <Button
                      mt={2}
                      position={"absolute"}
                      left={"50%"}
                      top={"10%"}
                      transform={"translate(-50%, -50%)"}
                      zIndex={999}
                      size={"sm"}
                      colorScheme={"yellow"}
                      onClick={(e) => {
                        dispatch(setLineDrawFlag(true));
                        dispatch(setTabFlag(1));
                      }}
                    >
                      Line Draw
                    </Button>
                  </Box>
                ) : null}
                <ObjectUploader
                  createObject={createObject}
                  objectUploaded={(data) =>
                    SupplementObjectHandler(data, "source_image")
                  }
                  clipImage={workflowDetails?.copyToClip}
                  showCropButton={"true"}
                />
                <ImageFromResource
                  objectRole={"source_image"}
                  passResourceDetails={updateResourceImageDetails}
                  resourceId={workflowDetailsData?.resource_uuid}
                />
              </Box>
            </VStack>
          </Center>
        </VStack>
      ) : (
        <VStack
          w={"100%"}
          align={"center"}
          position="relative"
          h="100%"
          ref={divRef}
        >
          <PopupImageModal
            imageUrl={sourceImage}
            objectName={workflowDetailsData?.object_name}
            showIcons={!currentImage?.length}
          />

          {currentImage?.length ? (
            <>
              {workflowDetailsData?.modelCode > 1 ? (
                <CompareImage
                  controlImage={
                    workflowDetails?.superImposeFlag &&
                    workflowDetailsData?.control_guidance_image
                      ? workflowDetailsData?.control_guidance_image
                      : null
                  }
                  controlImageBox={
                    workflowDetails?.superImposeFlag &&
                    workflowDetailsData?.control_guidance_image &&
                    workflowDetailsData?.controlBoundingBox &&
                    workflowDetailsData?.controlBoundingBox?.length === 4
                      ? workflowDetailsData?.controlBoundingBox
                      : null
                  }
                  ref={imageRef}
                  leftImage={sourceImage}
                  rightImage={currentImage}
                  parentDivWidth={divWidth}
                />
              ) : workflowDetailsData?.modelCode === 1 ? (
                <Box
                  position="absolute"
                  top="0"
                  right="0"
                  w="100%"
                  h="90%"
                  bg={greyBgColor}
                  zIndex={"20"}
                >
                  <Image
                    src={currentImage}
                    w="100%"
                    h="100%"
                    objectFit={"contain"}
                  />
                </Box>
              ) : null}
              <Box
                position="absolute"
                top="0"
                right="0"
                bg={greyBgColor}
                border="none"
                width="128px"
                height="30px"
                zIndex={"20"}
              >
                <Box position="absolute" top="0" right="24" zIndex={"21"}>
                  <AddNewWorkflow
                    projectId={workflowDetailsData?.project_uuid}
                    resourceId={workflowDetailsData?.resource_uuid}
                    imageWorkflowId={workflowDetailsData?.workflow_uuid}
                    imageWorkflowName={workflowDetailsData?.workflow_name}
                    imageIndexValue={imageIndexValue}
                    workflowCounter={workflowCounter}
                    objData={{object_name: imageObjectName, url: currentImage}}
                    resetData={() => {
                      resetImage();
                      resetWorkflowData(dispatch);
                    }}
                    menuRight="-30px"
                  />
                </Box>
                <Box position="absolute" top="0" right="16" zIndex={"21"}>
                  <LikeImage
                    projectId={workflowDetailsData?.project_uuid}
                    imageWorkflowId={workflowDetailsData?.workflow_uuid}
                    imageIndexValue={imageIndexValue}
                    workflowCounter={workflowCounter}
                    sendDataToCardFlip={(value) => {}}
                  />
                </Box>
                <Box position="absolute" top="0" right="8" zIndex={"21"}>
                  <SharedSpaceWorkflow
                    projectId={workflowDetailsData?.project_uuid}
                    objectKey={imageObjectName}
                  />
                </Box>
                <Box
                  position="absolute"
                  top="0"
                  right="0"
                  fontSize={"30px"}
                  zIndex={"21"}
                  onClick={() => {
                    resetImage();
                  }}
                >
                  <AiFillCloseSquare color="red" cursor="pointer" />
                </Box>
              </Box>
            </>
          ) : null}
        </VStack>
      )}
    </>
  );
}

export default SourceImage;
