import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Icon, HStack } from '@chakra-ui/react';
import {
  themeColor,
  borderBottomColor,
  defaultThemeColor,
  
} from '../../constants/index';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { AiFillSetting, AiOutlineCodeSandbox } from 'react-icons/ai';

function NestedRoutes(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    notificationContentData,
    headerArrData,
    breadCrumTitle,
    split,
    notificationTextData,
  } = props;
  const headerArr = headerArrData;
  const [routeValue, setRouteValue] = useState('');
  const notificationContent = notificationContentData;

  useEffect(() => {
    const pathUrlName = pathname.split('/')[split];
    const paramValue = headerArrData.find(
      headerData => headerData.value === pathUrlName
    );
    setRouteValue(paramValue.label);
  }, [pathname]);

  const notificationContentUI = notificationContent?.length ? (
    notificationContent.map((data, index) => (
      <Flex key={index} alignItems="center" mr="5" color="#fff">
        <Text
          height="25px"
          width="25px"
          textAlign="center"
          bg={themeColor}
          mr="4"
          pt="4px"
          fontSize="12px"
          borderRadius="50px"
        >
          {data.number}
        </Text>
        <Text color="#fff">{data.label}</Text>
      </Flex>
    ))
  ) : (
    <Text color="#fff">{notificationTextData}</Text>
  );

  const renderTitleHeader = headerArr?.map((headerData, index) => (
    <Text
      color={routeValue === headerData.label ? themeColor : 'white'}
      fontWeight={routeValue === headerData.label ? 'bold' : 'normal'}
      mr="5"
      fontSize={16}
      cursor="pointer"
      key={headerData.value + index}
      borderBottom={routeValue === headerData.label ? '1px' : null}
      borderColor={defaultThemeColor}
      p={2}
      onClick={() => {
        setUserRoute(headerData);
      }}
    >
      {headerData.label}
    </Text>
  ));

  const setUserRoute = headerData => {
    navigate(`${headerData.value}`);
    setRouteValue(headerData.label);
  };

  return (
    <Box>
      {/* <Box
        p="10px"
        borderBottomWidth="0.5px"
        borderBottomColor={borderBottomColor}
      >
        <Flex alignItems="center" mr="6">
          {notificationContentUI}
        </Flex>
      </Box> */}
      <Box
        p="10px"
        borderBottomWidth="0.5px"
        borderBottomColor={borderBottomColor}
      >
        <HStack>
            <Icon
              w={8}
              h={8}
              color={'#ee7733'}
              as={AiOutlineCodeSandbox}
              mr="10px"
            ></Icon>
            <Text mr="2" color="white" fontSize={18}>
              {breadCrumTitle} &gt;
              <Text as="span" ml="2" color={themeColor} fontWeight="bold">
                {routeValue}
              </Text>
            </Text>
            </HStack>
      </Box>
      <Box>
        <Flex borderBottomWidth="0.5px" borderBottomColor={borderBottomColor}>
          {renderTitleHeader}
        </Flex>
        <Box color="white">
          <Outlet></Outlet>
        </Box>
      </Box>
    </Box>
  );
}

export default NestedRoutes;
