// Chakra Imports
import { AiOutlineSearch } from "react-icons/ai";
import React, { useEffect, useRef, useState, } from "react";
import {
  Box,
  useToast,
  Button,
  Flex,
  Image,
  Select,
  Input,
  Text,
  Drawer,
  IconButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";

// Api Import
import {
  fetchPromptDetails,
} from "../../../../../../services/promptServices";

// Component Import
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { greyBgColor } from "../../../../../../constants";
import {
  convertCocolibRLEToBasicRLE,
  loadBasicRLEToRawData,
} from "../../../ImageWithMasksUtils";

function PromptSearch(props) {
  const toast = useToast();
  const btnRef = useRef();
  const [loading, isLoading] = useState(false);
  const [promptData, setPromptData] = useState([]);
  const [filteredGroupName, setFilteredGroupName] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [promptSearchName, setPromptSearchName] = useState("");
  const [groupValue, selectedGroupValue] = useState("");

  useEffect(() => {
    promptDetails();
  }, []);

  //   Todo:Fetch Prompt Data List
  const promptDetails = () => {
    isLoading(true);
    let obj = {
      records_per_page: 100,
      page_id: 1,
    };
    fetchPromptDetails(obj)
      .then((res) => {
        if (res?.data[0]?.records?.length) {
          setPromptData(res?.data[0]?.records);
          setFilteredGroupName(res?.data[0]?.records); //Todo:To maintain dropdown data
        } else {
          setPromptData([]);
          setFilteredGroupName([]);
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const RenderPromptDetails = ({ data }) => {
    const promptMaskData = data?.prompt_mask
      ? JSON.parse(data?.prompt_mask)
      : null;
    const [imageHeight, setImageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [showMask, setShowMask] = useState(false);
    const divRef = useRef(null);

    return (
      <Box
        borderWidth={"0.5px"}
        p="2"
        mb="3"
        borderRadius={"5px"}
        borderColor={"#585858"}
      >
        <Flex w="100%">
          <Box style={{ height: "100px", width: "fit-content", position: "relative" }}>
            {data?.prompt_example_image?.[0]?.url ? (
              <Image
                h="100%"
                w="100%"
                objectFit={"contain"}
                src={data?.prompt_example_image?.[0]?.url}
                style={{
                  opacity: showMask ? "0" : "1.0",
                  userSelect: "none",
                  userdrag: "none",
                  pointerEvents: "none",
                }}
                ref={divRef}
                onLoad={(e) => {
                  if (divRef.current) {
                    const { width, height } = (
                      divRef.current.getBoundingClientRect()
                    );
                    setImageHeight(height);
                    setImageWidth(width);
                  }
                }}
              />
            ) : null}
            {promptMaskData ? (
              <canvas
                height={`${promptMaskData?.size[0]}px`}
                width={`${promptMaskData?.size[1]}px`}
                ref={(element) => {
                  if (element) {
                    loadBasicRLEToRawData(
                      convertCocolibRLEToBasicRLE(
                        promptMaskData?.counts
                      ),
                      Math.round(promptMaskData?.size[0]),
                      Math.round(promptMaskData?.size[1]),
                      element.getContext("2d")
                    );
                  }
                }}
                style={{
                  width: `${imageWidth}px`,
                  height: `${imageHeight}px`,
                  opacity: showMask ? "1.0" : "0.5",
                  objectFit: "contain",
                  position: "absolute",
                  left: "0",
                  top: "0",
                  zIndex: "0",
                  userSelect: "none",
                  userdrag: "none",
                  pointerEvents: "none",
                }}
              />
            ) : null}
          </Box>
          <Box ml={data?.prompt_example_image?.[0]?.url ? 3 : 0} w="100%">
            <Flex justifyContent={"space-between"} w="100%">
              <Text color="#fff" fontSize={"16px"} fontWeight={"bold"}>
                {data?.prompt_name}{" "}
                <Text as="span" fontSize={"14px"}>
                  ({data?.prompt_group})
                </Text>
              </Text>
              <Button
                size="xs"
                colorScheme="green"
                onClick={() => {
                  passPromptData(data);
                }}
              >
                Apply
              </Button>
            </Flex>
            <Flex justifyContent={"space-between"} w="100%">
              <Text color="#fff" fontSize={"12px"} w="80%">
                {data?.prompt_text}{" "}
              </Text>
              {data?.prompt_example_image?.[0]?.url && promptMaskData && 
                <Button
                  mt="2"
                  size="xs"
                  colorScheme="blue"
                  onClick={() => {
                    setShowMask(!showMask);
                  }}
                >
                  {showMask ? "Show Image" : "Show Mask"}
                </Button>
              }
            </Flex>
          </Box>
        </Flex>
      </Box>
    );
  }

  // Todo:Pass selected prompt value to Base Config Component
  const passPromptData = (data) => {
    props?.sendPromptDetails(
      data?.prompt_text, JSON.parse(data?.prompt_mask)
    );
    onClose();
  };

  // Filter Prompt Data
  const renderGroupList = () => {
    if (filteredGroupName?.length) {
      const uniquePromptGroups = [
        ...new Set(filteredGroupName.map((item) => item.prompt_group)),
      ];
      return uniquePromptGroups?.map((value, index) => (
        <option key={index} color="#000" value={value}>
          {value}
        </option>
      ));
    }
  };

  // Todo:Filter prompt Api call
  const filterPromptData = () => {
    isLoading(true);
    let obj = {
      records_per_page: 100,
      page_id: 1
    };
    if (groupValue?.length) {
      obj.filter_group = groupValue;
    }
    if (promptSearchName?.length) {
      obj.filter_name = promptSearchName;
    }
    fetchPromptDetails(obj)
      .then((res) => {
        isLoading(false);
        if (res?.result) {
          toast(toastFunctionToaster(res?.message, "success"));
          setPromptData(res?.data[0]?.records);
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
          setPromptData([]);
        }
      })
      .catch((err) => {
        isLoading(false);
        toast(toastFunctionToaster(err?.message, "error"));
      });
  };
  return (
    <Box>
      <Tooltip placement="top-start" label="Search Prompt Library">
        <IconButton
          colorScheme="yellow"
          size="sm"
          icon={<AiOutlineSearch />}
          onClick={() => {
            onOpen();
          }}
        />
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          onClose();
          promptDetails();
          selectedGroupValue("");
        }}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent bg={greyBgColor}>
          <DrawerCloseButton color="#585858" />
          <DrawerHeader color="#fff" fontSize="18px">
            Prompt Library
          </DrawerHeader>
          <DrawerBody
            p="3"
            borderTopWidth={"1px"}
            borderBottomWidth={"1px"}
            borderColor={"#585858"}
            h="calc(100vh - 200px)"
            overflow="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#fff",
                borderRadius: "24px",
              },
            }}
          >
            {promptData?.length
              ? promptData?.map((data, index) => (
                <RenderPromptDetails key={index} data={data} />
              ))
              : null
            }
          </DrawerBody>
          <DrawerFooter>
            <Flex alignItems="center" w="100%" justifyContent={"space-between"}>
              <Select
                w="38%"
                border="none"
                bg="#151117"
                color="#606c7f"
                fontSize="14px"
                borderRadius={"10px"}
                value={groupValue}
                onChange={(e) => {
                  selectedGroupValue(e.target.value);
                }}
                _focus={{ boxShadow: "none" }}
                isDisabled={loading}
              >
                <option key="" color="#000" value="">
                  Select Group
                </option>
                {renderGroupList()}
              </Select>
              <Input
                type="text"
                size="sm"
                mr="2"
                w="38%"
                color="#fff"
                borderRadius={"5px"}
                placeholder="Enter Prompt Details..."
                value={promptSearchName}
                onChange={(e) => {
                  setPromptSearchName(e.target.value);
                }}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    filterPromptData();
                  }
                }}
                _focus={{ boxShadow: "none" }}
                isDisabled={loading}
              ></Input>
              <Button
                colorScheme="yellow"
                size="sm"
                w="20%"
                onClick={() => {
                  filterPromptData();
                }}
                isDisabled={loading}
                isLoading={loading}
              >
                Search
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default PromptSearch;
