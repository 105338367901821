import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
} from "@chakra-ui/react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from "browser-image-compression";
import { defaultGreyColor, greyBgColor } from "../../../constants";

function ImageCompressorModal({
  handleSubmitNew,
  imageHeight,
  imageWidth,
  isModalOpen,
  onModalClose,
  uploadedImage,
  imageLoading,
  cropImageStatus,
}) {
  const [loading, setLoading] = useState(false);
  const [cropImageFlag, setCropImageFlag] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [cropLoader, setCropLoader] = useState(imageLoading);

  useEffect(() => {
    setCropImageFlag(cropImageStatus || false);
  }, [cropImageStatus]);

  useEffect(() => {
    setCropLoader(imageLoading);
  }, [imageLoading]);

  const compressImageSize = async () => {
    if (uploadedImage) {
      const options = {
        maxWidthOrHeight: 2048,
        useWebWorker: true,
        onProgress: setProgressPercentage,
      };
      setLoading(true);
      try {
        const compressedFile = await imageCompression(uploadedImage, options);
        await handleSubmitNew(compressedFile);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setProgressPercentage(0);
        onModalClose();
      }
    }
  };

  const base64ToFile = (base64Data, fileName, fileType) => {
    return fetch(base64Data)
      .then((res) => res.blob())
      .then((blob) => new File([blob], fileName, { type: fileType }));
  };

  const handleCropImageClick = async () => {
    if (cropper) {
      try {
        const croppedCanvas = cropper.getCroppedCanvas();
        const croppedImageDataURL = croppedCanvas.toDataURL("image/jpeg");
        const fileName = uploadedImage.name;
        const fileType = uploadedImage.type;
        const croppedFile = await base64ToFile(
          croppedImageDataURL,
          fileName,
          fileType
        );

        setCroppedImage(croppedImageDataURL);
        await handleSubmitNew(croppedFile);
      } catch (error) {
        console.error("Error cropping image:", error);
      }
    }
  };

  const handleImageUpload = useCallback(() => {
    if (uploadedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(uploadedImage);
    }
  }, [uploadedImage]);

  useEffect(() => {
    if (uploadedImage) {
      handleImageUpload();
    }
  }, [uploadedImage, handleImageUpload]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isModalOpen}
      onClose={onModalClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent bg={greyBgColor}>
        <ModalHeader
          mb="3"
          color="#fff"
          borderBottomWidth={"1px"}
          borderBottomColor={defaultGreyColor}
        >
          Image is too large, do you want to resize it before uploading?
        </ModalHeader>
        <ModalBody borderColor={defaultGreyColor}>
          {loading ? (
            <>
              <Flex justifyContent={"space-between"}>
                <Text color="#fff">Resizing Image...</Text>
                <Text color="green.500">{progressPercentage}% Complete</Text>
              </Flex>
              <Progress
                value={progressPercentage}
                colorScheme="green"
                size="md"
              />
            </>
          ) : (
            <Flex alignItems="center" justifyContent={"space-between"}>
              <Text color="#fff" my="3">
                Current Image Size: {imageWidth} x {imageHeight} px
              </Text>
            </Flex>
          )}
          {cropImageFlag && (
            <>
              <Box position="relative" mb="3" background="#494552">
                <Cropper
                  src={imageSrc}
                  style={{ height: "400", width: "100%" }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  guides={true}
                  onInitialized={(instance) => setCropper(instance)}
                />
              </Box>
              <Button
                position="absolute"
                bottom={"10px"}
                size="xs"
                onClick={handleCropImageClick}
                zIndex={"999"}
                colorScheme="green"
                isDisabled={cropLoader ? true : false}
                isLoading={cropLoader ? true : false}
              >
                Crop Image
              </Button>
              {cropImageFlag ? (
                <Button
                  left={"85px"}
                  top={"-2px"}
                  size="xs"
                  colorScheme="red"
                  onClick={onModalClose}
                >
                  Cancel Crop
                </Button>
              ) : null}
            </>
          )}
        </ModalBody>
        {!cropImageFlag ? (
          <ModalFooter
            mt="4"
            borderTopWidth={"1px"}
            borderTopColor={defaultGreyColor}
          >
            <Flex>
              <Button
                colorScheme="green"
                size="sm"
                mr="3"
                onClick={compressImageSize}
                isLoading={imageLoading}
                isDisabled={imageLoading}
              >
                Yes
              </Button>
              <Button
                colorScheme="red"
                size="sm"
                onClick={onModalClose}
                isDisabled={loading}
              >
                No
              </Button>
            </Flex>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
}

export default ImageCompressorModal;
