// React Imports
import { AiOutlinePlus } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import {
  Image,
  Box,
  Text,
  Icon,
  Flex,
  Switch,
  Input,
  Button,
  Drawer,
  Select,
  useToast,
  FormLabel,
  DrawerBody,
  FormControl,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  VStack,
} from "@chakra-ui/react";

// Component Imports
import { setDeseignProjectObj } from "../../../store/actions/workFlowAction";
import {
  borderBottomColor,
  errorColor,
  greyBgColor,
  themeFontSizeMediumHeading,
} from "../../../constants";

// Api Imports
import { fetchResourcesListingServices } from "../../../services/resourceTemplateService";
import {
  createProjectTemplate,
  updateProjectEntityStatus,
  updateProjectTemplateDetails,
} from "../../../services/projectTemplateService";

function AddProjectTemplate(props) {
  const toast = useToast();
  const btnRef = React.useRef();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projectTypeChoice, setProjectTypeChoice] = useState("Commercial");
  const [resourceType, setResourceType] = useState("");
  const [resourceList, setResourceList] = useState([]);
  const [projectStatus, setProjectStatus] = useState(false);
  const [projectCustomType, setProjectCustomType] = useState("");
  const [customTypeErr, setCustomTypeErr] = useState(false);
  const [projTemplateForm, setProjTemplateForm] = useState({
    project_name: "",
    project_type: "",
    project_details: "",
    project_requirements: "",
  });
  const projectEditViewFlag = props.projectEditViewFlag;
  const projTitle =
    projectEditViewFlag === "editProjTemplate"
      ? "Edit"
      : projectEditViewFlag === "viewProjTemplate"
      ? "View"
      : "Add";

  useEffect(() => {
    fetchResourceList();
  }, []);

  const fetchResourceList = () => {
    let objBody = {
      searchKey: "",
      searchValue: "",
      filterEnabled: String(true),
      records_per_page: 1000,
      page_id: 1,
    };
    isLoading(true);
    fetchResourcesListingServices(objBody)
      .then((res) => {
        isLoading(false);
        if (res?.data[0]?.total_records > 0) {
          setResourceList(res.data[0].records);
        } else {
        }
      })
      .catch((err) => {
        isLoading(false);
      });
  };

  useEffect(() => {
    if (projectEditViewFlag.length) {
      onOpen();
      setProjTemplateForm(props.sendEditableData);
      setProjectStatus(props?.sendEditableData?.project_status);
      setResourceType(
        props?.sendEditableData?.project_resources[0]?.resource_uuid
      );
      projectType(props?.sendEditableData?.project_type);
    }
  }, [props?.projectEditViewFlag]);

  // Todo:Set Project Type
  const projectType = (choice) => {
    if (choice === "Commercial") {
      setProjectTypeChoice(props?.sendEditableData?.project_type);
    } else {
      setProjectTypeChoice("custom");
      setProjectCustomType(props?.sendEditableData?.project_type);
    }
  };

  //   Set form fields
  const onUpdateField = (e) => {
    const nextFormState = {
      ...projTemplateForm,
      [e.target.name]: e.target.value,
    };
    setProjTemplateForm(nextFormState);
  };

  //   Todo:Form validation for title and Type
  const validateFieldForm = () => {
    let fields = projTemplateForm;
    let errors = {};
    let formIsValid = true;

    if (!fields["project_name"]) {
      formIsValid = false;
      errors["project_name"] = "Project title is required.";
    }

    if (!projectTypeChoice?.trim()) {
      formIsValid = false;
      errors["project_type"] = "*Project Type is required.";
    }

    if (
      projectTypeChoice === "custom" &&
      (!projectCustomType || projectCustomType.trim().length === 0)
    ) {
      formIsValid = false;
      setCustomTypeErr("*Enter Custom Project Type.");
    } else {
      setCustomTypeErr("");
    }

    if (!resourceType?.trim()) {
      formIsValid = false;
      errors["resourceType"] = "*Resource is required.";
    }

    setError(errors);
    return formIsValid;
  };

  //   Todo:Submit Project details data
  const createProjectTemplateData = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      projTemplateForm["project_type"] =
        projectTypeChoice === "custom" ? projectCustomType : projectTypeChoice;
      projTemplateForm["resource_uuid"] = resourceType;
      createProjectTemplate(projTemplateForm)
        .then((res) => {
          isLoading(false);
          if (res.result) {
            onClose();
            resetUpdatedProjDetails("renderProjData");
            toastFunction(res.message, "success");
          } else {
            isLoading(false);
            toastFunction(res.message, "error");
          }
        })
        .catch((err) => {
          isLoading(false);
          if (err) {
            toastFunction(err.message, "success");
          }
        });
    }
  };

  //   Todo:Update Project Details
  const updateProjectTemplateForm = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      delete projTemplateForm["project_resources"];
      projTemplateForm["project_type"] =
        projectTypeChoice === "custom" ? projectCustomType : projectTypeChoice;
      projTemplateForm["resource_uuid"] = resourceType;
      updateProjectTemplateDetails(projTemplateForm)
        .then((res) => {
          isLoading(false);
          if (res.status) {
            resetUpdatedProjDetails("renderProjData");
            toastFunction(res.message, "success");
          } else {
            toastFunction(res.message, "error");
          }
        })
        .catch((err) => {
          isLoading(false);
          toastFunction(err?.message, "error");
        });
    }
  };

  const resetUpdatedProjDetails = (data, renderFlag) => {
    onClose();
    props.resetEditableData(data, renderFlag);
  };

  //   Todo:Reset Form
  const resetFormValue = () => {
    onOpen();
    setProjTemplateForm({
      project_name: "",
      project_type: "",
      project_details: "",
      project_requirements: "",
    });
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  // Todo Update Project Status
  const updateProjectStatus = () => {
    let objData = {
      project_uuid: props?.sendEditableData.project_uuid,
      project_status: String(!projectStatus),
    };
    setProjectStatus(!projectStatus);
    updateProjectEntityStatus(objData)
      .then((res) => {
        if (res.status) {
          toastFunction(res.message, "success");
          resetUpdatedProjDetails("renderProjData");
        } else {
          toastFunction(res.message, "error");
        }
      })
      .catch((err) => {
        toastFunction(err?.message, "error");
      });
  };

  // Todo:Render Resource List
  const renderResourceList = () => {
    if (resourceList?.length) {
      return resourceList?.map((data, index) => (
        <option
          style={{ color: "black" }}
          value={data?.resource_uuid}
          key={index}
        >
          {data?.resource_name}
        </option>
      ));
    }
  };

  // Todo:Render Design Project Selected Image
  const renderProjectImage = () => {
    if (props?.sendEditableData?.project_cover?.[0]?.url) {
      return (
        <Box mb={3}>
          <Text mb="2">Project Image</Text>
          <Image
            borderRadius={"5px"}
            src={props?.sendEditableData?.project_cover[0]?.url}
          ></Image>
        </Box>
      );
    } else {
      return (
        <Flex
          mb="3"
          borderWidth={"1px"}
          borderRadius={"5px"}
          alignItems={"center"}
          borderStyle="dotted"
          justifyContent={"center"}
          h="200px"
          cursor={"pointer"}
          bg="#1c181e"
        >
          <Text fontWeight={600}>Upload Project Image</Text>
        </Flex>
      );
    }
  };

  return (
    <>
      <Flex
        ref={btnRef}
        onClick={() => {
          resetFormValue();
        }}
        bg={"#e3e3e3"}
        h="150"
        w="95%"
        cursor="pointer"
        alignItems="center"
        justifyContent="center"
        borderRadius="10px"
      >
        <Box color="#000" textAlign="center">
          <Icon fontSize={14} as={AiOutlinePlus}></Icon>
          <Text size="sm" fontSize="14px">
            Create New Project
          </Text>
        </Box>
      </Flex>
      <Drawer
        size="lg"
        isOpen={isOpen}
        placement="right"
        finalFocusRef={btnRef}
        fontFamily="Poppins"
        onClose={() => {
          resetUpdatedProjDetails(true, "render");
          setProjectTypeChoice("Commercial");
        }}
      >
        <DrawerContent bg="#151117" display="flex">
          <DrawerHeader color="#fff" fontWeight="normal">
            {projTitle} Project Template
          </DrawerHeader>
          <DrawerCloseButton
            color="white"
            onClose={() => {
              resetUpdatedProjDetails();
            }}
          />
          <DrawerBody color="#fff">
            <FormControl isRequired mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading}>
                Project Title
              </FormLabel>
              <Input
                placeholder="Enter Project Title"
                borderRadius="10px"
                height={"50px"}
                border="none"
                borderColor={borderBottomColor}
                bg={greyBgColor}
                value={projTemplateForm.project_name}
                onChange={onUpdateField}
                name="project_name"
                disabled={
                  projectEditViewFlag === "viewProjTemplate" ? true : false
                }
                color="#fff"
              />
              <Text fontSize="sm" color={errorColor} mt="1">
                {error?.project_name}
              </Text>
            </FormControl>

            <FormControl isRequired mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading}>
                Project Type
              </FormLabel>

              <Flex>
                <VStack w="100%" alignItems={"start"}>
                  <Select
                    borderRadius="10px"
                    height={"50px"}
                    border="none"
                    borderColor={borderBottomColor}
                    bg={greyBgColor}
                    borderRightWidth="0px"
                    value={projectTypeChoice}
                    onSelect={(e) => {
                      setProjectTypeChoice(e.target.value);
                    }}
                    onChange={(e) => {
                      setProjectTypeChoice(e.target.value);
                    }}
                  >
                    <option disabled={true} style={{ color: "black" }}>
                      Please Select
                    </option>
                    <option value="Commercial" style={{ color: "black" }}>
                      Commercial
                    </option>

                    <option value="custom" style={{ color: "black" }}>
                      Custom Project Type
                    </option>
                  </Select>
                  {projectTypeChoice === "custom" ? (
                    <>
                      <Input
                        placeholder="Enter Custom Type"
                        borderRadius="10px"
                        height={"50px"}
                        border="none"
                        borderColor={borderBottomColor}
                        bg={greyBgColor}
                        value={projectCustomType}
                        onChange={(e) => {
                          setProjectCustomType(e.target.value);
                        }}
                        color="#fff"
                      />
                      {customTypeErr ? (
                        <Text fontSize="sm" color={errorColor} mt="1">
                          {customTypeErr}
                        </Text>
                      ) : null}
                    </>
                  ) : null}
                  {error?.project_type ? (
                    <Text fontSize="sm" color={errorColor} mt="1">
                      {error?.project_type}
                    </Text>
                  ) : null}
                </VStack>
              </Flex>
            </FormControl>

            <FormControl isRequired mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading}>
                Project Resource
              </FormLabel>

              <Flex>
                <VStack w="100%" alignItems={"start"}>
                  <Select
                    placeholder="Select Resource"
                    borderRadius="10px"
                    height={"50px"}
                    border="none"
                    borderColor={borderBottomColor}
                    bg={greyBgColor}
                    borderRightWidth="0px"
                    value={resourceType}
                    onSelect={(e) => {
                      setResourceType(e.target.value);
                    }}
                    onChange={(e) => {
                      setResourceType(e.target.value);
                    }}
                  >
                    {renderResourceList()}
                  </Select>
                  <Text fontSize="sm" color={errorColor} mt="1">
                    {error?.resourceType}
                  </Text>
                </VStack>
              </Flex>
            </FormControl>

            <FormControl mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading}>
                Project Description
              </FormLabel>
              <Input
                as="textarea"
                rows={4}
                borderRadius="10px"
                border="none"
                borderColor={borderBottomColor}
                bg={greyBgColor}
                placeholder="Enter Project Description"
                maxHeight={"200px"}
                size="xl"
                px="10px"
                value={projTemplateForm.project_details}
                onChange={onUpdateField}
                name="project_details"
                disabled={
                  projectEditViewFlag === "viewProjTemplate" ? true : false
                }
                color="#fff"
              />
              <Text fontSize="sm" color={errorColor} mt="1">
                {error?.project_details}
              </Text>
            </FormControl>
            {/* <FormControl mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading}>
                Project Requirements
              </FormLabel>
              <Input
                as="textarea"
                rows={4}
                borderRadius="10px"
                border="none"
                borderColor={borderBottomColor}
                bg={greyBgColor}
                px="10px"
                placeholder="Enter Project Requirements"
                size="xl"
                maxHeight={"200px"}
                value={projTemplateForm.project_requirements}
                onChange={onUpdateField}
                name="project_requirements"
                disabled={
                  projectEditViewFlag === "viewProjTemplate" ? true : false
                }
                color="#fff"
              />
            </FormControl> */}
            {renderProjectImage()}
            {projectEditViewFlag.length ? (
              <FormControl mb={3}>
                <FormLabel fontSize={themeFontSizeMediumHeading}>
                  Project Status
                </FormLabel>
                <Switch
                  colorScheme="green"
                  isChecked={projectStatus}
                  onChange={() => {
                    setProjectStatus(!projectStatus);
                  }}
                />
              </FormControl>
            ) : null}
            {projectEditViewFlag === "viewProjTemplate" ? null : (
              <Flex>
                <Button
                  size="sm"
                  colorScheme="green"
                  isDisabled={loading ? true : false}
                  isLoading={loading ? true : false}
                  type="button"
                  onClick={(e) => {
                    projectEditViewFlag === "editProjTemplate"
                      ? updateProjectTemplateForm(e)
                      : createProjectTemplateData(e);
                  }}
                >
                  {projectEditViewFlag === "editProjTemplate"
                    ? "Update"
                    : "Add"}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  ml={3}
                  onClick={() => {
                    resetUpdatedProjDetails(true);
                  }}
                >
                  Close
                </Button>
              </Flex>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default AddProjectTemplate;
