import axios from "./axios";
import { serviceUrls } from "./serviceUrls";

export const fetchPromptDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().promptList, data, config);
};

export const addPromptData = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().addPromptData, data, config);
};

export const updatePromptLibrary = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updatePromptData, data, config);
};

export const fetchPromptEnhanceData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchPromptEnhance, data, config);
};

export const promptStatistics = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().promptDataStats, data, config);
};
