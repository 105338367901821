// Chakra Imports
import ReactDOM from "react-dom";
import React, { useRef } from "react";
import { AiOutlineInstagram } from "react-icons/ai";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Input,
  Select,
  Icon,
  LinkOverlay,
} from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor } from "../../constants";
import Logo from "../../image/LandingPageImg/logo.png";
import LandingBgImage from "../../image/LandingPageImg/landingbg.png";
import BuildingImg from "../../image/LandingPageImg/buildingBg.png";
import FormBuildingImage from "../../image/LandingPageImg/formbg.jpg";

function LandingPage() {
  const scrollToAccessForm = useRef(null);

  // Scroll to div
  const scrollToDiv = () => {
    if (scrollToAccessForm.current) {
      ReactDOM.findDOMNode(scrollToAccessForm.current).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const renderContactForm = () => {
    return (
      <Flex
        w={{ base: "100%", md: "80%", lg: "50%", sm: "100%" }}
        justifyContent={"center"}
        alignItems={"center"}
        my="10"
      >
        <Box bg="#141718" borderRadius={"20px"} p="20px" w="70%">
          <Text fontSize={"24px"}>🎁Get Early Access</Text>
          <Text fontSize={"14px"} color="grey">
            Join our email list and get early access to Corbu
          </Text>
          <Box
            w="100%"
            mt="4"
            as="form"
            action="https://corbu.us21.list-manage.com/subscribe/post?u=3c5a408528d8db9da5f287a51&amp;id=ac62f24cee&amp;f_id=0068d8e6f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            spacing={4}
            align="left"
            target="_blank"
          >
            <Box mb="20px">
              <Flex>
                <Text mb="8px" color="#fff" fontSize={"14px"}>
                  Email Address
                </Text>
              </Flex>
              <Input
                size="sm"
                borderRadius="10px"
                height={"45px"}
                border="none"
                bg="#232627"
                placeholder="Your email address"
                color="#fff"
                _placeholder={{ color: "#bbbbbb", fontSize: "13px" }}
                _hover={{ boxShadow: "none" }}
                fontSize="13px"
                type="email"
                name="EMAIL"
                required
              />
            </Box>
            <Flex mb="20px" justifyContent={"space-between"}>
              <Box w="48%">
                <Flex>
                  <Text mb="8px" color="#fff" fontSize={"14px"}>
                    First Name
                  </Text>
                </Flex>
                <Input
                  size="sm"
                  borderRadius="10px"
                  height={"45px"}
                  border="none"
                  bg="#232627"
                  placeholder="Your First Name"
                  color="#fff"
                  _placeholder={{ color: "#bbbbbb", fontSize: "13px" }}
                  _hover={{ boxShadow: "none" }}
                  fontSize="13px"
                  type="text"
                  name="FNAME"
                  required
                />
              </Box>
              <Box w="48%">
                <Flex>
                  <Text mb="8px" color="#fff" fontSize={"14px"}>
                    Last Name
                  </Text>
                </Flex>
                <Input
                  size="sm"
                  borderRadius="10px"
                  height={"45px"}
                  border="none"
                  bg="#232627"
                  placeholder="Your Last Name"
                  color="#fff"
                  _placeholder={{ color: "#bbbbbb", fontSize: "13px" }}
                  _hover={{ boxShadow: "none" }}
                  fontSize="13px"
                  type="text"
                  name="LNAME"
                  required
                />
              </Box>
            </Flex>
            <Box mb="20px">
              <Flex>
                <Text mb="8px" color="#fff" fontSize={"14px"}>
                  Design Firm Website
                </Text>
              </Flex>
              <Input
                size="sm"
                borderRadius="10px"
                height={"45px"}
                border="none"
                bg="#232627"
                placeholder="Your Design Firm Website"
                color="#fff"
                _placeholder={{ color: "#bbbbbb", fontSize: "13px" }}
                _hover={{ boxShadow: "none" }}
                fontSize="13px"
                type="text"
                name="MMERGE7"
              />
            </Box>
            <Box mb="20px">
              <Flex>
                <Text mb="8px" color="#fff" fontSize={"14px"}>
                  I am a (an)
                </Text>
              </Flex>

              <Select
                class=""
                id="mce-undefined"
                placeholder="Select"
                borderRadius="10px"
                height={"45px"}
                border="none"
                fontSize="13px"
                bg="#232627"
                borderRightWidth="0px"
              >
                <option value="Investor" style={{ color: "black" }}>
                  Investor
                </option>
                <option value="Designer" style={{ color: "black" }}>
                  Designer
                </option>
                <option value="Student" style={{ color: "black" }}>
                  Student
                </option>
                <option
                  value="Consumer wanting a design"
                  style={{ color: "black" }}
                >
                  Consumer wanting a design
                </option>
              </Select>
            </Box>

            <Box mb="10px">
              <Button
                type="submit"
                name="subscribe"
                size="md"
                variant="outline"
                fontWeight="500"
                fontSize="16px"
                w="100%"
                bg={defaultThemeColor}
                borderRadius={"10px"}
                border={"none"}
                _hover={{ boxShadow: "none" }}
                _active={{ bg: "none" }}
              >
                Join{" "}
              </Button>
            </Box>
          </Box>
        </Box>
      </Flex>
    );
  };
  return (
    <Box bg="black" color="#fff">
      <Box
        bgImage={LandingBgImage}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        h="100vh"
        position={"relative"}
      >
        <Box className="top-content" p="10">
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Flex alignItems={"center"}>
              <Image src={Logo}></Image>
              <Text ml="3" fontWeight={"600"} fontSize={"26px"}>
                Corbu
              </Text>
            </Flex>
            <Button
              variant="outline"
              color="#fff"
              borderColor={defaultThemeColor}
              _hover={{ boxShadow: "none" }}
              _active={{ bg: "none" }}
              onClick={() => {
                scrollToDiv("scrollToDiv");
              }}
            >
              Signup
            </Button>
          </Flex>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Flex
              flexWrap={"wrap"}
              justifyContent={"center"}
              fontSize={{ base: "2rem", md: "5rem", lg: "5rem", sm: "2rem" }}
              mt={{ base: "5rem", md: "0rem", lg: "0rem", sm: "5rem" }}
            >
              <Text textAlign={"center"} w="100%" fontWeight={"600"}>
                Unprecedented{" "}
              </Text>
              <Text>
                <Text as="span" color={defaultThemeColor} fontWeight={"600"}>
                  Design AI
                </Text>{" "}
              </Text>
            </Flex>
            <Text textAlign={"center"} mt="30px">
              For Architects, Home Builders, Interior Designers,
              <br /> and anyone designing in the Built World.
            </Text>
            <Box textAlign={"center"} mt="5rem">
              <Button
                size="md"
                _active={{ bg: "none" }}
                _hover={{ boxShadow: "none" }}
                bg={defaultThemeColor}
                onClick={() => {
                  scrollToDiv("scrollToDiv");
                }}
              >
                Get early access
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Flex
        bg={defaultThemeColor}
        alignItems={"center"}
        justifyContent={"center"}
        p="10"
        flexWrap="wrap"
      >
        <Image src={BuildingImg}></Image>
        <Text
          w={{ base: "100%", md: "80%", lg: "34%", sm: "100%" }}
          color="#000"
          fontSize={"2.5rem"}
          fontWeight={"700"}
        >
          Make Design Decisions Faster with the Power of Generative AI
        </Text>
      </Flex>
      <Flex
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        ref={scrollToAccessForm}
      >
        {renderContactForm()}
        <Image
          w={{ base: "100%", md: "80%", lg: "50%", sm: "100%" }}
          src={FormBuildingImage}
          objectFit={"cover"}
        ></Image>
      </Flex>
      <Box p="10">
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex alignItems={"center"}>
            <Image src={Logo}></Image>
            <Text ml="3" fontWeight={"600"} fontSize={"26px"}>
              Corbu
            </Text>
          </Flex>
          {/* <LinkOverlay href="https://instagram.com/corbu.ai" target="_blank"> */}
          {/* <Icon
            cursor="pointer"
            fontSize={24}
            color={defaultThemeColor}
            as={AiOutlineInstagram}
          ></Icon> */}
        </Flex>
      </Box>

      {/* <Flex
        p="10"
        borderTopWidth={"0.5px"}
        borderColor={"#141718"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text>Info@corbu.ai</Text>
        <Text>Privacy Policy</Text>
        <Text>Terms of Service</Text>
      </Flex> */}
    </Box>
  );
}

export default LandingPage;
