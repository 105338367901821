// Chakra React Imports
import axios from "../../services/axios";
import React, { useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { Box, Button, Icon, useToast, Input } from "@chakra-ui/react";

// Component Imports
import { defaultGreyColor } from "../../constants";
import { toastFunctionToaster } from "../../utils/toastFunction";

// Api Imports
import {
  uploadEditShareSpaceContent,
  uploadShareSpaceContent,
} from "../../services/businessServices";
import {
  externalContentUpload,
  externalContentUploadEdit,
} from "../../services/sharedServices";

function UploadSharedContent({
  type,
  sharedSpaceId,
  parentSharedContentId,
  sharedContentId,
  email,
  code,
  uploadImageDetailsFlag,
  name,
  width,
}) {
  const toast = useToast();
  const [loading, isLoading] = useState(false);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files?.length) {
      handleSubmitNew(
        e.target.files,
        Array.from(e.target.files).map((file) => ({
          file_name: file?.name,
          file_type: file?.type,
        }))
      );
    }
  };

  function postObjectData(s3Data, selectedFile) {
    isLoading(true);

    const { url, fields } = s3Data;
    const payload = new FormData();

    // add all the other fields
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    payload.append("file", selectedFile);
    const { data: result } = axios
      .post(url, payload)
      .then((res) => {
        if (res?.data?.length > 0) {
          toast(toastFunctionToaster("File uploaded successfully", "success"));
          uploadImageDetailsFlag(true);
        } else {
          toast(toastFunctionToaster("File uploaded successfully", "success"));
          uploadImageDetailsFlag(true);
        }
      })
      .catch((err) => {
        if (err) {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
        }
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const handleSubmitNew = async (uploadFiles, fileInfoArr) => {
    let response = null;
    if (fileInfoArr?.length) {
      isLoading(true);
      if (sharedContentId?.length) {
        if (type === "external") {
          const params = {
            shared_space_key: sharedSpaceId,
            user_email: email,
            password: code,
          };
          let obj = {
            shared_content_uuid: sharedContentId,
            files_list: fileInfoArr,
            content_choice: "string",
          };
          response = await externalContentUploadEdit(params, obj);
        } else {
          let objData = {
            shared_content_uuid: sharedContentId,
            files_list: fileInfoArr,
            content_choice: "string",
          };
          response = await uploadEditShareSpaceContent(objData);
        }
      } else {
        if (type === "external") {
          const params = {
            shared_space_key: sharedSpaceId,
            user_email: email,
            password: code,
          };
          let obj = {
            files_list: fileInfoArr,
            content_choice: "string",
          };
          if (parentSharedContentId) {
            obj.parent_shared_content_uuid = parentSharedContentId;
          }
          response = await externalContentUpload(params, obj);
        } else {
          let objData = {
            shared_space_uuid: sharedSpaceId,
            files_list: fileInfoArr,
            content_choice: "string",
          };
          if (parentSharedContentId) {
            objData.parent_shared_content_uuid = parentSharedContentId;
          }
          response = await uploadShareSpaceContent(objData);
        }
      }

      if (
        response &&
        response?.data &&
        response?.data?.length > 0 &&
        response.data[0]?.signedPackage?.signedUrl
      ) {
        const finalUrls = response?.data[0]?.signedPackage?.signedUrl;
        finalUrls?.forEach((data, index) => {
          postObjectData(data, uploadFiles[index]);
        });
        isLoading(false);
      } else {
        isLoading(false);
        toast(toastFunctionToaster(response?.message, "error"));
      }
    }
  };

  return sharedContentId?.length ? (
    <Box position={"relative"} bg="#1c181e" w="150px" h="150px" p="5">
      <Input
        p="0px"
        type="file"
        onChange={handleFileChange}
        cursor={"pointer"}
        position="relative"
        opacity="0"
        w="100%"
        h="100%"
        zIndex={"1"}
        multiple={true}
      ></Input>
      <Icon
        w="100%"
        h="100%"
        border={"1px"}
        as={AiOutlineUpload}
        cursor={"pointer"}
        fontSize={"18px"}
        borderRadius={"5px"}
        color={defaultGreyColor}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    </Box>
  ) : (
    <Box
      w={width ? width : "130px"}
      position={"relative"}
      bg="#1c181e"
      h="100%"
    >
      <Input
        p="0px"
        type="file"
        onChange={handleFileChange}
        cursor={"pointer"}
        position="relative"
        opacity="0"
        h="100%"
        zIndex={"1"}
        multiple={true}
      ></Input>
      <Button
        size={"xs"}
        color={"gray.600"}
        onClick={handleSubmitNew}
        rightIcon={<FaPlus />}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        colorScheme="green"
        textColor={"#fff"}
        isDisabled={loading ? true : false}
        isLoading={loading ? true : false}
      >
        {name}
      </Button>
    </Box>
  );
}

export default UploadSharedContent;
