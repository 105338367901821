// React Imports
import React from "react";
import { AiFillPlusSquare, AiFillQuestionCircle } from "react-icons/ai";
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
  MenuItem,
} from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor, greyBgColor } from "../../../../../constants";
import { wizardTitles } from "../../../../../constants/Workflow.js";
import { useNavigate } from "react-router";

function AddNewWorkflow({
  type,
  imageWorkflowId,
  imageWorkflowName,
  projectId,
  resourceId,
  imageIndexValue,
  workflowCounter,
  objData,
  resetData,
  fontSize,
  menuRight,
  menuLeft,
}) {
  const navigate = useNavigate();

  const navigateToCreateView = (item) => {
    resetData();
    const childWorkflowName = `${
      imageWorkflowName || "Untitled Workflow"
    } (${workflowCounter}${["A", "B", "C", "D"][imageIndexValue]})`
    if (type === "playground") {
      navigate("/business/playgroundDesign", {
        state: {
          launcher: "create",
          workflow_model_code: item.modelCode,
          parent_workflow_uuid: imageWorkflowId,
          object_name: objData?.object_name,
          object_url: objData?.url,
          workflow_name: childWorkflowName,
        },
      });
    } else {
      navigate("/business/designProject", {
        state: {
          launcher: "create",
          project_uuid: projectId,
          resource_uuid: resourceId,
          parent_workflow_uuid: imageWorkflowId,
          workflow_model_code: item.modelCode,
          object_name: objData?.object_name,
          object_url: objData?.url,
          workflow_name: childWorkflowName,
        },
      });
    }
  };

  let posX = {};
  if (menuRight) {
    posX.right = menuRight;
  } else if (menuLeft) {
    posX.left = menuLeft;
  }

  return (
    <Menu className="addToplayground">
      <Tooltip label="Open As New Workflow" placement="top">
        <Box>
          <MenuButton
            className="addToplayground"
            as={AiFillPlusSquare}
            color={defaultThemeColor}
            size={fontSize ? fontSize : "30px"}
            cursor="pointer"
          ></MenuButton>
        </Box>
      </Tooltip>
      <MenuList
        position={"absolute"}
        bg={greyBgColor}
        borderWidth={"0px"}
        top="33px"
        {...posX}
      >
        {wizardTitles.map((item, index) => {
          return (
            <MenuItem
              cursor={"pointer"}
              onClick={() => navigateToCreateView(item)}
              key={item.title + index}
              borderBottomWidth={"0px"}
              bg={greyBgColor}
              _hover={{
                bg: "#151117",
              }}
            >
              <Text
                textAlign={"center"}
                fontWeight={"600"}
                color="#fff"
              >
                {item.title}
              </Text>
              <Tooltip label={item?.placeholderTitle} placement="right">
                <Box mt="3px" ml="2">
                  <AiFillQuestionCircle
                    cursor={"pointer"}
                    color={defaultThemeColor}
                    fontSize="16px"
                  />
                </Box>
              </Tooltip>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

export default AddNewWorkflow;
