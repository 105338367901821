import React, { useEffect, useState } from 'react';
import { Flex, Box, Text, Switch, useToast, Spinner } from '@chakra-ui/react';
import { defaultThemeColor, errorColor } from '../../../../constants';
import { fetchTeamMemberData } from '../../../../services/teamServices';
import { enableDisableTeamMember } from '../../../../services/sharedServices';

function DisabledTeamMember() {
  const toast = useToast();
  const [loading, isLoading] = useState(true);
  const [teamMemberData, setTeamMemberData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetchTeamMemberData()
      .then(res => {
        isLoading(false);
        if (res.result && res.data.length) {
          setTeamMemberData(res.data[0]);
        } else {
          isLoading(false);
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        isLoading(false);
        if (err) {
          toast({
            title: err?.response?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      });
  };

  const changeCurrentUserStatus = rowData => {
    const updatedArr = teamMemberData.map(singleRowObj =>
      singleRowObj.uuid === rowData.uuid
        ? { ...singleRowObj, is_enable: !rowData.is_enable }
        : singleRowObj
    );
    setTeamMemberData(updatedArr);
    enableDisableTeamMember(rowData)
      .then(res => {
        if (res.result) {
          toast({
            title: res?.message,
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          fetchData();
        } else {
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        toast({
          title: err?.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const renderTeamData = teamMemberData?.map((data, index) => (
    <Flex
      m="1"
      p="10px"
      alignItems="center"
      justifyContent="space-between"
      bg="#1c181e"
      borderLeftWidth="5px"
      borderColor={
        data.is_enable === true
          ? defaultThemeColor
          : data.is_enable === false
          ? errorColor
          : 'grey'
      }
      key={index}
    >
      <Box>
        <Text mb="5px" fontSize={16}>
          {' '}
          {data.first_name ? data.first_name : '-'}
          <Text as="span" pl="10px">
            {data.last_name ? data.last_name : '-'}
          </Text>
        </Text>
        <Text mb="5px"> {data.email ? data.email : '-'}</Text>
      </Box>
      <Switch
        onChange={() => {
          changeCurrentUserStatus(data);
        }}
        isChecked={data?.is_enable}
        colorScheme="green"
      />
    </Flex>
  ));

  return (
    <>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10">
          <Spinner size="xl" />
        </Flex>
      ) : teamMemberData?.length ? (
        <Box
          h="calc(100vh - 200px)"
          overflow="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#fff',
              borderRadius: '24px',
            },
          }}
        >
          {renderTeamData}
        </Box>
      ) : (
        <Text p="10px">No Team Member Found</Text>
      )}
    </>
  );
}

export default DisabledTeamMember;
