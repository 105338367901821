import React, { useState } from "react";
import { Box, Button, Text, Textarea, useToast } from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { updateShareSpaceComment } from "../../services/businessServices";
import { externalUpdateComment } from "../../services/sharedServices";
import { toastFunctionToaster } from "../../utils/toastFunction";

function EditComments({
  type,
  sharedSpaceId,
  email,
  code,
  handleSpaceData,
  sharedContentData,
}) {
  const toast = useToast();
  const [comment, setComment] = useState("");
  const [commentLoader, setCommentLoader] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);

  const storeCommentData = () => {
    setCommentLoader(true);
    if (type === "external") {
      const params = {
        shared_space_key: sharedSpaceId,
        user_email: email,
        password: code,
      };
      let obj = {
        shared_content_uuid: sharedContentData?.shared_content_uuid,
        comment_text: comment,
      };
      externalUpdateComment(params, obj)
        .then((res) => {
          if (res?.result) {
            handleSpaceData();
            setShowTextArea(false);
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster(err?.message, "error"));
          }
        })
        .finally(() => {
          setCommentLoader(false);
        });
    } else {
      let obj = {
        shared_content_uuid: sharedContentData?.shared_content_uuid,
        comment_text: comment,
      };
      updateShareSpaceComment(obj)
        .then((res) => {
          if (res?.result) {
            handleSpaceData();
            setShowTextArea(false);
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster(err?.message, "error"));
          }
        })
        .finally(() => {
          setCommentLoader(false);
        });
    }
  };

  return (
    <Box
      mt="2"
      border="1px"
      borderRadius={"5px"}
      borderStyle={"dotted"}
      borderColor={"#797b80"}
      p="10px"
      borderBottom={"0"}
    >
      {showTextArea ? (
        <Textarea
          color={"white"}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          value={comment}
        />
      ) : (
        <Text rows="3" resize="none" color={"#fff"} borderColor={"#797b80"}>
          {sharedContentData?.info}
        </Text>
      )}
      <Box mt="2" w="100%" textAlign={"right"}>
        {showTextArea ? (
          <Button
            size={"xs"}
            colorScheme="red"
            onClick={() => {
              setShowTextArea(false);
              setComment("");
            }}
          >
            Cancel
          </Button>
        ) : (
          <Button
            size={"xs"}
            colorScheme="yellow"
            rightIcon={<FaEdit />}
            onClick={() => {
              setShowTextArea(true);
              setComment(sharedContentData?.info);
            }}
          >
            Edit Comment
          </Button>
        )}
        {showTextArea && comment?.length > 0 && (
          <Button
            ml="1"
            size={"xs"}
            colorScheme="green"
            isDisabled={commentLoader ? true : false}
            isLoading={commentLoader ? true : false}
            onClick={() => {
              storeCommentData();
            }}
          >
            Confirm
          </Button>
        )}
        {sharedContentData?.updated !== sharedContentData?.created && (
          <Text textAlign={"right"} color={"#d7d7d7"} my="2">
            Edited by {sharedContentData?.author} on{" "}
            {sharedContentData?.updated}
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default EditComments;
