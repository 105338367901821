// Chakra Imports
import React, { useState, useEffect } from "react";
import {
  Button,
  Text,
  Box,
  Drawer,
  useDisclosure,
  Flex,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor } from "../../constants";
import { resetPassword } from "../../services/sharedServices";

function ForgotPasswordPanel({ emailId, checkEmailValid }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, isLoading] = useState(false);
  const [forgotPasswordFlag, setForgotPasswordFlag] = useState(false);
  const toast = useToast();
  const btnRef = React.useRef();

  useEffect(() => {
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!emailPattern.test(emailId)) {
      setForgotPasswordFlag(false);
    } else {
      setForgotPasswordFlag(true);
    }
  }, [emailId]);

  const submitResetPassword = () => {
    isLoading(true);
    let objBody = {
      email: emailId,
    };
    if (forgotPasswordFlag && emailId.length) {
      resetPassword(objBody)
        .then((res) => {
          isLoading(false);
          if (res.result) {
            toast({
              title: res?.message,
              status: "success",
              isClosable: true,
              duration: 3000,
            });
            onClose();
          } else {
            toast({
              title: res?.message,
              status: "error",
              isClosable: true,
              duration: 3000,
            });
            onClose();
          }
        })
        .catch((err) => {
          isLoading(false);
          toast({
            title: err?.message,
            status: "error",
            isClosable: true,
            duration: 3000,
          });
          onClose();
        });
    } else {
      isLoading(false);
      checkEmailValid();
      onClose();
    }
  };
  return (
    <>
      <Button
        ref={btnRef}
        width="100%"
        colorScheme={"green.400"}
        color="green.400"
        variant="outline"
        borderRadius="10px"
        borderColor={"green.400"}
        fontSize="16px"
        onClick={onOpen}
        _hover={{ boxShadow: "none" }}
        _active={{ bg: "none" }}
      >
        Forgot Password
      </Button>

      <Drawer
        size="md"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg={"#232627"}>
          <Box p="3" fontFamily="Poppins" align="center" mt="10">
            <DrawerHeader fontSize={"3xl"} color="#fff">
              Reset Password
            </DrawerHeader>
            <Text mt="2" p="3" fontWeight="bold" bg={"gray.400"} rounded={"lg"}>
              {forgotPasswordFlag ? (
                emailId
              ) : (
                <Text as="span" color="red">
                  Please Enter Valid Email Id
                </Text>
              )}
            </Text>
            <Text mt="2" p="3" color="#fff">
              A default password will be sent to you at the above email address
              if you have an account with us. Please use the default password to
              login your account and then change the default password to a
              desire password.
            </Text>
            <Flex mt="5" alignItems="center" justifyContent="center">
              <Button
                fontWeight="500"
                fontSize="16px"
                w="50%"
                mr="2"
                bg={defaultThemeColor}
                borderRadius={"10px"}
                _hover={{ boxShadow: "none" }}
                _active={{ bg: "none" }}
                color={"#000"}
                type="submit"
                isDisabled={loading ? true : false}
                isLoading={loading ? true : false}
                onClick={() => submitResetPassword()}
              >
                Reset Password
              </Button>
              <Button
                fontWeight="500"
                fontSize="16px"
                w="30%"
                bg="#ff3636"
                borderRadius={"10px"}
                _hover={{ boxShadow: "none" }}
                _active={{ bg: "none" }}
                color={"#fff"}
                onClick={onClose}
              >
                Close
              </Button>
            </Flex>
          </Box>
          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ForgotPasswordPanel;
