// React and Chakra Imports
import React from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { Box, Text, Grid, GridItem, Flex, Tooltip } from "@chakra-ui/react";

// Component Imports
import { useNavigate } from "react-router-dom";
import { defaultThemeColor, greyBgColor } from "../../../../constants";
import { wizardTitles } from "../../../../constants/Workflow";

function CreateWorkflow(props) {
  const btnRef = React.useRef();
  const navigate = useNavigate();
  const project_details = props?.projectDetails;

  // Differentiate playground and design
  const navigateToCreateView = (item) => {
    if (!project_details) {
      navigate("/business/playgroundDesign", {
        state: {
          launcher: "create",
          workflow_model_code: item.modelCode,
        },
      });
    } else {
      navigate("/business/designProject", {
        state: {
          launcher: "create",
          project_uuid: project_details?.project_uuid,
          resource_uuid: project_details?.project_resources?.length
            ? project_details?.project_resources[
                project_details?.project_resources?.length - 1
              ]?.resource_uuid
            : null,
          workflow_model_code: item.modelCode,
        },
      });
    }
  };

  const renderWorkflow = () => {
    return (
      <Grid
        templateColumns={project_details ? "repeat(6, 1fr)" : "repeat(6, 1fr)"}
        gap={4}
        p="3"
      >
        {wizardTitles.map((item, index) => {
          return (
            <GridItem
              cursor={"pointer"}
              borderWidth={"1px"}
              borderRadius={"10px"}
              onClick={() => navigateToCreateView(item)}
              p="3"
              ref={btnRef}
              key={item.title + index}
            >
              <Box>
                <Flex alignItems={"center"} justifyContent={"center"}>
                  <Text color="#fff" fontSize={"24px"} textAlign={"center"}>
                    {item.title}
                  </Text>
                  <Tooltip label={item?.placeholderTitle} placement="top">
                    <Box mt="3px" ml="2">
                      <AiFillQuestionCircle
                        cursor={"pointer"}
                        color={defaultThemeColor}
                        fontSize="16px"
                      />
                    </Box>
                  </Tooltip>
                </Flex>
                {/*
                <Text color="#fff" textAlign={"center"} mr="2">
                  {item?.subTitle}
                </Text>
                */}
              </Box>
            </GridItem>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box mt="3" bg={"#151117"} borderRadius={"10px"}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth={"0.5px"}
        borderBottomColor={greyBgColor}
      >
        <Text color="#fff" p="10px" fontSize={"20px"}>
          Create Workflow Design
        </Text>
      </Flex>
      {renderWorkflow()}
    </Box>
  );
}

export default CreateWorkflow;
