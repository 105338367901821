import React, { useRef } from "react";
import {
  Box,
  Icon,
  Flex,
  Text,
  Modal,
  Button,
  Heading,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { greyBgColor } from "../../../constants";
import { BiCube } from "react-icons/bi";
import CreatePipeLine from "./CreatePipeLine";

function PiplineList() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const piplineData = [
    {
      pipelineName: "Avkash Pipeline 1",
      createdDate: "July 10th, 2024 3:40 PM PST",
      type: "New",
      prevCount: 0,
    },
    {
      pipelineName: "Avkash X 1",
      createdDate: " July 7th, 2024 3:40 PM PST",
      type: "",
      prevCount: 3,
    },
  ];

  const renderPipelineDetails = () => {
    return piplineData?.map((data, index) => (
      <Box
        mb="2"
        p="2"
        w="100%"
        borderRadius={"10px"}
        bg={greyBgColor}
        key={index}
      >
        <Flex justifyContent={"space-between"}>
          <Box w="50%">
            <Text color="#fff" mb="1" fontSize={"16px"} fontWeight={"600"}>
              {data?.pipelineName}
              {data?.type ? (
                <Text
                  ml="2"
                  p="1"
                  fontSize={"10px"}
                  borderRadius={"5px"}
                  as="span"
                  color="green.200"
                  borderWidth={"1px"}
                  borderColor={"green.200"}
                >
                  {data?.type}
                </Text>
              ) : null}
            </Text>
            <Text fontSize={"12px"} color="#d7d7d7">
              {data?.createdDate}
            </Text>
          </Box>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            w="50px"
            borderWidth={"1px"}
            borderRadius={"5px"}
          >
            <Text color="#fff" fontSize={"24px"}>
              {" "}
              {data?.prevCount}
            </Text>
          </Flex>
          <Flex w="25%" justifyContent={"flex-end"} alignItems={"center"}>
            {data?.prevCount == 0 ? null : (
              <Icon
                cursor={"pointer"}
                mr="2"
                as={BiCube}
                color="#fff"
                fontSize={"30px"}
                ml="2"
              />
            )}
            <Button size="sm" mr="2" colorScheme="blue">
              Edit
            </Button>
            {data?.prevCount > 0 ? null : (
              <Button size="sm" colorScheme="green">
                Start
              </Button>
            )}
            {data?.prevCount == 0 ? null : (
              <Button size="sm" colorScheme="yellow">
                Restart
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
    ));
  };

  return (
    <Box h="100%">
      <Flex
        h="5%"
        mb="2%"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Heading color="#fff" fontWeight="600" fontSize="24px">
          Pipeline
        </Heading>
        <Button colorScheme="green" size="sm" ref={btnRef} onClick={onOpen}>
          Create New Pipeline
        </Button>
      </Flex>
      <Box h="92%" p="20px" bg="#151117" borderRadius={"15px"}>
        {renderPipelineDetails()}
      </Box>

      {/* Drawer UI For Stepper */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg={greyBgColor} borderRadius="20px">
          <ModalHeader
            color="#fff"
            borderBottomWidth={"1px"}
            borderColor={"#d7d7d7"}
          >
            Pipeline Wizard (1/4)
          </ModalHeader>
          <ModalCloseButton color="#d7d7d7" />
          <ModalBody>
            <CreatePipeLine />
          </ModalBody>

          <ModalFooter borderTopWidth={"1px"} borderColor={"#d7d7d7"}>
            <Button colorScheme="red" mr={3} onClick={onClose} size="sm">
              Close
            </Button>
            <Button colorScheme="green" onClick={onClose} size="sm">
              Next
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default PiplineList;
