import React, { useState } from "react";
import {
  Box,
  Grid,
  Flex,
  Text,
  Input,
  Button,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { defaultThemeColor, errorColor } from "../../../constants/index";
import { getEmailId } from "../../../utils/localStorageIndex";
import { updatePassword } from "../../../services/sharedServices";
import { clearLocalStorage } from "../../../utils/localStorageIndex";

function ChangePassword() {
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const emailId = getEmailId();
  const [changePasswordObj, setChangePasswordObj] = useState({
    current_password: "",
    new_password: "",
  });
  const [retype_password, setRetypePassword] = useState("");
  const [retypePasswordErrMsg, setRetypePasswordErrMsg] = useState("");

  const onUpdateField = (e) => {
    const nextFormState = {
      ...changePasswordObj,
      [e.target.name]: e.target.value,
    };
    setChangePasswordObj(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = changePasswordObj;
    let errors = {};
    let formIsValid = true;
    const checkWhiteSpace = /^\S*$/;
    const checkLoerCase = /^(?=.*[a-z]).*$/;
    const checkUpperCase = /^(?=.*[A-Z]).*$/;
    const checkNumber = /.*[0-9].*/;
    const isValidLength = /^.{8,}$/;

    if (!fields["current_password"]) {
      formIsValid = false;
      errors["current_password"] = "*Current Password Is Reqired.";
    }
    if (!fields["new_password"]) {
      formIsValid = false;
      errors["new_password"] = "*New Password Cannot Be Empty.";
    } else if (!checkWhiteSpace.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] = "*Password must not contain Whitespaces";
    } else if (!checkLoerCase.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] =
        "*Password must have at least one Lowercase Character";
    } else if (!checkUpperCase.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] =
        "*Password must have at least one Uppercase Character";
    } else if (!checkNumber.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] = "*Password must have at least one Number";
    } else if (!isValidLength.test(fields["new_password"])) {
      formIsValid = false;
      errors["new_password"] = "*Password must be minimum 8 characters long";
    }

    if (!retype_password) {
      formIsValid = false;
      setRetypePasswordErrMsg("*Retype Password Cannot Be Empty.");
    } else if (fields["new_password"] !== retype_password) {
      formIsValid = false;
      setRetypePasswordErrMsg("*New Password didn't match.");
    }
    setError(errors);
    return formIsValid;
  };

  const submitUserData = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      changePasswordObj["email"] = emailId;
      setRetypePasswordErrMsg("");
      updatePassword(changePasswordObj)
        .then((res) => {
          isLoading(false);
          if (res.result) {
            toast({
              title: res?.message,
              status: "success",
              isClosable: true,
              duration: 3000,
            });
            navigate("/login");
            clearLocalStorage();
          } else {
            toast({
              title: res?.message,
              status: "error",
              isClosable: true,
              duration: 3000,
            });
          }
        })
        .catch((err) => {
          isLoading(false);
          if (err)
            toast({
              title: err?.response?.data?.detail,
              status: "error",
              isClosable: true,
              duration: 3000,
            });
        });
    }
  };

  return (
    <>
      <Box mb="10px">
        <Flex>
          <Text mb="8px" color="#fff" fontSize={"16px"}>
            Current Password
          </Text>
          <CgAsterisk color={errorColor} />
        </Flex>
        <Input
          size="sm"
          borderRadius="10px"
          height={"50px"}
          border="none"
          fontSize="12px"
          borderColor="#494552"
          bg="#1c181e"
          value={changePasswordObj.current_password}
          name="current_password"
          onChange={onUpdateField}
          placeholder="Enter Current Password"
          color="#fff"
        />
        <Text fontSize="sm" color={errorColor} mt="1">
          {error?.current_password}
        </Text>
      </Box>
      <Box mb="10px">
        <Flex>
          <Text mb="8px" color="#fff" fontSize={"16px"}>
            New Password
          </Text>
          <CgAsterisk color={errorColor} />
        </Flex>
        <Input
          size="sm"
          borderRadius="10px"
          height={"50px"}
          border="none"
          fontSize="12px"
          borderColor="#494552"
          bg="#1c181e"
          value={changePasswordObj.new_password}
          name="new_password"
          onChange={onUpdateField}
          placeholder="Enter New Password"
          color="#fff"
        />
        <Text fontSize="sm" color={errorColor} mt="1">
          {error?.new_password}
        </Text>
      </Box>
      <Box mb="10px">
        <Flex>
          <Text mb="8px" color="#fff" fontSize={"16px"}>
            Retype Password
          </Text>
          <CgAsterisk color={errorColor} />
        </Flex>
        <Input
          size="sm"
          borderRadius="10px"
          height={"50px"}
          border="none"
          fontSize="12px"
          borderColor="#494552"
          bg="#1c181e"
          value={retype_password}
          name="retype_password"
          onChange={(e) => {
            setRetypePassword(e.target.value);
          }}
          placeholder="Retype New Password"
          color="#fff"
        />
        <Text fontSize="sm" color={errorColor} mt="1">
          {retypePasswordErrMsg}
        </Text>
      </Box>
      <Box mb="10px">
        <Button
          size="md"
          variant="outline"
          fontWeight="500"
          fontSize="16px"
          w="100%"
          type="button"
          bg={defaultThemeColor}
          border={"none"}
          onClick={(e) => {
            submitUserData(e);
          }}
          isDisabled={loading ? true : false}
          isLoading={loading ? true : false}
        >
          Save{" "}
        </Button>
      </Box>
    </>
  );
}

export default ChangePassword;
