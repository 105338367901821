import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import NestedRoutes from '../../SubRoute/NestedRoutes';

function AdminBusiness() {
  const notificationContent = [
    { number: 3, label: 'Business Users' },
    { number: 5, label: 'Active Job Workflows' },
  ];

  const businessHeaderArr = [
    { label: 'Businesses List', value: 'AllBusinesses' },
    { label: 'Business Users List', value: 'AllBusinessUsers' },
  ];

  return (
    <Box>
      <NestedRoutes
        notificationContentData={notificationContent}
        headerArrData={businessHeaderArr}
        breadCrumTitle="Business User"
        split={3}
      ></NestedRoutes>
    </Box>
  );
}

export default AdminBusiness;
